import { render, staticRenderFns } from "./tx.modal.vue?vue&type=template&id=45874341&scoped=true&"
import script from "./tx.modal.vue?vue&type=script&lang=ts&"
export * from "./tx.modal.vue?vue&type=script&lang=ts&"
import style0 from "./tx.modal.vue?vue&type=style&index=0&id=45874341&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45874341",
  null
  
)

export default component.exports