
// @ts-ignore
export function IS_TESTNET() {
    return process.env.VUE_APP_IS_TESTNET === 'true';
}

// @ts-ignore
export function IS_WHITELIST_ON() {
    return process.env.VUE_APP_IS_WHITELIST_ON === 'true';
}
// @ts-ignore
export function STACKS_BACKEND_URL() {return process.env.VUE_APP_BACKEND_API_URL.toString(); }
// @ts-ignore
export function STACKS_BACKEND_PARAM() {return process.env.VUE_APP_BACKEND_API_PARAM.toString(); }
// @ts-ignore
export function STACKS_BACKEND_PARAM_LEGACY() {return process.env.VUE_APP_BACKEND_API_PARAM_LEGACY.toString(); }

// @ts-ignore
export function FARM_V1_END_CYCLE() {return parseInt(process.env.VUE_APP_FARM_V1_END_CYCLE.toString(), 10); }

// @ts-ignore
export function STACKS_API_URL() {return process.env.VUE_APP_STACKS_API_URL.toString(); }
// @ts-ignore
export function IPFS_URL() {return process.env.VUE_APP_IPFS_URL.toString(); }
// @ts-ignore
export function IPFS_URL2() {return process.env.VUE_APP_IPFS_URL2.toString(); }
// @ts-ignore
export function  MODE() {return process.env.VUE_APP_MODE.toString(); }


// @ts-ignore
export function STACKSWAP_ADDRESS() {return process.env.VUE_APP_CONTRACT_ADDRESS.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_SWAP() {return process.env.VUE_APP_SWAP_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT() {return process.env.VUE_APP_ONE_STEP_MINT_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_DAO() {return process.env.VUE_APP_DAO_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_GOVERNANCE() {return process.env.VUE_APP_GOVERNANCE_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING() {return process.env.VUE_APP_FARMING_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING2() {return process.env.VUE_APP_FARMING2_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING2_1() {return process.env.VUE_APP_FARMING2_1_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING2_2() {return process.env.VUE_APP_FARMING2_2_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING2_5() {return process.env.VUE_APP_FARMING2_5_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING3_LOGIC() {return process.env.VUE_APP_FARMING_V3_LOGIC_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_FARMING3_DATA() {return process.env.VUE_APP_FARMING_V3_DATA_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_STACKING() {return process.env.VUE_APP_STAKING_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_LBTC_STACKING() {return process.env.VUE_APP_LBTC_STAKING_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_STACKING_LOGIC() {return process.env.VUE_APP_STAKING_LOGIC_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_LBTC_STACKING_LOGIC() {return process.env.VUE_APP_LBTC_STAKING_LOGIC_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_STACKSWAP_ROUTER_SWAP() {return process.env.VUE_APP_ROUTER_SWAP_CONTACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_COLLATERAL_TYPES() {return process.env.VUE_APP_COLLATERAL_TYPES_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_AINFT() {return process.env.VUE_APP_AINFT_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_GOLD_PASS() {return process.env.VUE_APP_GOLD_PASS_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_SILVER_PASS() {return process.env.VUE_APP_SILVER_PASS_CONTRACT.toString(); }
// @ts-ignore
export function  CONTRACT_NAME_COMMISSION() {return process.env.VUE_APP_COMMISSION_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_POXL_TOKEN() {return process.env.VUE_APP_POXL_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_POX() {return process.env.VUE_APP_POX_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_MORTGAGER() {return process.env.VUE_APP_MORTGAGER_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_SIP10_RESERVE() {return process.env.VUE_APP_SIP10_RESERVE_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_STX_RESERVE() {return process.env.VUE_APP_STX_RESERVE_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_ORACLE() {return process.env.VUE_APP_ORACLE_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_VAULT_DATA() {return process.env.VUE_APP_VAULT_DATA.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_STACKER1() {return process.env.VUE_APP_STACKER1_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_STACKER2() {return process.env.VUE_APP_STACKER2_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_STACKER3() {return process.env.VUE_APP_STACKER3_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_STACKER4() {return process.env.VUE_APP_STACKER4_CONTRACT.toString(); }

// @ts-ignore
export function  CONTRACT_NAME_LIQUIDATOR() {return process.env.VUE_APP_LIQUIDATOR_CONTRACT.toString(); }

// @ts-ignore
export function  BASE_STX() {return process.env.VUE_APP_BASE_STX.toString(); }

export function  BASE_WSTX_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_ONE_BASE_STX_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}
export function  MIAMI_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_MIAMI_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}
export function  NEWYORK_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_NEWYORK_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}
export function  BASE_STSW_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_STSW_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}
export function  BASE_vSTSW_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_vSTSW_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}

export function  BASE_LBTC_DATA() {
    // @ts-ignore
    const data = process.env.VUE_APP_LBTC_DATA.toString();
    // @ts-ignore
    return JSON.parse(data);
}

export function STX_STSW_LP() {
    // @ts-ignore
    return process.env.VUE_APP_STX_STSW_LP.toString();
}

export function STSW_LBTC_LP() {
    // @ts-ignore
    return process.env.VUE_APP_STSW_LBTC_LP.toString();
}
// @ts-ignore
export function  DISCLAIMER_CONTENT() {return process.env.VUE_APP_DISCLAIMER.toString(); }

// export const CONTRACT_NAME_VSTSW = process.env.CONTRACT_NAME_VSTSW;
// export const CONTRACT_NAME_STSW = process.env.CONTRACT_NAME_STSW;
// export const CONTRACT_NAME_WSTX = process.env.CONTRACT_NAME_WSTX;


