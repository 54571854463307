
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import {
  getCurrentStackingRound,
    getStackingRoundFirstBlock,
  getClaimRewardListFromVSTSW,
  getBlockHeight, txCooldownFromVSTSW, txUnstakingFromVSTSW, numFormat_
} from '@/stacks/manager';
import {
  BASE_STSW_DATA,
  BASE_vSTSW_DATA,
    CONTRACT_NAME_STACKSWAP_STACKING,
  MODE
} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
import confirm_popup from'@/components/modal/confirm.popup.vue';
import tx_confirm from '@/components/modal/tx.modal.vue';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
    confirm_popup,
    tx_confirm,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),

  },
})
export default class UntakePage extends Vue  {
  current_round: any = null;
  start_block_num: number = 0;
  end_block_num: number=0;
  value: number = 1;
  id_list: any = ['Loading'];
  res_dict: any = {};
  selected_id: any = 0;
  toogleCoolDown: boolean = false;
  toogleUnstake: boolean = false;
  cooldown_block_number: number = 0;
  current_block: string = '0';
  vSTSW_Token = BASE_vSTSW_DATA();
  STSW_Token = BASE_STSW_DATA();
  // block_per_round: number = 4032;
  block_per_round: number = 5;

  // popup-var
  popup_show: boolean=false;
  popup_title: string='';
  popup_contents: string='None-Contents';

  // tx setting 모달
  toggleTxConfirm: boolean = false;

  OnTxOpen() {
    this.toggleTxConfirm = true;
  }
  OnTxClosed(){
    this.toggleTxConfirm = false;
  }


  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  clicked_confirm2 : boolean = false;
  tx_sent2 : boolean = false;
  tx_id : string = '';

  onWalletCancel(){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }

  onWalletCancel2(){
    // console.log('user closed wallet');
    this.clicked_confirm2 = false;
  }
  onWalletSuccess2(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent2 = true;
    this.clicked_confirm2 = false;
  }
  viewTX() {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
  }


  IsDisableCooldownBtn(opt:any){
    // console.log('IsDisableCooldownBtn', this.toogleCoolDown)
    return !this.toogleCoolDown;
  }
  onChangeList(event:any){
    // console.log('onChangeList 1', this.end_block_num, parseInt(this.current_block), this.cooldown_block_number)
    this.clicked_confirm = false;
    this.tx_sent = false;
    this.clicked_confirm2 = false;
    this.tx_sent2 = false;
    this.tx_id = '';
    this.end_block_num = Number(this.res_dict[this.selected_id].value.endBlock.value);
    this.cooldown_block_number = Number(this.res_dict[this.selected_id].value.cooldownBlock.value);
    if(this.cooldown_block_number === 999999999999999999){
      this.cooldown_block_number = 0;
    }
    // console.log('onChangeList 2', this.end_block_num, parseInt(this.current_block,10), this.cooldown_block_number)

    // tslint:disable-next-line:radix
    if(this.cooldown_block_number === 0 &&this.end_block_num<=parseInt(this.current_block,10)){
      // console.log("enable cooldown btn",  this.toogleCoolDown )

      this.toogleCoolDown = true;
      this.toogleUnstake = false;

      // tslint:disable-next-line:radix
    }else if (this.cooldown_block_number > 0 && this.cooldown_block_number<=parseInt(this.current_block,10)){
      // console.log("enable unstake btn")
      this.toogleCoolDown = false;
      this.toogleUnstake = true;
    }else{
      this.toogleCoolDown = false;
      this.toogleUnstake = false;
    }
    this.$forceUpdate();
    // console.log("enable cooldown btn22",  this.toogleCoolDown )

  }
  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }

  async created() {
    this.selected_id = 0;
    this.current_block = await getBlockHeight();
    // getBlockHeight().then((value)=>{
    //
    //   this.current_block = value.toString();
    // })
    getClaimRewardListFromVSTSW(this).then(([id_list, res_dict])=>{
      this.id_list = id_list;
      this.res_dict = res_dict;
      this.selected_id = id_list[0];
      this.onChangeList(null);
      // console.log('getClaimRewardListFromVSTSW', res_dict)
    })
    getCurrentStackingRound(this, CONTRACT_NAME_STACKSWAP_STACKING()).then(
        (value)=>{
          this.current_round = value;
        }
    )

    getStackingRoundFirstBlock(this,Number(this.current_round) + 1 ).then((value1) => {
      if(value1.value){
        // console.log('getStackingRoundFirstBlock', value1.value.toNumber())

        this.start_block_num = value1.value.toNumber();
      }
    });
    // getStackingRoundFirstBlock(this,Number(this.current_round) + 2 ).then((value1) => {
    //   if(value1){
    //     this.end_block_num = Number(value1) - 1;
    //   }
    // });
  }

  setCoolDownBlock(id:number){
    return this.cooldown_block_number;
    const cooldownBock = Number(this.res_dict[id].value.cooldownBlock.value);
    this.cooldown_block_number = cooldownBock;

    this.toogleCoolDown = false;
    if(cooldownBock === 999999999999999999){
      return '-'
      // tslint:disable-next-line:radix
    }else if(cooldownBock !== 999999999999999999 &&  this.cooldown_block_number > parseInt(this.current_block,10)){
      return cooldownBock.toString()
    }
    else{
      this.toogleCoolDown = true;
      return cooldownBock.toString()
    }

  }
  setTokenBalance(token_addr: string, id: number){
    if(token_addr === this.STSW_Token.addr){
      return (this.res_dict[id].value.amountSTSW.value / (10 ** this.STSW_Token.decimal)).toString();
    }else if(token_addr === this.vSTSW_Token.addr){
      return (this.res_dict[id].value.amountvSTSW.value / (10 ** this.vSTSW_Token.decimal)).toString();
    }else{
      return '0';
    }
  }
  onClickUnstake(){
    if(this.selected_id > 0){
      // this.openPopup("Run Unstaking");
      this.clicked_confirm = true;
      txUnstakingFromVSTSW(this, this.selected_id,
          new BigNumber(this.res_dict[this.selected_id].value.amountSTSW.value).toString(),
          new BigNumber(this.res_dict[this.selected_id].value.amountvSTSW.value).toString(),
      )
    }else{
      // alert("Please select stacking ID first")
      this.openPopup('Please select stacking ID first');
    }
  }
  onClickCooldown(){
    if(this.selected_id > 0){
      // alert("Run Cooldown");
      this.clicked_confirm2 = true;
      txCooldownFromVSTSW(this, this.selected_id);
    }else{
      alert('Please select stacking ID first')
      this.openPopup('Please select stacking ID first');
    }
  }
  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    this.popup_show = false;
  }
  // ==== PopupSetting =====//

  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    // this.$router.back();
    this.$router.push({name: 'staking'});
  }
  // setting을 오픈합니다.
  openSetting() {
    this.$store.dispatch('openSetting');
  }
}

