

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getSenderAddress, getTokenData} from '@/stacks/manager';
import axios from 'axios';
import {BASE_WSTX_DATA, STACKS_API_URL, STACKS_BACKEND_PARAM, STACKS_BACKEND_URL} from '@/stacks/config';

@Component
export default class TokenModal extends Vue{
  @Prop({default: false}) value!: boolean;
  @Prop() token_x!: any;
  @Prop() token_y!: any;

  searchToken: string = '';

  tokens: any = [
    BASE_WSTX_DATA(),
  ];
  communityTokens: any = [
  ];
  created() {
    const url = STACKS_BACKEND_URL() + '/stacks/tokens/' + STACKS_BACKEND_PARAM();
    axios.get ( url, {
      timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
    }).then( (res) => {
      // console.log('token', res.data)
      // for(let i = 0; i < res.data.length; i++) {
      //   if(res.data[i]['addr'] === 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.wstx-token-v4a'){
      //     res.data[i]['name'] = 'STX';
      //   }
      // }
      this.tokens = res.data;
    });
    const url2 = STACKS_BACKEND_URL() + '/stacks/communitytokens/' + STACKS_BACKEND_PARAM();
    axios.get ( url2, {
      timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
    }).then( (res) => { this.communityTokens = res.data; });
  }

  // 검색 input에 엔터를 치면 아래의 함수를 호출 합니다.
  search() {
    // console.log(this.searchToken.substring(0, this.searchToken.lastIndexOf('.')).length);
    if (this.searchToken.substring(0, this.searchToken.lastIndexOf('.')).length >= 40 ) {
      getTokenData(this, this.searchToken).then( (value1) => {
        if (value1 != null) {
          this.selected(value1);
        }
        // console.log(value1);
      });
    } else {


      axios.get(STACKS_API_URL() + '/v1/names/' + this.searchToken.substring(0, this.searchToken.lastIndexOf('.'))).then(
          (value2) => {
            // console.log('token_name', value2.data.address
            //     + this.searchToken.substring(this.searchToken.lastIndexOf('.'), this.searchToken.length));
            getTokenData(this, value2.data.address
                + this.searchToken.substring(this.searchToken.lastIndexOf('.'), this.searchToken.length)).then( (value1) => {
                  if (value1 != null) {
                    this.selected(value1);
                  }
                  // console.log(value1);
                },
            );
          });
    }
    alert('Loading data. Please wait.');
  }
  OnClickBack(event:any){
    // console.log("Back : ", event.target.id)
    if(event.target.id === 'background') {
      this.OnclickCancel(event);
    }
  }
  OnclickCancel(event:any){
    this.value = false;
    this.$emit('modalClosed', true);
    event.stopPropagation();
  }

  input(isOpen: boolean) {
    this.$emit('input', isOpen);
  }
  // 선택 완료시 해당 토큰값을 전달해줍니다.
  selected(token: any) {
    this.$emit('selected', token);
    this.input(false);
    this.value = false;
  }
  selectedEvent(event:any){
    event.stopPropagation();
  }
}
