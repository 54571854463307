
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import vote_popup from '@/router/staking/components/vote.popup.vue';
import vote_popup2 from '@/router/staking/components/vote.modal.vue';

import {
  getNumVoting,
  getPairData,
  getBalance,
  poolShareRatio,
  getLiquidityList,
  isInWhitelist,
  getBlockHeight, txWithdrawVoting, numFormat_
} from '@/stacks/manager';
import {BASE_vSTSW_DATA, BASE_WSTX_DATA, IS_TESTNET} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
import confirm_popup from'@/components/modal/confirm.popup.vue';
import tx_confirm from '@/components/modal/tx.modal.vue';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
    confirm_popup,
    tx_confirm,
    vote_popup,
    vote_popup2,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class VotePage extends Vue  {

  start_block:number = 0;
  end_block:number = 0;

  proposer:string = '';
  url_str:string = '';
  title:string = '';

  num_agree:number = 0;
  num_disagree:number = 0;
  num_my_vote:number = 0;

  proposal_id:number = 0;
  current_block:number = 0;
  vSTSW_balance:number = 0;

  is_claim:boolean= false;
  is_open:boolean = false;

  proposal_list:any[] = []

  toggleVote:boolean= false;
  toggleVoteWithdraw:boolean= false;

  vSTSW_Token = BASE_vSTSW_DATA();

  is_able_2_vote = false;
  created(){
    // console.log(this.$route.query.balance, typeof (this.$route.query.balance))
    // @ts-ignore
    // tslint:disable-next-line:radix
    this.start_block = parseInt(this.$route.query.proposal_dict['start-block-height'].value);
    // @ts-ignore
    // tslint:disable-next-line:radix
    this.end_block = parseInt(this.$route.query.proposal_dict['end-block-height'].value)
    // @ts-ignore
    this.num_agree = new BigNumber(this.$route.query.proposal_dict['yes-votes'].value).dividedBy(10**this.vSTSW_Token.decimal).toNumber();
    // @ts-ignore
    this.num_disagree = new BigNumber(this.$route.query.proposal_dict['no-votes'].value).dividedBy(10**this.vSTSW_Token.decimal).toNumber();

    // @ts-ignore
    this.url_str = this.$route.query.proposal_dict.url.value;
    // @ts-ignore
    this.title = this.$route.query.proposal_dict.title.value;
    // @ts-ignore
    this.proposer = this.$route.query.proposal_dict.proposer.value;
    // @ts-ignore
    // tslint:disable-next-line:radix
    this.proposal_id = parseInt(this.$route.query.proposal_dict.id.value);

    // @ts-ignore
    this.proposal_list = this.$route.query.proposal_dict['contract-changes'].value;

    // @ts-ignore
    this.is_open = this.$route.query.proposal_dict['is-open'].value;

    // @ts-ignore
    // tslint:disable-next-line:radix
    this.vSTSW_balance = parseInt(this.$route.query.balance);



    getBlockHeight().then((value)=>{
      // tslint:disable-next-line:radix
      this.current_block = parseInt(value.toString());
      getNumVoting(this, this.proposal_id).then(([value, res])=>{
        this.num_my_vote = new BigNumber(value).dividedBy(10**this.vSTSW_Token.decimal).toNumber();
        // tslint:disable-next-line:radix
        if(res === false && parseInt(value) > 0 && this.current_block > this.end_block){
          this.is_claim= true;
        }else{
          this.is_claim= false;
        }

        if(this.is_open === false && this.num_my_vote > 0){
          this.toggleVoteWithdraw= true;
        }
        else{
          this.toggleVoteWithdraw= false;
        }
        this.is_able_2_vote = this.IsOpenVote();
        this.getIsOpen();
        this.$forceUpdate();
      })
    })
  }
  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }
  OnConfirmClosed(){
    this.toggleVote = false;
  }

  getIsOpen(){

    if(this.start_block <= this.current_block &&
        this.current_block <=  this.end_block){
      this.is_open= true;
      return 'OPEN';
    }else{
      return 'CLOSE';
    }

  }
  onClickOpenVote_(){
    this.toggleVote = true;
  }
  IsOpenVote(){
    // console.log('Voting : ', this.start_block, this.current_block, this.end_block)
    if(this.start_block<= this.current_block && this.current_block <= this.end_block){
      return false;
    }else{
      return true;
    }

  }
  onClickOpenVote(){
    // console.log('Voting : ', this.start_block, this.current_block, this.end_block)
    if(this.start_block<= this.current_block && this.current_block <= this.end_block){
      this.toggleVote = true;
      return false;
    }else{
      return true;
    }

  }
  onClickWithdrawVote(){
    // alert("Withdarw")
    txWithdrawVoting(this, this.proposal_id);
  }
  onClickCloseVote() {
    this.toggleVote = false
  }

  // tx setting 모달
  toggleTxConfirm:boolean = false;
  OnTxOpen() {
    this.toggleTxConfirm = true;
  }
  OnTxClosed(){
    this.toggleTxConfirm = false;
  }

  // ==== PopupSetting =====//
  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.$router.back();
    // this.$router.push({name: 'liquidity'});
  }
  // setting을 오픈합니다.
  openSetting() {
    this.$store.dispatch('openSetting');
  }
}
