
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import luHeader from '@/layout/header.component.vue';

import BigNumber from 'bignumber.js';
import {
  depositSTSW_SIP10_to_ERC20,
  get_voters,
  GetFee,
  getVoteRes,
  registerTokenPair,
  vote,
  getETHToken,
  withdrawFixFee,
  WithdrawFee_RATIO,
  cancel_depositSTSW_SIP10_to_ERC20,
  getOrderIDInfo_From,
  getOrderIDInfo_To, update_depositSTSW_SIP10_to_ERC20, EmergencyWithdraw, withdrawFrom
} from '@/stacks/etc/bridge.manager';
import {BASE_STSW_DATA} from "@/stacks/config";

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class manageBridge extends Vue {

  bridge_contract: string = 'SP3YX02QBYNBWQ0QQ9CKSWT263C2KR9AE7BGZT6XA.bridge002';

  deposit_1_addr: string = '';
  deposit_1_amount: string = '';
  deposit_1_fee: string = '';
  deposit_1_stx_fee: string = '10';

  cancel_deposit_1_id: string = '';
  cancel_deposit_1_amount: string = '';

  vote_1_radio:string = 'True';
  voters: string = '';
  voteRes: string = '';

  register_sip_10_addr: string = BASE_STSW_DATA().addr;
  register_erc_addr: string = '0x1C98B54d673C026C8286bADCa3E840aaf72931a3';
  register_min: string = '';
  register_max: string = '';

  fixed_fee_to_withdraw: string = '';
  fixed_fee_in_contract: string = '';


  ratio_fee1_to_withdraw: string = '';
  ratio_fee1_in_contract: string = '';
  ratio_fee2_to_withdraw: string = '';
  ratio_fee2_in_contract: string = '';
  ratio_fee3_to_withdraw: string = '';
  ratio_fee3_in_contract: string = '';

  update_deposit_token: string = BASE_STSW_DATA().addr;
  update_deposit_round: string = '';
  update_deposit_orders: string = '';

  withdraw_amount: string = '';
  withdraw_amount_order: string = '';
  withdraw_orderID: string = '';


  created() {
    this.$store.dispatch('setCallback', this.onConnect);
    this.bridge_contract_setup();
  }

  onConnect() {
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  onCancelIDChange() {
    getOrderIDInfo_To(this, this.cancel_deposit_1_id, this.bridge_contract).then((orderInfo)=>{
      console.log(orderInfo);
      this.cancel_deposit_1_amount = new BigNumber(orderInfo.TokenAmt.value).dividedBy(10**6).toFixed(6);
    })
  }

  bridge_contract_setup() {
    get_voters(this, this.bridge_contract).then((voters) => {
      this.voters = ''+voters;
    });
    getVoteRes(this, this.bridge_contract).then((voteRes) => {
      this.voteRes = voteRes;
    });
    GetFee(this, this.bridge_contract).then((fee)=> {
      console.log(fee);
      this.fixed_fee_in_contract = new BigNumber(fee.value.FEE_FIX_AMT.value).dividedBy(10**6).toFixed(6) + ' STX';
      this.fixed_fee_to_withdraw = new BigNumber(fee.value.FEE_FIX_AMT.value).dividedBy(10**6).toFixed(6);
    });
    getETHToken(this, this.register_sip_10_addr, this.bridge_contract).then((ethToken)=> {
      console.log(ethToken);
      this.ratio_fee1_in_contract = new BigNumber(ethToken.FEE_RATIO_1_AMT.value).dividedBy(10**6).toFixed(6);
      this.ratio_fee1_to_withdraw = new BigNumber(ethToken.FEE_RATIO_1_AMT.value).dividedBy(10**6).toFixed(6);
      this.ratio_fee2_in_contract = new BigNumber(ethToken.FEE_RATIO_2_AMT.value).dividedBy(10**6).toFixed(6);
      this.ratio_fee2_to_withdraw = new BigNumber(ethToken.FEE_RATIO_2_AMT.value).dividedBy(10**6).toFixed(6);
      this.ratio_fee3_in_contract = new BigNumber(ethToken.FEE_RATIO_3_AMT.value).dividedBy(10**6).toFixed(6);
      this.ratio_fee3_to_withdraw = new BigNumber(ethToken.FEE_RATIO_3_AMT.value).dividedBy(10**6).toFixed(6);
    });
  }
  voteFor() {
    vote(this, this.vote_1_radio == 'True', this.bridge_contract);
  }

  depositSTSW_sip10_2_erc20() {
    depositSTSW_SIP10_to_ERC20(this, this.deposit_1_addr, this.deposit_1_amount, this.deposit_1_fee , this.bridge_contract)
  }

  registerToken() {
    registerTokenPair(this, this.register_sip_10_addr, this.register_erc_addr, this.register_min,this.register_max, this.bridge_contract)
  }
  cancelDeposit() {
    cancel_depositSTSW_SIP10_to_ERC20(this, this.cancel_deposit_1_id, this.cancel_deposit_1_amount, this.bridge_contract)
  }

  withdrawSTSW() {
    EmergencyWithdraw(this, this.withdraw_amount, this.bridge_contract);
  }
  withdrawSTSW_order() {
    withdrawFrom(this, this.withdraw_orderID, this.withdraw_amount_order, this.bridge_contract);
  }


  calcFee() {
    // console.log('on click fee')
    this.deposit_1_fee =  new BigNumber(this.deposit_1_amount).multipliedBy(6).dividedBy(100).toFixed(6);
  }

  withdrawFixedFee() {
    withdrawFixFee(this, this.fixed_fee_to_withdraw, this.bridge_contract);
  }

  withdrawRatioFee(type:string) {
    console.log(type);
    let amount = '';
    switch (type) {
      case 'ratio1' :
        amount = this.ratio_fee1_to_withdraw;
        break;
      case 'ratio2' :
        amount = this.ratio_fee2_to_withdraw;
        break;
      case 'ratio3' :
        amount = this.ratio_fee3_to_withdraw;
        break;
    }
    WithdrawFee_RATIO(this, type, this.register_sip_10_addr, amount, this.bridge_contract);
  }

  updateDeposit() {
    const templist = [];

    for (const order of this.update_deposit_orders.split(',')) {
      templist.push(order.trim());
    }
    update_depositSTSW_SIP10_to_ERC20(this, this.update_deposit_round, templist)
  }

}
