import {
    BASE_LBTC_DATA,
    BASE_STSW_DATA,
    BASE_STX,
    BASE_WSTX_DATA,
    CONTRACT_NAME_AINFT,
    CONTRACT_NAME_COMMISSION,
    CONTRACT_NAME_GOLD_PASS,
    CONTRACT_NAME_SILVER_PASS,
    CONTRACT_NAME_STACKSWAP_FARMING,
    CONTRACT_NAME_STACKSWAP_FARMING2,
    CONTRACT_NAME_STACKSWAP_STACKING, IPFS_URL, IPFS_URL2,
    STACKS_API_URL,
    STACKSWAP_ADDRESS,
    STSW_LBTC_LP,
    STX_STSW_LP,
} from '@/stacks/config';
import {
    bufferCV, bufferCVFromString,
    callReadOnlyFunction,
    contractPrincipalCV, createFungiblePostCondition, createSTXPostCondition,
    cvToValue, FungibleConditionCode,
    listCV, makeStandardNonFungiblePostCondition,
    standardPrincipalCV, TupleCV,
    uintCV,
} from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import {openContractCall} from '@stacks/connect';
import {
    getBasicReadOptions, getBasicTokenWriteOptionsCallbackCondition, getBasicWriteOptions,
    getBasicWriteOptionsConditonCallback,
    getBlockHeight,
    getPostConditionFromAsset,
    getSenderAddress,
} from '@/stacks/manager';
import {getDecimalStringfromString} from '@/stacks/token.tool';
import axios from 'axios';
import {createNonFungiblePostCondition} from '@stacks/transactions/src/postcondition';
import {NonFungibleConditionCode} from '@stacks/transactions/src/constants';
import {principalCV} from '@stacks/transactions/dist/clarity/types/principalCV';


async function getNFTAsset(tokenStr: string) {
    // console.log(tokenStr);
    const token_addr = tokenStr.split('.');
    const url = STACKS_API_URL() + '/v2/contracts/interface/' + token_addr[0] + '/' + token_addr[1];
    const result = await axios.get(url);
    // console.log(tokenStr,result);
    if (result.data.non_fungible_tokens.length === 1) {
        // console.log(tokenStr+'::'+result.data.non_fungible_tokens[0].name);
        return tokenStr + '::' + result.data.non_fungible_tokens[0].name;
    } else {
        // TODO one contract with more than one ft
        // console.log(tokenStr+'::'+result.data.non_fungible_tokens[0].name);
        return tokenStr + '::' + result.data.non_fungible_tokens[0].name;
    }
}
export async function getPostConditionFromAssetNFT(account: string, tokenStr: string, condition: NonFungibleConditionCode, id: string) {
    const assetString = await getNFTAsset(tokenStr);
    // console.log('account', account);
    // console.log('condition', condition);
    // console.log('assetString', assetString);
    // console.log(' uintCV(id)',  bufferCVFromString(id));
    return makeStandardNonFungiblePostCondition(account, condition, assetString, uintCV(id));
}

export async function mintNFT(source: any, price: string | number, addr: string) {
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, price, FungibleConditionCode.Equal));
    const function_option: any =  getBasicWriteOptionsConditonCallback(source, addr, 'mint', [
        standardPrincipalCV(getSenderAddress(source)),
    ], post_condition);
    await openContractCall(function_option);
}

export async function mintNFTs(source: any, price: string | number, addr: string, toAddr: string, counts: number) {
    const post_condition = [];
    const inputList = [];
    for (let i = 0; i < counts; i++) {
        inputList.push(standardPrincipalCV(toAddr));
    }
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, price, FungibleConditionCode.Equal));
    const function_option: any =  getBasicWriteOptionsConditonCallback(source, addr, 'batch-mint', [
        listCV(inputList),
    ], post_condition);
    await openContractCall(function_option);
}

export async function mintNFT2(source: any) {
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, source.mint_fee * 1000000, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STX_STSW_LP(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STSW_LBTC_LP(), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 'mint', [
        standardPrincipalCV(getSenderAddress(source)),
    ], post_condition);
    await openContractCall(function_option);
}

export async function sendMany(source: any, lists: TupleCV[], amountTotal: string) {
    const post_condition = [];

    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amountTotal, FungibleConditionCode.Equal));

    const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, 'SP3DEN0AZXRZV9ZBMQ7NB65V9NV4Y9TMF87NNP4RF.yeasty-turquoise-planarian', 'send-many', [
        listCV(lists),
    ], post_condition);
    await openContractCall(function_option);
}


export async function batchSetMintPass(source: any, lists: TupleCV[], addr: string) {
    const function_option: any =  getBasicWriteOptionsConditonCallback(source, addr, 'batch-set-mint-pass', [
        listCV(lists),
    ], []);
    await openContractCall(function_option);
}


export async function listInUstx(source: any, id: number, price: string, comm: string = CONTRACT_NAME_COMMISSION()) {

    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 'list-in-ustx', [
        uintCV(id),
        uintCV(price),
        contractPrincipalCV(STACKSWAP_ADDRESS(), comm),
    ], []);
    await openContractCall(function_option);
}

export async function unlistInUstx(source: any, id: number) {

    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 'unlist-in-ustx', [
        uintCV(id),
    ], []);
    await openContractCall(function_option);
}

export async function buyInUstx(source: any, id: number, price: number, owner: string, comm: string = CONTRACT_NAME_COMMISSION()) {
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(price).multipliedBy(1.075).toFixed(), FungibleConditionCode.LessEqual));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STX_STSW_LP(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STSW_LBTC_LP(), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    // post_condition.push(await getPostConditionFromAssetNFT(owner, STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_AINFT(), NonFungibleConditionCode.DoesNotOwn, id));
    post_condition.push(await getPostConditionFromAssetNFT(owner, STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), NonFungibleConditionCode.Sends, '' + id));
    // console.log(await getNFTAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_AINFT()))
    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 'buy-in-ustx', [
        uintCV(id),
        contractPrincipalCV(STACKSWAP_ADDRESS(), comm),
    ], post_condition);

    const function_option2 =  getBasicWriteOptions(source, CONTRACT_NAME_AINFT(), 'buy-in-ustx', [
        uintCV(id),
        contractPrincipalCV(STACKSWAP_ADDRESS(), comm),
    ]);
    await openContractCall(function_option);
}

export async function transferNFT(source: any, id: string, to: string, nft_address: string) {
    // console.log(id, to, STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_AINFT())
    // console.log(CONTRACT_NAME_AINFT())

    const post_condition = [];
    post_condition.push(await getPostConditionFromAssetNFT(getSenderAddress(source), STACKSWAP_ADDRESS() + '.' + nft_address, NonFungibleConditionCode.Sends, '' + id));
    // console.log(post_condition)

    const function_option: any =  getBasicWriteOptionsConditonCallback(source, nft_address, 'transfer', [
        uintCV(id),
        principalCV(getSenderAddress(source)),
        principalCV(to),
    ], post_condition);
    await openContractCall(function_option);
}

export async function reTouchNFT(source: any, id: number, amount: string) {
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, amount, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STX_STSW_LP(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STSW_LBTC_LP(), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAssetNFT(getSenderAddress(source), STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), NonFungibleConditionCode.Sends, '' + id));

    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 're-touch', [
        uintCV(id),
    ], post_condition);
    await openContractCall(function_option);
}

export async function regressNFT(source: any, id: number, price: string = '90000000') {
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), BASE_LBTC_DATA().addr, price, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAssetNFT(getSenderAddress(source), STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(), NonFungibleConditionCode.Sends, '' + id));


    const function_option: any =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_AINFT(), 'regress-token', [
        uintCV(id),
    ], post_condition);
    await openContractCall(function_option);
}

export async function getNFTData(source: any,  id: number) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-nft-data', [
        uintCV(id),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result.value;
}

export async function getOwner(source: any,  id: number) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-owner', [
        uintCV(id),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    try {
        return result.value.value;
    } catch (e) {
        return '';
    }
}

export async function getMintPass(source: any,  contract: string) {
    const function_option: any =  getBasicReadOptions(source, contract, 'get-mint-pass-balance', [
        principalCV(getSenderAddress(source)),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    try {
        return result;
    } catch (e) {
        return '';
    }
}

export async function getPassBalance(source: any,  contract: string) {
    const function_option: any =  getBasicReadOptions(source, contract, 'get-balance', [
        principalCV(getSenderAddress(source)),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    try {
        // console.log('result_raw', result_raw);
        // const result = cvToValue(result_raw);
        // console.log(result);
        // return result;
        return  cvToValue(result_raw);
    } catch (e) {
        return '0';
    }
}

export async function getReserveAmount(source: any,  id: number) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-reserve-amount', [
        uintCV(id),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result.value;
}

export async function getTokenURI(source: any,  id: number) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-token-uri', [
        uintCV(id),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result.value;
}

export async function getTotalList(source: any) {
    // console.log(source, CONTRACT_NAME_AINFT())
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-total-list', [
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('getTotalList', result);
    return result;
}

export async function getMarketList(source: any) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-market-list', [
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result;
}

export async function getMarketListing(source: any,  id: number) {
    const function_option: any =  getBasicReadOptions(source, CONTRACT_NAME_AINFT(), 'get-listing-in-ustx', [
        uintCV(id),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    try {
        // console.log(result.value.price.value);
        return result.value.price.value;
    } catch (e) {
        return 0;
    }
}

export async function getPASSData(source: any, isGold: boolean) {
    const contractaddr = isGold ? CONTRACT_NAME_GOLD_PASS() : CONTRACT_NAME_SILVER_PASS();
    const temp = {
          id: 0,
          name: 'loading...',
          img_url: `loading...`,
          // json_url: isGold ? 'https://ipfs.io/ipfs/QmQtSMAPvKFEr11VfKio2NHqwayJk4CTUkfmkxgXTR7Q2w' : 'https://ipfs.io/ipfs/QmQMArAThFXsFtDUyBWBepThCUbANjomWSw9cnmLALLcaM',
          json_url: isGold ? IPFS_URL() + '/QmQtSMAPvKFEr11VfKio2NHqwayJk4CTUkfmkxgXTR7Q2w' : IPFS_URL() + '/QmQMArAThFXsFtDUyBWBepThCUbANjomWSw9cnmLALLcaM',

          desc: 'loading...',
          attr:
            {
            },
          price: 0,
          owner: 'loading...',
          locked_amt: 0,
          addr : contractaddr,
          contract_name : STACKSWAP_ADDRESS() + '.' + contractaddr,
      };
    const json_result = await axios.get(temp.json_url);
    // console.log(json_result.data);
    temp.desc = json_result.data.description;
    temp.name = json_result.data.name;
    temp.img_url = IPFS_URL() + '/' + json_result.data.image.substr(7, json_result.data.image.length);
    // temp.attr.reTouch = json_result.data.attributes[0].value;
    // temp.price = await getMarketListing(source, id);
    // temp.locked_amt = await getReserveAmount(source, id);

    return temp;
}

export async function getNFTDataFromID(source: any, id_: number) {
    // json_url: `https://ipfs.io/ipfs/Qmaa5RRUMa9HQRHWJw3HWG3i1NUmb5VgJXT9Zqqg5VHQjX/${id_}.json`,
    const temp = {
          id: id_,
          name: 'loading...',
          img_url: `loading...`,
          json_url: IPFS_URL2() + `/Qmaa5RRUMa9HQRHWJw3HWG3i1NUmb5VgJXT9Zqqg5VHQjX/${id_}.json`,
          desc: 'loading...',
          attr:
            {
                reTouch: 0,
            },
          price: 0,
          owner: 'loading...',
          locked_amt: 0,
          contract_name : STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_AINFT(),
      };
    // const json_result = await axios.get(`https://ipfs.io/ipfs/Qmaa5RRUMa9HQRHWJw3HWG3i1NUmb5VgJXT9Zqqg5VHQjX/${id_}.json`);
    // temp.img_url = 'https://ipfs.io/ipfs/'+json_result.data.image.substr(7, json_result.data.image.length);
    // console.log(json_result.data);

    const json_result = await axios.get(IPFS_URL2() + `/Qmaa5RRUMa9HQRHWJw3HWG3i1NUmb5VgJXT9Zqqg5VHQjX/${id_}.json`);

    temp.img_url = IPFS_URL2() + '/' + json_result.data.image.substr(7, json_result.data.image.length);
    // temp.img_url = 'https://ipfs.io/ipfs/'+json_result.data.image.substr(7, json_result.data.image.length);
    // console.log(temp.img_url)
    temp.desc = json_result.data.description;
    temp.name = json_result.data.name;
    temp.attr.reTouch = json_result.data.attributes[0].value;
    temp.price = await getMarketListing(source, id_);
    temp.locked_amt = await getReserveAmount(source, id_);

    return temp;
}
