
import {Component, Prop, Vue} from 'vue-property-decorator';
import {getSenderAddress} from '@/stacks/manager';

@Component
export default class WalletModal extends Vue {
  @Prop({default: false}) value!: boolean;
  @Prop({default: 'Wallet Information'}) Title!: string;
  Subtitle: string = 'Full Wallet Address';

  OnClickConfirmBtn() {
    this.value = false;
    this.$emit('walletClosed', true);
    // console.log('confirm close')
  }

  OnclickCancel() {
    this.value = false;
    this.$emit('walletClosed', true);
  }

  getUserFull() {
    const temp = getSenderAddress(this);
    return temp;
  }

  copyAddress() {
    navigator.clipboard.writeText(this.getUserFull());
    alert('Copied address to clipboard.');
  }
  connectWallet() {
    this.$store.dispatch('connectWallet', this.$store.getters.callback);
  }

  disconnectWallet() {
    this.$store.dispatch('disConnectWallet');
    this.OnclickCancel();
  }

}
