import vault from './vault.vue';

export const subVaultRouter = [
    {
        path: 'sub_vault',
        name: 'sub_vault',
        component: vault,
        props: true,
    },
];
