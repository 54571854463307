
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getSenderAddress} from '@/stacks/manager';

@Component
export default class TxModal extends Vue {
  @Prop({default: false}) value!: boolean;
  @Prop({default: 'Wallet Information'}) Title!: string;
  Subtitle: string = 'Full Wallet Address';
  tolerance: number = 5;
  deadline: number = 60;
  backEle: any = null;

  created() {
    this.tolerance = this.$store.getters.tolerance;

  }

  checkDeadline() {
    this.deadline = Number(this.deadline);
    if (!this.deadline) {
      this.deadline = 60;
    } else if (this.deadline < 0) {
      this.deadline = this.deadline * -1;
    }
  }

  OnClickConfirmBtn(event: any) {
    this.value = false;
    this.$emit('txClosed', true);
    event.stopPropagation();
  }
  OnClickEvent(event: any) {
    event.stopPropagation();
  }
  OnClickBack(event: any) {
    if (event.target.id === 'background') {
      this.OnclickCancel(event);
    }
  }
  OnclickCancel(event: any) {
    this.value = false;
    this.$emit('txClosed', true);
    event.stopPropagation();
  }

  chooseTolerance(tolerance: number) {
    this.tolerance = tolerance;
    // console.log(this.tolerance)
  }

  saveSetting(event: any) {
    // console.log(":Close")
    this.$store.dispatch('closeSetting');
    this.$emit('txClosed', true);


    this.value = false;
    event.stopPropagation();
  }
  @Watch('$store.getters.toggleSettingModal')
  toggleSettingModal(val: boolean) {
    this.value = val;
  }

  @Watch('tolerance')
  changeTolerance() {
    this.$store.dispatch('setTolerance', this.tolerance);
  }

}
