import {
    BASE_STSW_DATA, BASE_WSTX_DATA, CONTRACT_NAME_ORACLE,
    CONTRACT_NAME_STACKSWAP_FARMING,
    CONTRACT_NAME_STACKSWAP_FARMING2, CONTRACT_NAME_STACKSWAP_FARMING2_1,
    CONTRACT_NAME_STACKSWAP_STACKING,
    STACKSWAP_ADDRESS,
} from '@/stacks/config';
import {
    callReadOnlyFunction,
    contractPrincipalCV,
    cvToValue, FungibleConditionCode,
    listCV,
    standardPrincipalCV,
    uintCV,
} from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import {openContractCall} from '@stacks/connect';
import {
    getBasicReadOptions, getBasicTokenOptions, getBasicTokenWriteOptionsCallbackCondition,
    getBasicWriteOptionsConditonCallback,
    getBlockHeight,
    getPostConditionFromAsset,
    getSenderAddress, parceAddressToCV,
} from '@/stacks/manager';
import {getDecimalStringfromString} from '@/stacks/token.tool';
import {values} from "lodash";

export async function getFarmingRound(source: any, bh: number) {
    try {
        const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getRewardRound', [
            uintCV(bh),
        ]);
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        // console.log('farming round', result);
        return result.value;
    } catch (e) {
        return 0;
    }
}

export async function getFarmingRound2(source: any, bh: number, farming_version: string) {
    try {
        const function_option =  getBasicReadOptions(source, farming_version, 'getRewardRound', [
            uintCV(bh),
        ]);
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        // console.log('farming round', result);
        return result.value;
    } catch (e) {
        return 0;
    }
}

export async function getFarmingRoundFirstBlock(source: any, cycle: number) {
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getFirstBlockOfRound', [
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result;
}

export async function getFarmingRoundFirstBlock2(source: any, cycle: number, farming_contract: string) {
    const function_option =  getBasicReadOptions(source, farming_contract, 'getFirstBlockOfRound', [
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log(result);
    return result;
}

export async function isUserMigratable(source: any, pool_address: string) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'isUserMigratable', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
    ]);
    try {
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        // console.log('migrate' , result);
        return result;
    } catch (e) {
        return true;
    }
}

export function getPoolUSDPriceFarm(farm: any, farm_total: any, oracle_stx: any, oracle_stsw: any, oracle_lbtc: any) {
    // BigNumber.config({ DECIMAL_PLACES: 6});
    let tvl = 0;
    switch (farm.BASE) {
        case 'STX':
            if (farm.token_x.symbol === farm.BASE) {
                tvl =  (new BigNumber(farm.pair['balance-x'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 12))).multipliedBy(2).multipliedBy(new BigNumber(oracle_stx)).toNumber();
            } else {
                tvl =  (new BigNumber(farm.pair['balance-y'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 12))).multipliedBy(2).multipliedBy(new BigNumber(oracle_stx)).toNumber();
            }
            break;
        case 'STSW':
            if (farm.token_x.symbol === farm.BASE) {
                tvl =  (new BigNumber(farm.pair['balance-x'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 12))).multipliedBy(2).multipliedBy(new BigNumber(oracle_stsw)).toNumber();
            } else {
                tvl =  (new BigNumber(farm.pair['balance-y'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 12))).multipliedBy(2).multipliedBy(new BigNumber(oracle_stsw)).toNumber();
            }
            break;
        case 'lBTC':
            if (farm.token_x.symbol === farm.BASE) {
                tvl =  (new BigNumber(farm.pair['balance-x'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 14))).multipliedBy(2).multipliedBy(new BigNumber(oracle_lbtc)).toNumber();
            } else {
                tvl =  (new BigNumber(farm.pair['balance-y'].value).multipliedBy(farm_total).dividedBy(farm.pair['shares-total'].value).dividedBy(new BigNumber(10 ** 14))).multipliedBy(2).multipliedBy(new BigNumber(oracle_lbtc)).toNumber();
            }
            break;
        default:
            tvl = 0;
    }
    return tvl;
}

export async function getFarming(pair: any , token_x: any, token_y: any, farm_total: any) {
    // BigNumber.config({ DECIMAL_PLACES: 6});
    const x_token_addr = token_x.addr;
    // console.log(token_x.addr);
    const y_token_addr = token_y.addr;
    // console.log(token_y.addr);
    let total_balacne = new BigNumber(-1);
    if (x_token_addr === BASE_WSTX_DATA().addr) {
        total_balacne =  (new BigNumber(pair['balance-x'].value).multipliedBy(farm_total).dividedBy(pair['shares-total'].value).dividedBy(new BigNumber(10 ** token_x.decimal))).multipliedBy(2);
    } else if (y_token_addr === BASE_WSTX_DATA().addr) {
        total_balacne =  (new BigNumber(pair['balance-y'].value).multipliedBy(farm_total).dividedBy(pair['shares-total'].value).dividedBy(new BigNumber(10 ** token_y.decimal))).multipliedBy(2);
    }
    // console.log('pool_stx_price', token_x.addr, token_y.addr,token_x.balance, token_y.balance,total_balacne.toNumber());
    // console.log(pair["balance-x"].value, pair["balance-y"].value, total_balacne.toNumber());
    return total_balacne;
}

export async function getFarmingStakerStatus(source: any, pool_address: string, cur_round: number) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getLPUserData', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    try {
        // console.log('getFarmingStakerStatus', result);
        const pooledToken = result.value.amountLP.value;
        const unclaimedList = [];
        const stakedList = [];
        for (const unclaimedElement of result.value.unclaimedList.value) {
            if ( unclaimedElement.value < cur_round) {
                unclaimedList.push(unclaimedElement.value);
                // console.log('TTT', unclaimedElement.value)
            }
            stakedList.push(unclaimedElement.value);
        }
        // console.log({pooledToken, unclaimedList, stakedList});
        return {pooledToken, unclaimedList, stakedList};
    } catch (e) {
        // console.log(e);
        return {pooledToken: 0, unclaimedList: [], stakedList: []};
    }
}

export async function getFarmingStakerStatus2(source: any, pool_address: string, cur_round: number, farming_contract: string) {
    const address = pool_address.split('.');
    console.log('getFarmingStakerStatus', farming_contract);
    const function_option =  getBasicReadOptions(source, farming_contract, 'getLPUserData', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    try {
        console.log('getFarmingStakerStatus', result);
        const pooledToken = result.value.amountLP.value;
        const unclaimedList = [];
        const stakedList = [];
        for (const unclaimedElement of result.value.unclaimedList.value) {
            if ( unclaimedElement.value < cur_round) {
                unclaimedList.push(unclaimedElement.value);
                // console.log('TTT', unclaimedElement.value)
            }
            stakedList.push(unclaimedElement.value);
        }
        // console.log({pooledToken, unclaimedList, stakedList});
        return {pooledToken, unclaimedList, stakedList};
    } catch (e) {
        console.log(e);
        return {pooledToken: 0, unclaimedList: [], stakedList: []};
    }
}


export async function getFarmingTotalStatusCycle(source: any, cycle: number) {
    // console.log(cycle);
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getTotalRoundDataOrDefault', [
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('FARMING TOTAL', result);
    try {
        return {
            reward: result.reward.value,
            value: result.value.value,
        };
        // return new BigNumber(result.value.amountUstx.value).multipliedBy(2).toFixed(0).toString();
    } catch (e) {
        return {
            reward: 0,
            value: 0,
        };
    }
}

export async function getFarmingTotalStatusCycle2(source: any, cycle: number, farming_contract: string) {
    // console.log(cycle);
    const function_option =  getBasicReadOptions(source, farming_contract, 'getTotalRoundDataOrDefault', [
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('FARMING TOTAL', result);
    try {
        return {
            reward: result.reward.value,
            value: result.value.value,
        };
        // return new BigNumber(result.value.amountUstx.value).multipliedBy(2).toFixed(0).toString();
    } catch (e) {
        return {
            reward: 0,
            value: 0,
        };
    }
}

/**
 *
 * @param source
 * @param pool_address
 * @param cycle
 *
 * {
 *     "type": "(tuple (amountLP uint) (weight uint))",
 *     "value": {
 *         "amountLP": {
 *             "type": "uint",
 *             "value": "122000000"
 *         },
 *         "weight": {
 *             "type": "uint",
 *             "value": "1"
 *         }
 *     }
 * }
 */
export async function getFarmingTotalPoolStatus(source: any, pool_address: string) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getLPTotalData', [
        contractPrincipalCV(address[0], address[1]),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('lp total data', result);
    try {
        return {amountLP: result.value.amountLP.value, weight: result.value.weight.value};
        // return {amountLP: result.value.amountLP.value, weight: '3'};
    } catch (e) {
        return {amountLP: '0', weight: '1'};
    }
}
export async function getFarmingTotalPoolStatus2(source: any, pool_address: string, farming_contract: string) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, farming_contract, 'getLPTotalData', [
        contractPrincipalCV(address[0], address[1]),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('lp total data', result);
    try {
        return {amountLP: result.value.amountLP.value, weight: result.value.weight.value};
        // return {amountLP: result.value.amountLP.value, weight: '3'};
    } catch (e) {
        return {amountLP: '0', weight: '1'};
    }
}
/**
 *
 * @param source
 * @param pool_address
 * @param cycle
 *
 *     {
 *       value: u0,
 *       weight: u0,
 *       amountLP: u0,
 *       price: u0
 *     }
 */
export async function getFarmingTotalPoolStatusCycle(source: any, pool_address: string, cycle: number) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getLPRoundData', [
        contractPrincipalCV(address[0], address[1]),
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('lp round data', result);
    try {
        return new BigNumber(result.value.amountUstx.value).multipliedBy(2).toFixed(0).toString();
    } catch (e) {
        return '0';
    }
}

export async function getFarmingTotalValue(source: any, pool_address: string) {
    try {
        const address = pool_address.split('.');
        const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getLPTotalData', [
            contractPrincipalCV(address[0], address[1]),
        ]);
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        // console.log('get_farming_total',result.value);
        return result.value;
    } catch (e) {
        return 0;
    }
}


export async function getFarmingUserRewardPerCycle(source: any, pool_address: string, cycle: number) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getFarmingReward', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('reward', result);
    return result;
}

export async function getFarmingUserRewardPerCycle2(source: any, pool_address: string, cycle: number, farming_contract: string) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, farming_contract, 'getFarmingReward', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('reward', result);
    return result;
}

export async function getFarmingUserRewardList(source: any, pool_address: string, unclaimed_list: any) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'getFarmingRewardFromList', [
        standardPrincipalCV(getSenderAddress(source)),
        contractPrincipalCV(address[0], address[1]),
        listCV(unclaimed_list),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('getFarmingUserRewardTotal', result);
    return result;
}

export async function getFarmingUserRewardList2(source: any, pool_address: string, unclaimed_list: any, farming_contract: string) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, farming_contract, 'getFarmingRewardFromList', [
        standardPrincipalCV(getSenderAddress(source)),
        contractPrincipalCV(address[0], address[1]),
        listCV(unclaimed_list),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log('getFarmingUserRewardTotal', result);
    return result;
}


export async function getFarmingRewardInfo(source: any, pool_address: string, cycle: number) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2_1(), 'getLPUserRoundData', [
        contractPrincipalCV(address[0], address[1]),
        standardPrincipalCV(getSenderAddress(source)),
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    console.log(result);
    return result.value.returnLP.value;
}

export async function getFarmingRewardInfo22(source: any, pool_address: string, farmerAddress: string, cycle: number) {
    const address = pool_address.split('.');
    const function_option =  getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_FARMING2_1(), 'getLPUserRoundData', [
        parceAddressToCV(pool_address),
        parceAddressToCV(farmerAddress),
        uintCV(cycle),
    ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    console.log('bbb',result.value['returnLP'].value);
    return result.value['returnLP'].value;
}

export async function migrateToVersion2(source: any, pool_address: string) {
    const address = pool_address.split('.');
    // console.log(amountTokens);
    const function_option =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'migrateFromVersion1', [
        contractPrincipalCV(address[0], address[1]),
    ], []);
    await openContractCall(function_option);
}

export async function stakeFarmingContract(source: any, pool_address: string, amountTokens: string, lockPeriod: number) {
    const address = pool_address.split('.');
    // console.log(amountTokens);
    const amount = new BigNumber(amountTokens).multipliedBy(10 ** 6).toFixed(0).toString();
    const function_option =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'stakeTokens', [
        uintCV(amount),
        contractPrincipalCV(address[0], address[1]),
        uintCV(lockPeriod),
        contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE()),
    ], [await getPostConditionFromAsset(getSenderAddress(source), pool_address, amount, FungibleConditionCode.Equal)]);
    await openContractCall(function_option);
}

export async function stakeFarmingContract2(source: any, pool_address: string, amountTokens: string, lockPeriod: number, farming_contract: string) {
    const address = pool_address.split('.');
    // console.log(amountTokens);
    const amount = new BigNumber(amountTokens).multipliedBy(10 ** 6).toFixed(0).toString();
    const function_option =  getBasicWriteOptionsConditonCallback(source, farming_contract, 'stakeTokens', [
        uintCV(amount),
        contractPrincipalCV(address[0], address[1]),
        uintCV(lockPeriod),
        contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE()),
    ], [await getPostConditionFromAsset(getSenderAddress(source), pool_address, amount, FungibleConditionCode.Equal)]);
    await openContractCall(function_option);
}


export async function getFarmBalance(source: any, token: any) {
    // console.log("getBalance", token)
    const pair_details_options: any = getBasicTokenOptions(source, token.addr, 'get-balance',
      [contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_FARMING2()) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        return cvToValue(result).value;
    } catch (e) {
        return 0;
    }
}

export async function getFarmBalance2(source: any, token: any, farming_contract: string) {
    // console.log("getBalance", token)
    const pair_details_options: any = getBasicTokenOptions(source, token.addr, 'get-balance',
        [contractPrincipalCV(STACKSWAP_ADDRESS(), farming_contract) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        return cvToValue(result).value;
    } catch (e) {
        return 0;
    }
}
export enum CONTRACT_TYPE {
    FARM_V2,
    STAKING,
    FARM_V3
}
export async function getFarmAmount2(source: any, addr: string, token: any, farming_contract: string, contract_type: CONTRACT_TYPE, group: string = '') {
    // console.log("getBalance", token)
    let function_option:any = null;
    try {
        switch (contract_type) {
            case CONTRACT_TYPE.FARM_V2: {
                function_option = getBasicTokenWriteOptionsCallbackCondition(source, addr, 'getTotalRoundDataOrDefault', [
                    uintCV(100),
                ], []);
                const result = await callReadOnlyFunction(function_option);
                console.log(cvToValue(result));
                return cvToValue(result).reward.value;
            }
            case CONTRACT_TYPE.STAKING: {
                function_option = getBasicTokenWriteOptionsCallbackCondition(source, addr, 'get-staking-stats-at-cycle-or-default', [
                    uintCV(100),
                ], []);
                const result = await callReadOnlyFunction(function_option);
                console.log(cvToValue(result));
                return cvToValue(result).reward.value;
            }
            case CONTRACT_TYPE.FARM_V3: {
                function_option = getBasicTokenWriteOptionsCallbackCondition(source, addr, 'getGroupOrDefault', [
                    uintCV(group),
                ], []);
                const result = await callReadOnlyFunction(function_option);
                console.log(cvToValue(result));
                return cvToValue(result).CurRewardAmt.value;
            }
        }

    // const pair_details_options : any = getBasicTokenOptions(source, token.addr, 'getTotalRoundDataOrDefault',
    //   [uintCV(100)]);
        // return cvToValue(result).value;
    } catch (e) {
        return 0;
    }

}
export async function set_new_reward(source: any, addr: string, amount: string, isFarming: boolean) {

    // console.log({amount});
    // const post_condition = [await getPostConditionFromAsset(addr, BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];

    const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, addr, isFarming ? 'changeRewardAmount' : 'set-mining-reward', [
        uintCV(amount),
    ], []);
    await openContractCall(function_option);
}


export async function claimFarmingContract(source: any, pool_address: string, cycle: number, amount_STSW: string, amount_LP: string) {
    const address = pool_address.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_STACKSWAP_FARMING2(), BASE_STSW_DATA().addr, amount_STSW, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_STACKSWAP_FARMING2(), pool_address, amount_LP, FungibleConditionCode.Equal));
    const function_option =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'claimStakingReward', [
        uintCV(cycle),
        contractPrincipalCV(address[0], address[1]),
        contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE()),
    ], post_condition);
    await openContractCall(function_option);
}

export async function claimFarmingContract2(source: any, pool_address: string, cycle: number, amount_STSW: string, amount_LP: string, farming_contract: string) {
    const address = pool_address.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + farming_contract, BASE_STSW_DATA().addr, amount_STSW, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + farming_contract, pool_address, amount_LP, FungibleConditionCode.Equal));
    const function_option =  getBasicWriteOptionsConditonCallback(source, farming_contract, 'claimStakingReward', [
        uintCV(cycle),
        contractPrincipalCV(address[0], address[1]),
        contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE()),
    ], post_condition);
    await openContractCall(function_option);
}


export async function unstakeFarmingContract(source: any, pool_address: string, cycle: number, amount_STSW: string, amount_LP: string) {
    const address = pool_address.split('.');
    const post_condition = [];
    // post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_FARMING(), BASE_STSW_DATA().addr, amount_STSW, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_STACKSWAP_FARMING2(), pool_address, amount_LP, FungibleConditionCode.Equal));
    const function_option =  getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_FARMING2(), 'unstake-from-farming', [
        uintCV(cycle),
        contractPrincipalCV(address[0], address[1]),
    ], post_condition);
    await openContractCall(function_option);
}


export async function getCurrentFarmingRound(source: any) {
    const bh = await getBlockHeight();
    return await getFarmingRound(source, bh);
}

export async function getCurrentFarmingRound2(source: any, farming_contract: string) {
    const bh = await getBlockHeight();
    return await getFarmingRound2(source, bh, farming_contract);
}


export async function getRewardBalanceFromFarming(source: any, pool_addr: string, unclaimed_list: any) {
    // let reward_balance = new BigNumber(0);
    const num_list = [];
    for (const unclaimed of unclaimed_list) {
        num_list.push(uintCV(unclaimed));
    }
    let reward_num = new BigNumber(0);

    try {
        for (let i = 0; i < num_list.length; i += 4) {
            // console.log(i, num_list.slice(i, i+4 < num_list.length ? i + 4 : num_list.length));
            const result = await getFarmingUserRewardList(source, pool_addr, num_list.slice(i, i + 4 < num_list.length ? i + 4 : num_list.length));
            // rewardSum.value;
            // console.log('temp result', result);
            reward_num = reward_num.plus( new BigNumber(result.rewardSum.value));
        }
        return getDecimalStringfromString(reward_num.toString(), 6);
    } catch (e) {
        // console.log(e);
        return getDecimalStringfromString(reward_num.toString(), 6);
    }
}

export async function getRewardBalanceFromFarming2(source: any, pool_addr: string, unclaimed_list: any, farming_contract: string) {
    // let reward_balance = new BigNumber(0);
    const num_list = [];
    for (const unclaimed of unclaimed_list) {
        num_list.push(uintCV(unclaimed));
    }
    let reward_num = new BigNumber(0);
    console.log(pool_addr, num_list, farming_contract);
    try {
        for (let i = 0; i < num_list.length; i += 4) {
            console.log( pool_addr, num_list.slice(i, i + 4 < num_list.length ? i + 4 : num_list.length), farming_contract);
            const result = await getFarmingUserRewardList2(source, pool_addr, num_list.slice(i, i + 4 < num_list.length ? i + 4 : num_list.length), farming_contract);
            // rewardSum.value;
            console.log('temp result', result.rewardSum.value);
            reward_num = reward_num.plus( new BigNumber(result.rewardSum.value));
        }
        return getDecimalStringfromString(reward_num.toString(), 6);
    } catch (e) {
        // console.log(e);
        return getDecimalStringfromString(reward_num.toString(), 6);
    }
}


export function getFarmingAPR(tvl: string, stsw_price: string, reward: string, weight: string) {
    // console.log('farmingAPR');
    // console.log({tvl, stsw_price, reward, weight})
    if ( !tvl || new BigNumber(tvl) <= new BigNumber(0) ) {
        const temp = new BigNumber(reward).dividedBy(10 ** 6).multipliedBy(52).multipliedBy(stsw_price);
        // console.log('reward_to_stx', temp.toFixed(3).toString());
        // console.log(typeof temp);
        return temp.toFixed(3).toString();
    } else {
        BigNumber.config({ DECIMAL_PLACES: 10});
        const tvl_bn = new BigNumber(tvl).multipliedBy(10 ** 12);
        // console.log({tvl_bn: tvl_bn.toString()});
        const reward_usd = new BigNumber(reward).multipliedBy(stsw_price);
        // console.log({reward_stx: reward_usd.toString()});
        const new_reward = reward_usd.div(tvl_bn);
        // console.log({new_reward: new_reward.toString()});
        const new_reward_apr = new_reward.multipliedBy(5200).multipliedBy(weight);
        // console.log({new_reward_apr: new_reward_apr.toString()});
        return new_reward_apr.toFixed(3).toString();
    }
}
