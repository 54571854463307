
 import {Component, Vue} from 'vue-property-decorator';
 import { mapGetters } from 'vuex';
 import VueSlider from 'vue-slider-component';
 import 'vue-slider-component/theme/antd.css';
 import {
   getBalance,
   txStakeFromStaking,
   getCurrentStackingRound,
   numFormat_, getStakingAPR, getStackingRoundFirstBlock
 } from '@/stacks/manager';
 import {
   BASE_STSW_DATA,
   CONTRACT_NAME_STACKSWAP_STACKING,
   MODE,
   STACKSWAP_ADDRESS
 } from '@/stacks/config';
 import luHeader from '@/layout/header.component.vue';
 import confirm_popup from'@/components/modal/confirm.popup.vue';
 import tx_confirm from '@/components/modal/tx.modal.vue';
 import BigNumber from 'bignumber.js';

 @Component({
   components: {
     luHeader,
     VueSlider,
     confirm_popup,
     tx_confirm,
   },
   computed: {
     ...mapGetters([
       'isConnected',
     ]),
   },
 })
 export default class StakePage extends Vue  {
   STSW_balance: string = 'Loading';
   STSW_Token = BASE_STSW_DATA();
   invest_amount: string = '0';
   stacking_period: number = 1;
   APR = 'Loading...';

   current_round: any = null;
   start_block_num: number = 0;
   end_block_num: number=0;
   round_per_block: number = 4320;
   options: any = {
     min: 1,
     max: 64,
   };

   // block_per_round: number = 4032;

   // popup-var
   popup_show: boolean = false;
   popup_title: string = '';
   popup_contents: string = 'None-Contents';

   numFormat (input: any, decimal: number = 3){
     return numFormat_ (input, decimal);
   }

   // ==== PopupSetting =====//
   openPopup(contents: string){
     this.popup_show=true;
     this.popup_contents=contents;
   }
   closePopup(){
     this.popup_show = false;
   }
   // ==== PopupSetting =====//

   // tx setting 모달
   toggleTxConfirm: boolean = false;
   OnTxOpen() {
     this.toggleTxConfirm = true;
   }
   OnTxClosed(){
     this.toggleTxConfirm = false;
   }

   clicked_confirm : boolean = false;
   tx_sent : boolean = false;
   tx_id : string = '';

   onWalletCancel(){
     // console.log('user closed wallet');
     this.clicked_confirm = false;
   }
   onWalletSuccess(data: any){
     // console.log('user tx successes', data);
     this.tx_id = data.txId;
     this.tx_sent = true;
     this.clicked_confirm = false;
   }
   viewTX() {
     window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
   }

   created() {
     getBalance(this, this.STSW_Token).then((value) =>
         this.STSW_balance = this.setTokenBalance(this.STSW_Token).toString()
     );
     getCurrentStackingRound(this,  STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_STACKSWAP_STACKING()).then(
         (value)=>{
           this.current_round = value;
         }
     )
     getStackingRoundFirstBlock(this,Number(this.current_round) + 1 ).then((value1) => {
       if(value1){
         this.start_block_num = value1;
         this.end_block_num = Number(value1) + Number(this.round_per_block) - 1;
       }
     });
     this.setAPR();
     // getStackingRoundFirstBlock(this,Number(this.current_round) + 2 ).then((value1) => {
     //   if(value1){
     //     this.end_block_num = Number(value1) - 1;
     //   }
     // });
   }
   setTokenBalance(token_obj:any){
     // alert(`${token_obj.balance / (10 ** token_obj.decimal)}`)
     return token_obj.balance / (10 ** token_obj.decimal);
   }
   setInvestAmount(){
     // console.log("F", this.invest_amount);
   }
   onClickPeriod(new_period:number){
     this.stacking_period = new_period;
     this.end_block_num = Number(this.start_block_num) + Number(this.round_per_block) * Number(this.stacking_period) -1;
   }

   onChangePeriod(){
     this.setAPR();
     this.end_block_num = Number(this.start_block_num) + Number(this.round_per_block) * Number(this.stacking_period) -1;

     // getStackingRoundFirstBlock(this,Number(this.current_round) + 1 + Number(this.stacking_period) ).then((value1) => {
     //   if(value1){
     //     this.end_block_num = Number(value1) - 1;
     //   }
     // });
   }

   setAPR(){
     const APR_1 = getStakingAPR(this.$route.params.tvl, new BigNumber(this.invest_amount).multipliedBy(10**6).toFixed(0).toString(), this.stacking_period);
     // const APR_36 = getStakingAPR(this.$route.params.tvl, new BigNumber(this.invest_amount).multipliedBy(10**6).toFixed(0).toString(),this.stacking_period);
     this.APR = APR_1 + '%';
   }

   // 숫자하고 . 만 입력하게 합니다.
   onlyNumber($event: KeyboardEvent) {
     const keyCode = $event.keyCode;
     if (keyCode < 48 || keyCode > 57) {
       if (keyCode === 46) {
         const target: any = $event.target;
         $event.returnValue = target.value.split('.').length > 1 ? false : true;
       } else {
         $event.returnValue = false;
       }
     }
     // console.log($event);
     this.setAPR();
   }


   onClickMax(){
     this.invest_amount = parseFloat(this.STSW_balance).toString();
   }

   onClickStaking(){
     // console.log(typeof (this.stacking_period), typeof (this.invest_amount))
     // alert(`${this.stacking_period} ${this.invest_amount}`)
     // console.log("onClickStaking", this.invest_amount, typeof (this.invest_amount))
     const input_invest = parseFloat(this.invest_amount);
     if(input_invest > 0) {
       this.clicked_confirm = true;
       txStakeFromStaking(this,
           input_invest,
           this.stacking_period
       )
     }else{
       // alert('invest amount should be bigger than 0')
       this.openPopup('invest amount should be bigger than 0')
     }
   }

   // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
   back() {
     // this.$router.back();
     this.$router.push({name: 'staking'});
   }
   // setting을 오픈합니다.
   openSetting() {
     this.$store.dispatch('openSetting');
   }
 }
 