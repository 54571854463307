import { StoreOptions } from 'vuex';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

export const nftStore: StoreOptions<any> = {
    state: {
        project_contract: '',
        project_title: '',
        project_explain: '',
        project_img: '',
        total_items: 0,
        listed_items: 0,
    },
    actions: {
        set_project_contract({commit}, project_contract) {
            commit('set_project_contract', project_contract);
        },
        set_project_title({commit}, project_title) {
            commit('set_project_title', project_title);
        },
        set_project_explain({commit}, project_explain) {
            commit('set_project_explain', project_explain);
        },
        set_project_img({commit}, project_img) {
            commit('set_project_img', project_img);
        },
        set_total_items({commit}, total_items) {
            commit('set_total_items', total_items);
        },
        set_listed_items({commit}, listed_items) {
            commit('set_listed_items', listed_items);
        },
    },
    getters: {
        project_contract: (state) => state.project_contract,
        project_title: (state) => state.project_title,
        project_explain: (state) => state.project_explain,
        project_img: (state) => state.project_img,
        total_items: (state) => state.total_items,
        listed_items: (state) => state.listed_items,
    },
    mutations: {
        set_project_contract(state, project_contract) {
            state.project_contract = project_contract;
        },
        set_project_title(state, project_title) {
            state.project_title = project_title;
        },
        set_project_explain(state, project_explain) {
            state.project_explain = project_explain;
        },
        set_project_img(state, project_img) {
            state.project_img = project_img;
        },
        set_total_items(state, total_items) {
            state.total_items = total_items;
        },
        set_listed_items(state, listed_items) {
            state.listed_items = listed_items;
        },
    },
};
