
import { Component, Prop, Vue } from 'vue-property-decorator';
import modal from '@/components/modal/modal.component.vue';
import axios from 'axios';
import {
  BASE_WSTX_DATA,
  CONTRACT_NAME_POXL_TOKEN,
  IS_TESTNET,
  MIAMI_DATA, NEWYORK_DATA,
  STACKS_BACKEND_PARAM,
  STACKS_BACKEND_URL
} from '@/stacks/config';
import {getTokenData} from '@/stacks/manager';

@Component({
  components: {
    modal,
  },
})

export default class SelectTokenComponent extends Vue {
  @Prop() value!: boolean;
  @Prop() token!: any;
  // 검색할 토큰 명입니다.
  searchToken: string = '';
  // 토큰의 리스트가 나와야되는 부분입니다.
  tokens: any = [
    // MIAMI_DATA(),
    // NEWYORK_DATA(),
    {name:'MiamiCoin', decimal:0, icon:'https://app.stackswap.org/icon/miamicoin.png', addr:'SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27.miamicoin-core-v1', token_addr: 'SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27.miamicoin-token', symbol: 'MIA'},
    {name:'NewyorkCityCoin', decimal:0, icon:'https://app.stackswap.org/icon/newyorkcitycoin.png', addr:'SP2H8PY27SEZ03MWRKS5XABZYQN17ETGQS3527SA5.newyorkcitycoin-core-v1', token_addr: 'SP2H8PY27SEZ03MWRKS5XABZYQN17ETGQS3527SA5.newyorkcitycoin-token', symbol: 'NYC'}
  ];
  created() {
    // console.log(NEWYORK_DATA());
    // console.log('tokens', this.tokens);
    const url = STACKS_BACKEND_URL() + '/stacks/tokens/' + STACKS_BACKEND_PARAM();
    axios.get ( url, {
      timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
    }).then( (res) => {
      // console.log(res.data);
      // @ts-ignore
      const fixed_res =  res.data.filter((data) => data.addr.includes(CONTRACT_NAME_POXL_TOKEN()));
      this.tokens = this.tokens.concat(fixed_res);

      const url2 = STACKS_BACKEND_URL() + '/stacks/communitytokens/' + STACKS_BACKEND_PARAM();
      axios.get ( url2, {
        timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
      }).then( (res2) => {
        // @ts-ignore
        const fixed_res2 =  res2.data.filter((data) => data.addr.includes(CONTRACT_NAME_POXL_TOKEN()));
        this.tokens = this.tokens.concat(fixed_res2);
      });
    });

  }
  OnClickEvent(event:any){
    event.stopPropagation();
  }
  input(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  // 선택 완료시 해당 토큰값을 전달해줍니다.
  selected(token: any) {
    this.$emit('selected', token);
    this.input(false);
  }

  // 검색 input에 엔터를 치면 아래의 함수를 호출 합니다.
  search() {
    // console.log(this.searchToken);
    getTokenData(this, this.searchToken).then((value1) => {
      if (value1 != null) {
        this.selected(value1);
      }
      // console.log(value1);
    });
    alert('Loading data. Please wait.');
  }
  OnClickBack(event:any){
    if(event.target.id === 'background') {
      this.OnclickCancel(event);
    }
  }
  OnclickCancel(event:any){
    this.value = false;
    this.$emit('txClosed', true);
    event.stopPropagation();
  }
}
