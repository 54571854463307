// @ts-ignore
import { BigNumber } from 'bignumber.js';

export function getPriceAndDY2(x_to_y: boolean, pair: any, token_x: any, token_y: any ) {
    // console.log('price impact');
    //  (dy (/ (* u997 balance-y dx) (+ (* u1000 balance-x) (* u997 dx)))) ;; overall fee is 30 bp, either all for the pool, or 25 bp for pool and 5 bp for operator
    // dy = balancey * dx / balancex + dx
    try {
        const balance_x = x_to_y ? pair['balance-x'] : pair['balance-y'];
        // console.log('balance_x',balance_x);

        const balance_y = x_to_y ? pair['balance-y'] : pair['balance-x'];
        const decimal_x =  token_x.decimal;
        const decimal_y = token_y.decimal ;
        // console.log('decimal_Y',decimal_y);
        // console.log('balance_y',balance_y);
        const dx = token_x.amount * (10 ** decimal_x) * 0.997;
        let dx2 = new BigNumber(10).exponentiatedBy(decimal_x).multipliedBy(token_x.amount).multipliedBy(0.997);
        if (!token_x.amount) {
            dx2 = new BigNumber(1);
        }
        if (dx2.comparedTo(balance_x) >= 0) {
            // console.log('dx is bigger than dy');
            return {dy: '0', output_price: '0'};
        }
        const dy2 = (new BigNumber(balance_y).multipliedBy(dx2)).dividedBy(new BigNumber(balance_x).plus(dx2));
        const output_price2 = ((new BigNumber(10).exponentiatedBy(decimal_y)).dividedBy(new BigNumber(10).exponentiatedBy(decimal_x))).toNumber() * ((dx2).toNumber() / (dy2).toNumber());
        // console.log('dx2/dy2', (dx2).toNumber() / (dy2).toNumber())
        // console.log( dx2.dividedBy(dy2).multipliedBy(new BigNumber(10).exponentiatedBy(decimal_y)).toNumber())
        // console.log(output_price2)
        const output_price_final = output_price2.toString();
        // BigNumber.config({ DECIMAL_PLACES: decimal_y});
        const dy_final = dy2.dividedBy(new BigNumber(10).exponentiatedBy(decimal_y)).toString();
        // console.log('output price', output_price_final, dy_final);

        if (!token_x.amount) {
            return {dy: '0', output_price: output_price_final};
        }
        // return {dy: (getDecimalStringfromString2(dy.toString(), decimal_y)), output_price: getDecimalStringfromString2(output_price2.toString(), 10)};
        return {dy: dy_final, output_price: output_price_final};
    } catch (e) {
        // console.log(e);
        return {dy: '0', output_price: '0'};
    }
}


export function getPriceAndDY(x_to_y: boolean, pair: any, token_x: any, token_y: any ) {
    // console.log('price impact');
    //  (dy (/ (* u997 balance-y dx) (+ (* u1000 balance-x) (* u997 dx)))) ;; overall fee is 30 bp, either all for the pool, or 25 bp for pool and 5 bp for operator
    // dy = balancey * dx / balancex + dx
    try {
        const balance_x = x_to_y ? pair['balance-x'].value : pair['balance-y'].value;
        // console.log('balance_x',balance_x);

        const balance_y = x_to_y ? pair['balance-y'].value : pair['balance-x'].value;
        const decimal_x =  token_x.decimal;
        const decimal_y = token_y.decimal ;
        // console.log('decimal_Y',decimal_y);
        // console.log('balance_y',balance_y);
        const dx = token_x.amount * (10 ** decimal_x) * 0.997;
        let dx2 = new BigNumber(10).exponentiatedBy(decimal_x).multipliedBy(token_x.amount).multipliedBy(0.997);
        if (!token_x.amount) {
            dx2 = new BigNumber(1);
        }
        if (dx2.comparedTo(balance_x) >= 0) {
            // console.log('dx is bigger than dy');
            return {dy: '0', output_price: '0'};
        }
        const dy2 = (new BigNumber(balance_y).multipliedBy(dx2)).dividedBy(new BigNumber(balance_x).plus(dx2));
        const output_price2 = ((new BigNumber(10).exponentiatedBy(decimal_y)).dividedBy(new BigNumber(10).exponentiatedBy(decimal_x))).toNumber() * ((dx2).toNumber() / (dy2).toNumber());
        // console.log('dx2/dy2', (dx2).toNumber() / (dy2).toNumber())
        // console.log( dx2.dividedBy(dy2).multipliedBy(new BigNumber(10).exponentiatedBy(decimal_y)).toNumber())
        // console.log(output_price2)
        const output_price_final = output_price2.toString();
        // BigNumber.config({ DECIMAL_PLACES: decimal_y});
        const dy_final = dy2.dividedBy(new BigNumber(10).exponentiatedBy(decimal_y)).toString();
        // console.log('output price', output_price_final, dy_final);

        if (!token_x.amount) {
            return {dy: '0', output_price: output_price_final};
        }
        // return {dy: (getDecimalStringfromString2(dy.toString(), decimal_y)), output_price: getDecimalStringfromString2(output_price2.toString(), 10)};
        return {dy: dy_final, output_price: output_price_final};
    } catch (e) {
        // console.log(e);
        return {dy: '0', output_price: '0'};
    }
}

export function getAmountWithDecimalString(amount: number, decimal: number) {
    BigNumber.config({ EXPONENTIAL_AT: 40 });
    try {
        return new BigNumber(10 ** decimal).multipliedBy(amount).toString();
    } catch (e) {
        return new BigNumber(10 ** decimal).multipliedBy(new BigNumber(amount)).toString();
    }
}
export function getAmountStringWithDecimal(amount: string, decimal: number) {
    // console.log(new BigNumber(10 ** decimal).toString());
    try {
        return new BigNumber(10 ** decimal).multipliedBy(amount).toString();
    } catch (e) {
        return new BigNumber(10 ** decimal).multipliedBy(new BigNumber(amount)).toString();
    }
}
export function getDecimalStringfromString2(amount: string, decimal: number) {
    const amount_bn = new BigNumber(amount);
    const decimal_bn = new BigNumber(10 ** decimal);
    BigNumber.config({ DECIMAL_PLACES: 3});
    return (amount_bn.dividedBy(decimal_bn)).toFixed(0).toString();
}

export function getDecimalStringfromString(amount: string, decimal: number) {
    const len_str = amount.length;
    if ( len_str > decimal) {
        const temp = amount.substr(0, len_str - decimal) + '.' + amount.substr(len_str - decimal, len_str).replace(/.0+$/, '');
        if (temp === '0.') {
            return '0';
        } else {
            if (temp.charAt(temp.length - 1) === '.') {
                return temp.substr(0, temp.length - 1);
            } else {
                return temp;
            }
        }
    } else {
        const temp = '0'.repeat(decimal - len_str + 1) + amount;
        const temp2 = temp.substr(0, temp.length - decimal) + '.' + temp.substr(temp.length - decimal, temp.length).replace(/0+$/, '');

        if (temp2 === '0.') {
            return '0';
        } else {
            if (temp2.charAt(temp2.length - 1) === '.') {
                return temp2.substr(0, temp2.length - 1);
            } else {
                return temp2;
            }
        }
    }
}
