import Vue from 'vue';
import VueRouter from 'vue-router';
import { swapRouter } from '@/router/swap/swap.router';
import { poolRouter } from '@/router/pool/pool.router';
import { legacypoolRouter } from '@/router/legacypool/pool.router';
import {launchpadRouter} from '@/router/launchpad/launchpadRouter';
import { poxlRouter } from '@/router/poxlmining/poxl.router';
import { farmRouter } from '@/router/farming/farm.router';
import { stakingRouter } from '@/router/staking/staking.router';
import {proposeRouter} from '@/router/propose/propose.router';
import {aboutRouter} from '@/router/about/about.router';
import {stswDepositRouter} from '@/router/stswDeposit/stswDeposit';
import {stxStackingRouter} from '@/router/stxStacking/stxStacking';
import {vaultRouter} from '@/router/vault/vault.router';
import {eventRewardRouter} from '@/router/eventReward/event.reward.router';
import {nftRouter} from '@/router/nft/nft.router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // ...swapRouter,
    // ...poolRouter,
    // ...legacypoolRouter,
    // ...launchpadRouter,
    ...poxlRouter,
    // ...farmRouter,
    ...stakingRouter,
    ...proposeRouter,
    ...aboutRouter,
    ...stswDepositRouter,
    ...stxStackingRouter,
    ...vaultRouter,
    ...eventRewardRouter,
    // ...nftRouter,
  ],
  scrollBehavior() {
    const el: any = document.getElementById('app');
    el.scrollTop = 0;
  },
});

router.beforeEach(async (to: any, from: any, next: any) => {
  // path가 / <- 이거 일시 swap페이지로 가라는 명령어 입니다.
  if (to.path === '/') {
    return next('staking');
  }

  next();
});

export default router;
