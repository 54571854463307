
import {Component, Prop, Vue} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
// import selectToken from '@/router/swap/components/select-token.component.vue';
// import confirm from '@/router/swap/components/confirm.component.vue';
import {getPairData, getBalance, isInWhitelist, numWithComma, numFormat_, getBlockHeight} from '@/stacks/manager';
import {BASE_STSW_DATA, BASE_WSTX_DATA, BASE_LBTC_DATA, IS_TESTNET, STACKS_BACKEND_PARAM, STACKS_BACKEND_URL} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
import {getPriceAndDY} from '@/stacks/token.tool';
import tx_confirm from '@/components/modal/tx.modal.vue';
import selectToken2 from '@/router/swap/components/select-token.modal.vue';
import confirm2 from '@/router/swap/components/confirm.modal.vue';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import {
  getVaultInfoFromID,
  getVaultTypeInfo,
  txDepositCollateral,
  txMintDebt,
  txBurnDebt,
  txUnliquidationDeposit,
  txUnliquidationBurn,
  txToggleStacking,
  txCloseVault,
  txStackCollateral,
  txEnableWithdraw,
  txWithdrawCollateral, getDebtRatio, getPrice, getStabilityFee, txCloseVaultLiquidated, txFinalLiquidation
} from '@/stacks/manager_lbtc';

@Component({
  components: {
    luHeader,
    tx_confirm,
    selectToken2,
    confirm2,
  },
  computed: {
    ...mapGetters([
      'tolerance',
      'isConnected',
    ]),
  },
})

export default class VaultManagePage extends Vue {
  @Prop() vault_id!: any;
  // @Prop() vault_type!: any;
  current_block = '0';

  vault_info:any = {};
  coll_type:any = {};

  coll_token_info = BASE_WSTX_DATA();
  coll_token_balance = 'Loading';
  coll_unit = '';
  current_coll_amt = 'Loading';

  debt_token_info = BASE_LBTC_DATA();
  debt_token_balance = 'Loading';
  total_debt_str = 'Loading';
  debt_unit = 'lBTC';
  avaiable_debt_amt = 'Loading';
  withdraw_balance = '0';

  mint_debt_amt = 0;
  burn_debt_amt = 0;
  deposit_coll_amount = 0;
  withdraw_coll_amount = 0;

  liquidation_penalty = 'Loading';
  liquidation_ratio = 'Loading';
  curr_debt_ratio = 'Loading';
  curr_debt_ratio_from_contract = 'Loading';

  cur_stacked_coll = '0';
  able_stacked_coll = '0';

  deposit_coll_amt_unliquidation = 0;
  burn_debt_amt_unliquidation = 0;

  isLoadDone = false;
  isLiquidation = false;
  isLiquidationFinished = false;
  UnLiquidationDeadline = 0;
  isAble2UnLiquidate = false;
  isStacked = false;
  isDebtZero = false;
  isSTX = true;
  // 추후 업데이트 필요
  coll_price = 1;
  debt_price = 80000;

  async created() {
    this.vault_info = await getVaultInfoFromID(this, this.vault_id);
    this.coll_type = (await getVaultTypeInfo(this, this.vault_info['collateral-type'].value)).value;
    // console.log('ttt', this.vault_id, this.vault_info, this.coll_type, this.vault_info['stacked-tokens'].value)

    this.current_block = (await getBlockHeight()).toString();
    this.isAble2UnLiquidate = false;

    // this.liquidation_penalty = (new BigNumber(this.coll_type['liquidation-penalty'].value).dividedBy(100)).toString();
    this.liquidation_ratio = this.coll_type['liquidation-ratio'].value;

    if(this.vault_info['collateral-token'].value === 'STX') {
      this.coll_token_info = BASE_WSTX_DATA();
      this.isSTX=true;
    }else if (this.vault_info['collateral-token'].value ==='STSW'){
      this.coll_token_info = BASE_STSW_DATA();
      this.isSTX=false;
    }

    this.coll_price = new BigNumber((await getPrice(this, this.vault_info['collateral-token'].value))['last-price'].value).dividedBy(10**6).toNumber();
    this.debt_price = new BigNumber((await getPrice(this, 'lBTC'))['last-price'].value).dividedBy(10**6).toNumber();

    this.coll_unit = this.vault_info['collateral-token'].value;
    this.current_coll_amt = (new BigNumber(this.vault_info.collateral.value).dividedBy(10**this.coll_token_info.decimal)).toString();
    this.debt_token_balance = (new BigNumber(this.vault_info.debt.value).dividedBy(10**this.debt_token_info.decimal)).toString();
    this.total_debt_str = await this.CalTotalDebtAmt();

    this.avaiable_debt_amt = await this.CalMaxDebtAmt();
    this.curr_debt_ratio = this.CalCurrDebtRatio().toString();
    this.curr_debt_ratio_from_contract = await this.getDebtRatio();

    const curr_stacked_coll_amt = (new BigNumber(this.vault_info['stacked-tokens'].value).dividedBy(10**this.coll_token_info.decimal));
    this.cur_stacked_coll = curr_stacked_coll_amt.toString();
    this.able_stacked_coll = new BigNumber(this.current_coll_amt).minus(curr_stacked_coll_amt).toString();

    this.isLiquidation =  this.vault_info['is-liquidated'].value;
    this.isStacked =  !this.vault_info['revoked-stacking'].value;
    this.isLiquidationFinished = this.vault_info['liquidation-finished'].value;
    this.isDebtZero = (new BigNumber(this.vault_info['stacked-tokens'].value).toNumber() === 0.0);
    if(this.isDebtZero){
      this.withdraw_balance = (new BigNumber(this.able_stacked_coll).minus(await this.CalMinCollAmt())).toString();
    }
    if(this.isLiquidation){
      // tslint:disable-next-line:radix
      this.UnLiquidationDeadline = parseInt(this.vault_info['updated-at-block-height'].value) + 2;
      // console.log('UnLiquidationDeadline : ', this.UnLiquidationDeadline, this.vault_info['updated-at-block-height'], this.current_block)
      // tslint:disable-next-line:radix
      if(this.UnLiquidationDeadline > parseInt(this.current_block)){
        this.isAble2UnLiquidate = true;
      }
    }

    getBalance(this, this.coll_token_info).then((value) =>{
        this.coll_token_balance = new BigNumber(this.coll_token_info.balance).dividedBy(10 ** this.coll_token_info.decimal).toString()
        this.isLoadDone = true
    });
  }
  OnClickMaxColl() {
    if(new BigNumber(this.coll_token_balance).toNumber() > 0) {
      this.deposit_coll_amount = new BigNumber(this.coll_token_balance).toNumber();
    }else{
      this.deposit_coll_amount = 0;
    }
  }
  OnClickMaxMintDebt() {
    if(new BigNumber(this.avaiable_debt_amt).toNumber() > 0) {
      this.mint_debt_amt = new BigNumber(new BigNumber(this.avaiable_debt_amt).toFixed(8)).toNumber();
    }else{
      this.mint_debt_amt = 0;
    }
  }
  OnClickMaxBurnDebt() {
    if(new BigNumber(this.total_debt_str).toNumber() > 0) {
      this.burn_debt_amt = new BigNumber(new BigNumber(this.total_debt_str).toFixed(8)).toNumber();
    }else{
      this.burn_debt_amt = 0;
    }
  }
  OnClickMaxWithdrawColl(){
    if(new BigNumber(this.withdraw_balance).toNumber() > 0){
      this.withdraw_coll_amount = new BigNumber(this.withdraw_balance).toNumber();
    }else{
      this.withdraw_coll_amount = 0;
    }
  }
  onlyNumber($event: KeyboardEvent) {
    const keyCode = $event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode === 46) {
        const target: any = $event.target;
        $event.returnValue = target.value.split('.').length > 1 ? false : true;
      } else {
        $event.returnValue = false;
      }
    }
    // console.log($event);
  }


  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }
  async CalTotalDebtAmt(){
    const stability_fee = new BigNumber((await this.get_stability_fee(this.vault_id)).value).dividedBy(10**8).toString();
    return (new BigNumber(this.debt_token_balance).plus(new BigNumber(stability_fee))).toString();
  }
  async CalMaxDebtAmt(){
    const stability_fee = new BigNumber((await this.get_stability_fee(this.vault_id)).value).dividedBy(10**8).toString();

    // console.log(this.current_coll_amt, this.debt_token_balance, this.coll_type)
    const max_debt_amt = (new BigNumber(this.current_coll_amt).multipliedBy(this.coll_price))
        .multipliedBy(new BigNumber(100).dividedBy( new BigNumber(this.coll_type['collateral-to-debt-ratio'].value)))
        .dividedBy(new BigNumber(this.debt_price));
    return max_debt_amt.minus(new BigNumber(this.debt_token_balance).plus(new BigNumber(stability_fee))).toString();
  }

  async CalMinCollAmt(){
    const stability_fee = new BigNumber((await this.get_stability_fee(this.vault_id)).value).dividedBy(10**8).toString();

    return ((new BigNumber(this.debt_token_balance).plus(new BigNumber(stability_fee))).multipliedBy(new BigNumber(this.debt_price))
        .multipliedBy(this.coll_type['collateral-to-debt-ratio'].value)
        .dividedBy(100).dividedBy(this.coll_price));
  }



  CalCurrDebtRatio(){
    return new BigNumber(100).multipliedBy(this.current_coll_amt).multipliedBy(this.coll_price).dividedBy(this.debt_price).dividedBy(this.debt_token_balance);
  }
  async getDebtRatio(){
    const res = await getDebtRatio(this,
        new BigNumber(this.current_coll_amt),
        new BigNumber(this.total_debt_str),
        this.vault_info['collateral-token'].value
    );
    // console.log(res)
    return res.value;
  }
  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.$router.back();
    // this.$router.push({name: 'liquidity'});
  }



  OnClickDeposit(){
    txDepositCollateral(this,
        this.vault_id,
        new BigNumber(this.deposit_coll_amount),
        this.vault_info['collateral-token'].value
    );
  }

  OnClickWithdraw(){
    txWithdrawCollateral(this,
        this.vault_id,
        new BigNumber(this.withdraw_coll_amount),
        this.vault_info['collateral-token'].value
    );
  }

  OnClickMintDebt(){
    txMintDebt(this,
        this.vault_id,
        new BigNumber(this.mint_debt_amt),
        this.vault_info['collateral-token'].value
    );
  }

  OnClickBurnDebt(){
    txBurnDebt(this,
        this.vault_id,
        new BigNumber(this.burn_debt_amt),
        this.vault_info['collateral-token'].value
    );
  }

  OnClickDepositUnLiquidation(){
    txUnliquidationDeposit(this,
        this.vault_id,
        new BigNumber(this.deposit_coll_amt_unliquidation),
        this.vault_info['collateral-token'].value
    );
  }

  OnClickBurnDebtUnliquidation(){
    txUnliquidationBurn(this,
        this.vault_id,
        new BigNumber(this.burn_debt_amt_unliquidation),
        this.vault_info['collateral-token'].value
    );
  }
  OnClickCloseVault_Warning(){
    alert('If you want to close the vault, \n stop stacking collateral tokens and try again.')
  }
  OnClickCloseVault(){
    txCloseVault(this,
        this.vault_id,
        this.vault_info['collateral-token'].value,
        this.vault_info.collateral.value,
        this.total_debt_str
    )
  }
  OnClickCloseLiquidatedVault(){
    txFinalLiquidation(this,
        this.vault_id
    )
  }
  OnToggleStake(){
    txToggleStacking(this,
        this.vault_id
    )
  }

  OnStakeCollateral(){
    txStackCollateral(this,
        this.vault_id
    )
  }

  OnEnableWithdraw(){
    txEnableWithdraw(this,
        this.vault_id,
        this.vault_info['stacker-name'].value
    )
  }
  async get_stability_fee(vault_id:string) {
    return (await getStabilityFee(this,
        vault_id,
    ));
  }
}
