import manage from './manage.page.vue';

export const manageRouter = [
    {
        path: 'manage',
        name: 'manage',
        component: manage,
        props: true,
    },
];
