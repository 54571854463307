
import {Component, Prop, Vue} from 'vue-property-decorator';
import {numFormat_, swapContractCall, swapContractCall2} from '@/stacks/manager';
import { MODE} from '@/stacks/config';
import BigNumber from 'bignumber.js';

@Component
export default class ConfirmModalWindow extends Vue{
  @Prop({default: false}) value!: boolean;
  @Prop() token_x!: {icon: string, name: string, [key: string]: any};
  @Prop() token_y!: {icon: string, name: string, [key: string]: any};
  @Prop() x_to_y!: boolean;
  @Prop() pair!: any;
  @Prop({default: 'NULL'}) title!: string;

  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  tx_id : string = '';
  click_id: number = 0;

  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }
  onWalletCancel(){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }
  OnClickBack(event:any){
    // console.log("Back : ", event.target.id)
    if(event.target.id === 'background') {
      this.onClickCancel(event);
    }
  }
  onClickCancel(event:any){
    // console.log("cancel btn")
    this.value = false;
    this.tx_sent = false;
    this.clicked_confirm = false;
    this.$emit('txClosed', true);
    event.stopPropagation();
  }

  // 컨펌이 끝났을때 스왑하는 함수 입니다.
  swap(event:any) {
    this.clicked_confirm = true;
    // swapContractCall(this);
    swapContractCall2(this);
    event.stopPropagation();
  }
  viewTX(event:any) {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
    event.stopPropagation();
  }
  price_impact() {
    // console.log('price impact');
    //  (dy (/ (* u997 balance-y dx) (+ (* u1000 balance-x) (* u997 dx)))) ;; overall fee is 30 bp, either all for the pool, or 25 bp for pool and 5 bp for operator
    // dy = balancey * dx / balancex + dx
    try {
      const balance_x = this.x_to_y ? this.pair['balance-x'].value : this.pair['balance-y'].value;
      // console.log('balance_x',balance_x, typeof(balance_x));

      const balance_y = this.x_to_y ? this.pair['balance-y'].value : this.pair['balance-x'].value;
      // console.log('balance_y',balance_y);
      const dx = new BigNumber(this.token_x.amount).multipliedBy(10 ** this.token_x.decimal);
      // console.log('dx',dx.toString(), typeof(dx));
      if (dx.toNumber() > new BigNumber(balance_x).toNumber()) {
        // console.log('dx is bigger than dy');
        return 100;
      }
      const dy_1 = new BigNumber(balance_y).multipliedBy(dx);
      // console.log('dy_1', dy_1.toString());
      const dy_2 = new BigNumber(balance_x).plus(dx);
      // console.log('dy_2', dy_2.toString());
      const dy = (dy_1.toNumber())/(dy_2.toNumber());
      // console.log('dy',dy.toString());
      const input_price =  new BigNumber(10**10).multipliedBy(new BigNumber(balance_x)).dividedBy(new BigNumber(balance_y));
      // console.log('input_price',input_price.toString());
      const output_price = new BigNumber(10**10).multipliedBy(new BigNumber(balance_x)).plus(dx).dividedBy(new BigNumber(balance_y).minus(dy));
      // console.log('output_price',output_price.toString());
      const price_impact2 = output_price.minus(input_price).multipliedBy(10000).dividedBy(input_price);
      // console.log('price_impact2',price_impact2.toString());
      let price_impact = price_impact2.toNumber();
      if (price_impact >= 10000) {price_impact = 9999;  }
      // console.log('price impact : ', (price_impact/100))
      return (price_impact/100).toFixed(5);
    } catch (e) {
      // console.log(e);
      return 0;
    }
  }
  price_impact2() {
    // console.log('price impact');
    //  (dy (/ (* u997 balance-y dx) (+ (* u1000 balance-x) (* u997 dx)))) ;; overall fee is 30 bp, either all for the pool, or 25 bp for pool and 5 bp for operator
    // dy = balancey * dx / balancex + dx
    try {
      const balance_x = this.x_to_y ? this.pair['balance-x'] : this.pair['balance-y'];
      // console.log('balance_x',balance_x, typeof(balance_x));

      const balance_y = this.x_to_y ? this.pair['balance-y'] : this.pair['balance-x'];
      // console.log('balance_y',balance_y);
      const dx = new BigNumber(this.token_x.amount).multipliedBy(10 ** this.token_x.decimal);
      // console.log('dx',dx.toString(), typeof(dx));
      if (dx.toNumber() > new BigNumber(balance_x).toNumber()) {
        // console.log('dx is bigger than dy');
        return 100;
      }
      const dy_1 = new BigNumber(balance_y).multipliedBy(dx);
      // console.log('dy_1', dy_1.toString());
      const dy_2 = new BigNumber(balance_x).plus(dx);
      // console.log('dy_2', dy_2.toString());
      const dy = (dy_1.toNumber())/(dy_2.toNumber());
      // console.log('dy',dy.toString());
      const input_price =  new BigNumber(10**10).multipliedBy(new BigNumber(balance_x)).dividedBy(new BigNumber(balance_y));
      // console.log('input_price',input_price.toString());
      const output_price = new BigNumber(10**10).multipliedBy(new BigNumber(balance_x)).plus(dx).dividedBy(new BigNumber(balance_y).minus(dy));
      // console.log('output_price',output_price.toString());
      const price_impact2 = output_price.minus(input_price).multipliedBy(10000).dividedBy(input_price);
      // console.log('price_impact2',price_impact2.toString());
      let price_impact = price_impact2.toNumber();
      if (price_impact >= 10000) {price_impact = 9999;  }
      // console.log('price impact : ', (price_impact/100))
      return (price_impact/100).toFixed(5);
    } catch (e) {
      // console.log(e);
      return 0;
    }
  }
}
