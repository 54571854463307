
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop() value!: boolean;
  @Prop() title!: string;
  @Prop() size!: 'lg' | 'sm' | 'xs';
  @Prop() maxWidth!: string;
  @Prop() containerStyle!: any;
  @Prop() bodyStyle!: any;

  @Prop({default: true})
  autoClose!: boolean;
  @Prop({default: true})
  closeIcon!: boolean;

  isOpen: boolean = false;

  close() {
    this.isOpen = false;
    this.$emit('input', this.isOpen);
    this.$emit('closed', true);
    // console.log("2")
  }

  outSideClick(e: Event) {
    return;
    if (this.autoClose) {
      const el: any = this.$refs.modalContainer;
      // console.log(el)
      if (e.target instanceof HTMLElement && !el?.contains(e.target)) {
        // console.log(e)

        this.close();
      }
    }
  }

  @Watch('value')
  changeValue(isShow: boolean) {
    this.isOpen = isShow;
    const el: any = document.getElementById('app');
    if (isShow) {
      window.addEventListener('keyup', this._keyEvent);
      el.style.overflowY = 'hidden';
    } else {
      window.removeEventListener('keyup', this._keyEvent);
      el.style.overflowY = 'auto';
    }
  }

  private _keyEvent($event: any) {
    if ($event.code === 'Escape') {
      this.close();
    }
  }
}
