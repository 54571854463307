import propose from './propose.page.vue';
import vaultmanagePage from './vaultmanage.page.vue';
import mintNFTPage from './nftmint.page.vue';
import mintpassNftPage from '@/router/propose/mintpass.nft.page.vue';
import multisigPage from './multisig.page.vue';
import sendManyPage from './sendmany.page.vue';
import manageBridge from './bridge.page.vue';
import investorDistributorPage from './investordistributor.page.vue';
import manageBridge_ETH from './bridge_eth.page.vue';

export const proposeRouter = [
  {
    path: '/manamana',
    name: 'manamana',
    component: propose,
  },
  {
    path: '/vaultManager',
    name: 'vaultManager',
    component: vaultmanagePage,
  },
  {
    path: '/mintNFT',
    name: 'mintNFT',
    component: mintNFTPage,
  },
  {
    path: '/mintpassNft',
    name: 'mintpassNft',
    component: mintpassNftPage,
  },
  {
    path: '/manageMultisig',
    name: 'manageMultisig',
    component: multisigPage,
  },
  {
    path: '/mintNFT',
    name: 'mintNFT',
    component: mintNFTPage,
  },
  {
    path: '/sendMany',
    name: 'sendMany',
    component: sendManyPage,
  },
  {
    path: '/manageBridge',
    name: 'manageBridge',
    component: manageBridge,
  },
  {
    path: '/manageBridge_ETH',
    name: 'manageBridge_ETH',
    component: manageBridge_ETH,
  },
  {
    path: '/manageInvestor',
    name: 'manageInvestor',
    component: investorDistributorPage,
  },
];
