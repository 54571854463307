import { StoreOptions } from 'vuex';

// @ts-ignore
// @ts-ignore
// @ts-ignore
export const settingStore: StoreOptions<any> = {
  state: {
    toggleSettingModal: false,
    tolerance: 5.0,
    pairs: [],
    poolPairs: {block: 0, pools: {}},
    investedPoolPairs: {block: 0, data: {}},
    farmPairs: [],
    farm2Pairs: [],
    stx_stsw : 0,
    usd_stx : 0,
  },
  actions: {
    // setting model open
    openSetting({commit}) {
      commit('setToggleSettingModal', true);
    },
    // setting model close
    closeSetting({commit}) {
      commit('setToggleSettingModal', false);
    },
    // setting 정보를 넣는 부분 입니다.
    setTolerance({commit}, tolerance) {
      commit('setTolerance', tolerance);
    },
    setInvestedPairs({commit}, pairs) {
      commit('setInvestedPairs', pairs);
    },
    setFarmingPairs({commit}, farmPairs) {
      commit('setFarmingPairs', farmPairs);
    },
    setFarming2Pairs({commit}, farmPairs) {
      commit('setFarming2Pairs', farmPairs);
    },
    setSTX_STSW({commit}, stx_stsw) {
      commit('setSTX_STSW', stx_stsw);
    },
    setUSD_STX({commit}, usd_stx) {
      commit('setUSD_STX', usd_stx);
    },
  },
  getters: {
    tolerance: (state) => state.tolerance,
    // 현재 setting modal이 open인지 close 리턴 합니다.
    toggleSettingModal: (state) => state.toggleSettingModal,
    investedPair: (state) => state.pairs,
    farmingPair: (state) => state.farmPairs,
    farming2Pair: (state) => state.farm2Pairs,
    stx_stsw: (state) => state.stx_stsw,
    usd_stx: (state) => state.usd_stx,
  },
  mutations: {
    setToggleSettingModal(state, isOpen) {
      state.toggleSettingModal = isOpen;
    },
    setTolerance(state, tolerance) {
      state.tolerance = tolerance;
    },
    setInvestedPairs(state, pairs) {
      state.pairs = pairs;
    },
    setFarmingPairs(state, farmPairs) {
      state.farmPairs = farmPairs;
    },
    setFarming2Pairs(state, farm2Pairs) {
      state.farm2Pairs = farm2Pairs;
    },
    setSTX_STSW(state, stx_stsw) {
      state.stx_stsw = stx_stsw;
    },
    setUSD_STX(state, usd_stx) {
      state.usd_stx = usd_stx;
    },
    // pairs
    setPoolPairs(state, pairs) {
      if (state.poolPairs.block !== pairs.block) {
        state.poolPairs.block = pairs.block;
        state.poolPairs.pools = {};
      }
      state.poolPairs.pools = pairs.pools;
    },
    // invested_pairs = { block: "block number:number",
    //                    addr: "user wallet address :string",
    //                    data: {"pair address:string": {'num_lp':"# of lp", 'num_x': "# of x token invested: BigNumber", 'num_y':"# of x token invested: BigNumber", 'ratio':"ratio of target lp pool:float"}}
    setUserInvestedPoolPairs(state, invested_pairs) {
      if (state.investedPoolPairs.block !== invested_pairs.block) {
        state.investedPoolPairs.block = invested_pairs.block;
        state.investedPoolPairs.data = {};
      }
      state.investedPoolPairs.data[invested_pairs.addr] = invested_pairs.data;
    },
  },
};
