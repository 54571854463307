
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  claimMiningReward,
  claimStackingReward,
  getMinedBlock,
  minePOXLToken,
  stackPOXLToken,
  getBalance,
  getCurrentStackingRound,
  isInWhitelist, getBlockHeight,
} from '@/stacks/manager';
import selectToken from '@/router/poxlmining/components/select-token.component.vue';
import selectToken2 from '@/router/poxlmining/components/select-token.modal.vue';

import {IS_TESTNET, MIAMI_DATA} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';

@Component({
  components: {
    selectToken,
    luHeader,
    selectToken2,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class PoxlPage extends Vue {
  // 토큰을 선택하는 모달의 toggle 입니다
  toggleSelectToken: boolean = false;

  token: any = MIAMI_DATA();

  mine_amount: number = 1;

  stack_amount: number = 100;
  stack_start_blocknum: number = 0;
  stack_lock_period: number = 1;

  mined_blocknum: number = 0;

  find_more: boolean = false;
  offset_total: number = 40;

  target_reward_cycle: number = 0;
  mined_offset: number = 0;

  current_stacking_round: number = 0;
  is_city_coin: boolean = true;

  is_clicked_list: boolean[] = [false, false];
  OnClickPool(id: number) {
    this.is_clicked_list[id] = !this.is_clicked_list[id];
    this.$forceUpdate();
  }
  getIsClick(id: number) {
    return this.is_clicked_list[id];
  }
  OnConfirmClosed() {
    this.toggleSelectToken = false;
  }

  created() {
    getCurrentStackingRound(this, this.token.addr).then((value) => this.current_stacking_round = value);
    getBlockHeight().then((value) => this.stack_start_blocknum = value);
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    // isInWhitelist(this.$store.getters.user.mainnetAddress).then((value) => {
    //   if (!value && !IS_TESTNET()) {window.location.href = 'https://testnet.stackswap.org'; }
    // });
    getCurrentStackingRound(this, this.token.addr).then((value) => this.current_stacking_round = value);
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  minePOXLVue() {
    // getRandomInt(this, this.contract_address, this.mine_amount);
    // canClaimCall(this, this.contract_address, this.mine_amount);
    minePOXLToken(this);
    // removePoxl(this, this.contract_address)
  }

  stackPOXLVue() {
    stackPOXLToken(this);
  }

  claimMiningRewardVue() {
    claimMiningReward(this);
  }

  claimStackingRewardVue() {
    claimStackingReward(this);
  }

  getMinedBlocks() {
    getMinedBlock(this, this.token.addr, this.mined_offset).then((value) => {
      if ( value.is_end) {
        alert( 'no more block to search');
      } else {
        this.mined_offset = this.mined_offset + 1;
        alert( value.mined_block);
      }
    });
  }

  // 토큰을 선택하는 모달읠 오픈 합니다.
  openSelectToken() {
    this.toggleSelectToken = true;
  }

  // 토큰을 선택 했을시 아래의 함수를 호출 합니다.
  selectedToken(token: any) {

    this.token = token;
    this.is_city_coin = this.token.addr === MIAMI_DATA().addr;
    getCurrentStackingRound(this, this.token.addr).then((value) => this.current_stacking_round = value);
    if (this.$store.getters.isConnected) {
      getBalance(this, this.token);
      // this.token_x_Number = this.token_x.balance
    }
  }
}
