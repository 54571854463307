
import luHeader from '@/layout/header.component.vue';
import { Component, Vue } from 'vue-property-decorator';
import {IS_TESTNET, MODE} from '@/stacks/config';
import {
  claimUserBeta01Reward,
  getBalance,
  getBlockHeight,
  getUserBeta01Data,
  isInWhitelist,
  numFormat_,
} from '@/stacks/manager';
import {mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,

  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})

export default class BetaEventPage extends Vue {

  current_block: string = 'Loading';

  num_reward: number = 0;
  reward: string = 'Loading';
  event1_target_block_number: number = 44744;
  event2_target_block_number: number = 97304;
  event3_target_block_number: number = 149864;

  //
  // event1_target_block_number: number = 44460;
  // event2_target_block_number: number = 44550;
  // event3_target_block_number: number = 44650;

  is_event1_claimed: boolean = false;
  is_event2_claimed: boolean = false;
  is_event3_claimed: boolean = false;

  clicked_confirm: boolean = false;
  tx_sent: boolean = false;
  tx_id: string = '';

  async created() {
    getBlockHeight().then((value) => {
      this.current_block = value.toString();
    });
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }

    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    this.getUserData().then((value) => {
      // console.log(value);
      this.is_event1_claimed = value.event1_1_claimed.value;
      this.is_event2_claimed = value.event1_2_claimed.value;
      this.is_event3_claimed = value.event1_3_claimed.value;
      this.num_reward = new BigNumber(value.stsw_reward.value).dividedBy(10 ** 6).toNumber();
      this.reward = value.stsw_reward.value;
    });

  }

  async getUserData() {
    const data = await getUserBeta01Data(this, 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-beta-event-01-v1');
    return data;
  }

  onWalletCancel() {
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  onWalletSuccess(data: any) {
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }
  viewTX() {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`, '_blank');
  }

  numFormat(input: any, decimal: number = 3) {
    return numFormat_(input, decimal);
  }

  onClickClaimReward() {
    if ( this.reward === 'Loading' ) {
      alert('Loading...');
      return false;
    }
    let tempReward = new BigNumber(0);
    // console.log(Number(this.current_block), this.event1_target_block_number)
    if ( Number(this.current_block) > this.event1_target_block_number && !this.is_event1_claimed) {
      tempReward = tempReward.plus(this.reward);
      // console.log('1');
    }
    if ( Number(this.current_block) > this.event2_target_block_number && !this.is_event2_claimed) {
      tempReward = tempReward.plus(this.reward);
      // console.log('2');
    }
    if ( Number(this.current_block) > this.event3_target_block_number && !this.is_event3_claimed) {
      tempReward = tempReward.plus(this.reward);
      // console.log('3');
    }
    // console.log(tempReward.toString());
    if ( tempReward.toString() === '0' ) {
      alert('No claimable rewards');
      return false;
    }
    this.clicked_confirm = true;
    claimUserBeta01Reward(this, 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stackswap-beta-event-01-v1' , tempReward.toString());

  }


  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }
}
