
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  txVote
} from '@/stacks/manager';
import {MODE} from '@/stacks/config';

@Component
export default class VoteConfirmModalWindow extends Vue {
  @Prop() value!: boolean;

  OnClickBack(event:any){
    if(event.target.id === 'background') {
      this.OnclickCancel(event);
    }
  }

  OnclickCancel(event:any) {
    this.value = false;
    this.$emit('txClosed', true);
    event.stopPropagation();

  }

  tx_success(success: boolean) {
    this.$emit('tx_success', success);
  }

  @Prop({default: false}) IsVoteMenuShow!: boolean;
  @Prop({default: '0'}) vote_balance!: string;
  @Prop({default: 0}) proposal_id!:number;

  radioValues:string='NONE';
  invest_amount:number = 0;

  // popup-var
  popup_show:boolean=false;
  popup_title:string='';
  popup_contents:string='None-Contents';

  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  tx_id : string = '';
  click_id: number = 0;

  viewTX(event:any) {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
    event.stopPropagation();
  }
  onWalletCancel(event:any){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
    this.OnclickCancel(event);
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
    this.OnclickCancel(data);
  }

  created() {
    this.radioValues='NONE';
    this.invest_amount = 0;
    this.popup_show=false;
    this.popup_title='';
    this.popup_contents='None-Contents';
    this.clicked_confirm = false;
    this.tx_sent  = false;
    this.tx_id = '';
    this.click_id = 0;
  }
  onClickStaking() {
    this.IsVoteMenuShow = false;
    this.$emit('popupClosed', true);
  }

  onClickMax(){
    this.invest_amount = parseFloat(this.vote_balance);
  }

  onClickVote(event:any){
    // alert(`${this.radioValues} ${this.invest_amount} ${this.vote_balance}`)
    if(this.radioValues==='SELECT VOTE MODE' || this.radioValues==='NONE'){
      alert('Please select vote mode')
      // this.openPopup("Please select vote mode")
    }
    else if(this.invest_amount <= 0){
      alert('The number of votes should be bigger than 0')
      // this.openPopup("The number of votes should be bigger than 0")

    }
    else if(parseFloat(this.vote_balance) < this.invest_amount){
      alert(`The number of votes should be smaller than ${this.vote_balance}`)
      // this.openPopup(`The number of votes should be smaller than ${this.vote_balance}`)

    }else{
      this.clicked_confirm = true;
      txVote(this,this.proposal_id, this.radioValues,this.invest_amount)
    }
    event.stopPropagation();
  }

  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    // console.log('confirm close2')

    this.popup_show = false;
  }
  // ==== PopupSetting =====//

  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.IsVoteMenuShow = false;
    this.$emit('voteClosed', true);
  }
  // setting을 오픈합니다.
  openSetting() {
    this.$store.dispatch('openSetting');
  }
}
