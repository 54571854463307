import stswDeposit from './stswDeposit.vue';
import distributorClaim from './distributorClaim.vue';
import investorClaim from './investorClaim.vue';

export const stswDepositRouter = [
    {
        path: '/stsw-deposit',
        name: 'stsw-deposit',
        component: stswDeposit,
    },
    {
        path: '/claimDistributor',
        name: 'claimDistributor',
        component: distributorClaim,
    },
    {
        path: '/investorDistributor',
        name: 'investorDistributor',
        component: investorClaim,
    },
];
