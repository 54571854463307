
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getLiquidityList,
  getSoftTokenList,
  getPOXLTokenList,
  mintSIPContractCall,
  mintPOXLContractCall,
  getBlockHeight,
  isInWhitelist,
  propose,
  send_tokens,
  collectSwapFees,
  add_liquidity_dist,
  invest_to_farm_dist,
  STSWAwdDistributor,
  farmClaimDistributor,
  STSWStaking,
  tx_Distibutor0001_WithdrawLP,
  SendToken,
  invest_to_farm_dist0002, tx_Distibutor0002_WithdrawLP, farmClaimDistributor0003, STSWAwdDistributor0003, txDistibutor0003_unstake,
} from '@/stacks/manager';

import luHeader from '@/layout/header.component.vue';
import {
  BASE_STSW_DATA,
  CONTRACT_NAME_STACKSWAP_FARMING,
  CONTRACT_NAME_STACKSWAP_FARMING2, CONTRACT_NAME_STACKSWAP_FARMING2_1, CONTRACT_NAME_STACKSWAP_FARMING2_5,
  CONTRACT_NAME_STACKSWAP_STACKING,
  IS_TESTNET, STACKSWAP_ADDRESS,
} from '@/stacks/config';
import {contractPrincipalCV, standardPrincipalCV, stringAsciiCV, tupleCV} from '@stacks/transactions';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class LaunchpadPage extends Vue {

  is_poxl: boolean = false;
  selected: string = '';
  contract_name: string = '';
  contract_address: string = '';

  token_name: string = '';
  token_symbol: string = '';
  token_decimal: number = 6;
  token_uri: string = '';
  token_website: string = '';

  stsw_amount_dist: string = '';
  stsw_amount_dist_back: string = '';
  lp_amount_dist_back: string = '';

  migration_lp_amt: string = '';
  migration_stsw_amt: string = '';
  invest_round: string = '12';


  stsw_amount_stake: string = '';
  stx_amount_add: string = '';
  stsw_amount_add: string = '';

  farm_round: string = '0';
  farmv2_round: string = '0';

  unstake_round: string = '0';

  initial_amount: number = 100;
  initial_amount_x: number = 10;
  initial_amount_y: number = 10;

  gov_start_block: string = '';
  gov_url: string = 'https://gist.github.com/stackswap/f1bb19f6aa6d3585976c1ec0bee56c50';
  gov_title: string = 'STSW and LBTC staking update';

  first_stacking_block: number = 0;
  reward_cycle_block: number = 300;
  token_reward_maturity: number = 100;
  coinbase_reward: number = 0.1;

  liquidity_token: string = '';
  soft_token: string = '';
  poxl_token: string = '';

  created() {
    getBlockHeight().then((value) => this.first_stacking_block = value);
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    getLiquidityList(this).then((value) => this.liquidity_token = value);
    getPOXLTokenList(this).then((value) => this.poxl_token = value);
    getSoftTokenList(this).then((value) => this.soft_token = value);
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    isInWhitelist(this.$store.getters.user.mainnetAddress).then((value) => {
      if (!value && !IS_TESTNET()) {window.location.href = 'https://testnet.stackswap.org'; }
    });
    getLiquidityList(this).then((value) => this.liquidity_token = value);
    getPOXLTokenList(this).then((value) => this.poxl_token = value);
    getSoftTokenList(this).then((value) => this.soft_token = value);
  }

  selectToken() {
    if (this.selected === 'poxl') {
      this.is_poxl = true;
    } else {
      this.is_poxl = false;
    }
    // console.log("is in select Token")
    // console.log(this.selected)
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  proposeToGovernance() {
    // (list {name: "governance", address: 'ST23DAB333A5CPFXNK13E5YMX1DZJ07112QA7HWHA, qualified-name: 'ST23DAB333A5CPFXNK13E5YMX1DZJ07112QA7HWHA.stackswap-governance-v5gb})
    const temp = [
      tupleCV({"name": stringAsciiCV('l-lbtc-revoker'), "address": standardPrincipalCV('SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275'),
        'qualified-name': contractPrincipalCV('SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275', 'stackswap-lbtc-staking-data-v3a')}),
    ];

    propose(this, this.gov_start_block, this.gov_title, this.gov_url, temp);
  }

  send_token_distributor() {
    send_tokens(this, 'distributor0003', new BigNumber(this.stsw_amount_dist).multipliedBy(10 ** 6).toFixed().toString());
  }

  send_token_distributor_back() {
    STSWAwdDistributor(this, new BigNumber(this.stsw_amount_dist_back).multipliedBy(10 ** 6).toFixed().toString());
  }

  claim_farming_rward_dist() {
    farmClaimDistributor(this, this.farm_round);
  }

  stake_STSW() {
    STSWStaking(this, new BigNumber(this.stsw_amount_stake).multipliedBy(10 ** 6).toFixed().toString());
  }


  send_token_staking() {
    send_tokens(this, CONTRACT_NAME_STACKSWAP_STACKING(), '1233792000000');
  }

  send_token_farming() {
    send_tokens(this, CONTRACT_NAME_STACKSWAP_FARMING(), '671731200000');
    // send_tokens(this, CONTRACT_NAME_STACKSWAP_FARMING2(), '13434624000000');
  }

  send_token_farming2() {
    // send_tokens(this, CONTRACT_NAME_STACKSWAP_FARMING(), '1343462400000');
    send_tokens(this, CONTRACT_NAME_STACKSWAP_FARMING2_5(), '13434624000000');
  }

  collect_fees() {
    collectSwapFees(this, 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.wstx-token-v4a', 'SP2C2YFP12AJZB4MABJBAJ55XECVS7E4PMMZ89YZR.usda-token', 'SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.liquidity-token-v5jnzgp1c6b');
  }

  add_liquidity() {
    add_liquidity_dist(this, 'distributor0002', new BigNumber(this.stx_amount_add).multipliedBy(10 ** 6).toFixed().toString(),  new BigNumber(this.stsw_amount_add).multipliedBy(10 ** 6).toFixed().toString());
  }

  invest_to_farm() {
    invest_to_farm_dist(this, 'distributor0002');
  }

  txDistibutor0001_WithdrawLP() {
    tx_Distibutor0001_WithdrawLP(this, 'distributor0002', 'liquidity-token-stx-stsw');
  }

  txDistibutor0002_Migration_LP() {
    SendToken(this, 'distributor0003',
        STACKSWAP_ADDRESS() + '.liquidity-token-stx-stsw',
        new BigNumber(this.migration_lp_amt).multipliedBy(10 ** 6).toFixed());
  }

  txDistibutor0002_Migration_STSW() {
    SendToken(this, 'distributor0003',
        BASE_STSW_DATA().addr,
        new BigNumber(this.migration_stsw_amt).multipliedBy(10 ** 6).toFixed());
  }

  txDistibutor0002_Invest() {
    invest_to_farm_dist0002(this, 'distributor0003', new BigNumber(this.invest_round).toNumber());
  }

  txDistibutor0002_ClaimRound() {
    farmClaimDistributor0003(this, this.farmv2_round);
  }

  txDistibutor0002_Withdraw_STSW() {
    STSWAwdDistributor0003(this, new BigNumber(this.stsw_amount_dist_back).multipliedBy(10 ** 6).toFixed().toString());
  }

  txDistibutor0002_Withdraw_LP() {
    tx_Distibutor0002_WithdrawLP(this, 'distributor0003', 'liquidity-token-stx-stsw', '2868922707430');
  }
  txDistibutor0003_unstake() {
    txDistibutor0003_unstake(this, 'distributor0003', 'liquidity-token-stx-stsw', this.unstake_round);
  }
}
