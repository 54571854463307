
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  changeRiskParams,
} from '@/stacks/manager';

import luHeader from '@/layout/header.component.vue';
import {CONTRACT_NAME_STACKSWAP_FARMING, CONTRACT_NAME_STACKSWAP_STACKING, IS_TESTNET} from '@/stacks/config';
import {contractPrincipalCV, standardPrincipalCV, stringAsciiCV, tupleCV, uintCV} from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import {mintNFT, mintNFT2} from '@/stacks/nft/nft.manager';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class NFTmintPage extends Vue {

  col_type_key: string = '';

  col_type_ll: string = '';

  col_type_ctdr: string = '';

  col_type_md: string = '';

  col_type_sf: string = '';

  col_type_sfa: string = '';

  col_type_sfd: string = '';


  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    // console.log('test')
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  setUp() {
    mintNFT2(this);
  }

}
