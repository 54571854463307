import create from './create.page.vue';

export const createRouter = [
    {
        path: 'create',
        name: 'create',
        component: create,
        props: true,
    },
];
