import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { settingStore } from '@/store/modules/setting.store';
import { stakingStore } from '@/store/modules/staking.store';
import { userStore } from '@/store/modules/user.store';
import { vaultStore} from '@/store/modules/vault.store';
import { nftStore} from '@/store/modules/nft.store';

const modules: any = {
  settingStore,
  stakingStore,
  userStore,
  vaultStore,
  nftStore,
};

const defaultState = () => {
  const keys = Object.keys({...modules});
  const baseState: any = {};
  for (const key of keys) {
    baseState[key] = {...modules[key].state};
  }

  return baseState;
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  mutations: {
    resetState(state) {
      // @ts-ignore
      Object.assign(state, defaultState());
    },
  },
  plugins: [
      createPersistedState({
        paths: ['userStore', 'vaultStore', 'nftStore'],
      }),
  ],
});
