
import {Component, Prop, Vue} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {getPairData, getBalance, isInWhitelist, numWithComma, numFormat_, getBlockHeight} from '@/stacks/manager';
import {BASE_WSTX_DATA, BASE_STSW_DATA, STACKS_BACKEND_PARAM, STACKS_BACKEND_URL, MODE} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
import {getPriceAndDY} from '@/stacks/token.tool';
import tx_confirm from '@/components/modal/tx.modal.vue';
import selectToken2 from '@/router/swap/components/select-token.modal.vue';
import confirm2 from '@/router/swap/components/confirm.modal.vue';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import {txCreateVaultWithSTX, txCreateVaultWithSTSW, getDebtRatio, getPrice} from '@/stacks/manager_lbtc';

@Component({
  components: {
    luHeader,
    tx_confirm,
    selectToken2,
    confirm2,
  },
  computed: {
    ...mapGetters([
      'tolerance',
      'isConnected',
    ]),
  },
})

export default class VaultCreatePage extends Vue {
  @Prop() coll_type!: any;
  @Prop() debt_ratio!: any;
  @Prop() liquidation_ratio!: any;
  // @Prop() liquidation_penalty!: any;
  @Prop() coll_token!: any;
  @Prop() coll_address!: any;
  @Prop() max_debt!: any;
  @Prop() total_debt!: any;
  @Prop() stability_fee_apy!: any;

  input_token_amount:string = '0';
  output_token_amount:string = '0';
  max_output_token_amount:string = '0';

  coll_token_info: { icon: string, name: string, [key: string]: any} | any = BASE_WSTX_DATA();
  coll_token_balance = 'Loading';

  coll_token_price = 0;
  debt_token_price = 0;

  coll_token_price_str = 'Loading';
  debt_token_price_str = 'Loading';

  curr_debt_ratio = 'Loading';
  checkedStacking:boolean= false;

  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  tx_id : string = '';
  click_id: number = 0;

  isLoadDone = false;
  viewTX(event:any) {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
    event.stopPropagation();
  }
  onWalletCancel(){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }

  async created() {
    if (this.$store.getters.isConnected) {

      if(this.coll_token === 'STX') {
        this.coll_token_info = BASE_WSTX_DATA();
        this.checkedStacking = true;
      }else if (this.coll_token ==='STSW'){
        this.coll_token_info = BASE_STSW_DATA();
      }

      await getBalance(this, this.coll_token_info);
      this.coll_token_balance = new BigNumber(this.coll_token_info.balance).dividedBy(10 ** this.coll_token_info.decimal).toString();
      // console.log('balance : ', this.coll_token_balance)

      const block_num = await getBlockHeight();
      const price_data = this.$store.getters.price_data;
      // console.log('price : ', price_data.data, price_data.data[this.coll_token])
      if ( price_data.block === block_num && price_data.data[this.coll_token] !== undefined && price_data.data.lBTC !== undefined) {
        this.coll_token_price = price_data.data[this.coll_token];
        this.debt_token_price = price_data.data.lBTC;
      } else {
        this.coll_token_price = new BigNumber((await getPrice(this, this.coll_token))['last-price'].value).dividedBy(10**6).toNumber();
        this.debt_token_price = new BigNumber((await getPrice(this, 'lBTC'))['last-price'].value).dividedBy(10**6).toNumber();
        // console.log('price', this.coll_token_price, this.debt_token_price)
        price_data[this.coll_token] = this.coll_token_price;
        price_data.lBTC = this.debt_token_price;
        await this.$store.dispatch('setPriceData', {block: block_num, data : price_data});
      }
      this.OnChangeInputAmount();
      this.coll_token_price_str = this.coll_token_price.toString();
      this.debt_token_price_str = this.debt_token_price.toString();
      this.isLoadDone = true;
    }
  }

  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }

  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.$router.back();
    // this.$router.push({name: 'liquidity'});
  }

  onlyNumber($event: KeyboardEvent) {
    const keyCode = $event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode === 46) {
        const target: any = $event.target;
        $event.returnValue = target.value.split('.').length > 1 ? false : true;
      } else {
        $event.returnValue = false;
      }
    }
    // console.log($event);
  }

  OnChangeDebtAmount(){

    getDebtRatio(this,
        new BigNumber(this.input_token_amount),
        new BigNumber(this.output_token_amount),
        this.coll_token
    ).then((value) => {
      this.curr_debt_ratio = this.numFormat(value.value);
    })
    // await this.getDebtRatio();
    // this.curr_debt_ratio = (new BigNumber(this.input_token_amount).multipliedBy(this.coll_token_price).multipliedBy(100))
    //     .dividedBy(new BigNumber(this.output_token_amount).multipliedBy(this.debt_token_price)).toString();
    // this.curr_debt_ratio = this.numFormat(this.curr_debt_ratio);
  }

  OnChangeInputAmount(){
    // console.log('OnChangeInputAmount : ', this.input_token_amount)
    // tslint:disable-next-line:radix
    this.max_output_token_amount = (new BigNumber(this.input_token_amount).multipliedBy(this.coll_token_price)).multipliedBy(100/parseInt(this.liquidation_ratio)).dividedBy(this.debt_token_price).toString();
    this.max_output_token_amount = this.numFormat(this.max_output_token_amount,8);
  }

  OnClickCollMax(){
    this.input_token_amount = this.coll_token_balance;
    this.OnChangeInputAmount();
  }

  OnClickDebtMax(){
    if(this.input_token_amount === '0'){
      this.output_token_amount = '0';
    }else{
      this.output_token_amount = (new BigNumber(this.input_token_amount)
          .multipliedBy(this.coll_token_price))
          // tslint:disable-next-line:radix
          .multipliedBy(100/parseInt(this.debt_ratio))
          .dividedBy(this.debt_token_price)
          // .multipliedBy(10**8)
          .toString();
      this.output_token_amount = this.numFormat(this.output_token_amount, 8);

      this.curr_debt_ratio = this.debt_ratio;
    }
  }

  OnClickCreateVault(event:any){
    this.clicked_confirm = true;
    // alert(this.checkedStacking)
    if(this.coll_token === 'STX'){
      txCreateVaultWithSTX(this,
          new BigNumber(this.input_token_amount),
          new BigNumber(this.output_token_amount),
          this.coll_type,
          this.checkedStacking
      );
    }else if(this.coll_token === 'STSW'){
      txCreateVaultWithSTSW(this,
          new BigNumber(this.input_token_amount),
          new BigNumber(this.output_token_amount),
          this.coll_type,
          this.checkedStacking
      );
    }
    event.stopPropagation();
  }
}
