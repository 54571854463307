
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getBalance,
  getDatas,
  getPairBalance,
  getSenderAddress,
  isInWhitelist,
  getUserCountFromVSTSW,
  txStakeFromStaking,
  getStakingBalanceFromVSTSW,
  getRewardBalanceFromStaking,
  getCurrentStackingRound,
  getReward,
  getProposalsList,
  getStakingBalanceFromVSTSW_V2,
  getBlockHeight,
  getNumVoting,
  txWithdrawVoting,
  getRewardTotal,
  numWithComma,
  getStakingSTSWBalance, getSTXUSD, getSTXSTSWPrice, getStakingAPR, getRewardRoundID_V2, numFormat_
} from '@/stacks/manager';
import axios from 'axios';
import luHeader from '@/layout/header.component.vue';
import {IS_TESTNET, BASE_STSW_DATA, BASE_WSTX_DATA, BASE_vSTSW_DATA} from '@/stacks/config';
// import vote_confirm from '@/router/staking/components/vote-modal.vue';
import vote_confirm from '@/router/staking/components/vote.component.vue';
import vote_popup from '@/router/staking/components/vote.popup.vue';
import confirm_popup from'@/components/modal/confirm.popup.vue';
import BigNumber from 'bignumber.js';
import {getDecimalStringfromString} from '@/stacks/token.tool';

@Component({
  components: {
    luHeader,
    vote_confirm,
    confirm_popup,
    vote_popup,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },

})
export default class StakingPage extends Vue {

  data_list: any = [];

  STSW_Token = BASE_STSW_DATA();
  STSW_balance:string= 'Loading...';

  WSTX_balance:string= 'Loading...';
  WSTX_Token = BASE_WSTX_DATA();

  vSTSW_balance:string= 'Loading...';
  vSTSW_Token = BASE_vSTSW_DATA();

  STSW_stake_balance:string = 'Loading...';
  STSW_reward_balance:string= 'Loading...';

  toggleVote:boolean = false;
  selected_proposal_id:number=0;
  num_for_vote:number= 0;
  num_against_vote:number= 0;
  current_block: string ='Loading...';
  num_my_vote:string = 'Loading...';
  toggleVoteWithdraw:boolean = false;

  TVL: string = 'Loading...';
  TVL_STACKS: string = '0';
  mTVL: string = 'Loading...';
  APR: string = 'Loading...';
  APR_1: string = 'Loading...';
  APR_36: string = 'Loading...';

  res_list: any = [];
// popup-var
  popup_show:boolean=false;
  popup_title:string='';
  popup_contents:string='None-Contents';

  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    this.popup_show = false;
  }
  // ==== PopupSetting =====//

  created() {
    if (this.$store.getters.isConnected) {
      // console.log("TTTTT", this.STSW_Token, this.vSTSW_Token)
      this.onConnect();

    }
  }

  numFormat (input:any, decimal:number = 3){
    if (input === 'Loading...'){
      return 'Loading...';
    }
    return numFormat_(input, decimal);
  }

  onConnect() {
    this.UpdateBalance();
    getBlockHeight().then((value)=>{
      this.current_block = value.toString();
    })
    getProposalsList(this).then(value => {
      // console.log("raw", value)
      this.data_list = value;
    });

  }

  setTokenBalance(token_obj:any){
    // alert(`${token_obj.balance / (10 ** token_obj.decimal)}`)
    return token_obj.balance / (10 ** token_obj.decimal);
  }

 async UpdateBalance(){
    const usd_stx = await getSTXUSD(this);
    const stx_stsw = await getSTXSTSWPrice(this);

    getBalance(this, this.WSTX_Token).then((value) =>
        this.WSTX_balance = this.setTokenBalance(this.WSTX_Token).toString()
    );

    getBalance(this, this.STSW_Token).then((value) =>
        this.STSW_balance = this.setTokenBalance(this.STSW_Token).toString()
    );

    getBalance(this, this.vSTSW_Token).then((value) =>
        this.vSTSW_balance = this.setTokenBalance(this.vSTSW_Token).toString()
    );
    // getUserCountFromVSTSW -> for 문 없이
    getStakingBalanceFromVSTSW_V2(this).then((value)=>{
      // getUserCountFromVSTSW(this);
      // this.STSW_stake_balance = (value / (10 ** this.STSW_Token.decimal)).toString()
      this.STSW_stake_balance = value.toString();
      this.mTVL = new BigNumber(value.toString()).multipliedBy(stx_stsw).multipliedBy(usd_stx).toFixed(3).toString();
    })


    getStakingSTSWBalance(this).then((value) =>
        {
          this.TVL = new BigNumber(value).minus(20000000000000).multipliedBy(stx_stsw).multipliedBy(usd_stx).div(10**6).toFixed(3).toString();
          // this.APR = getStakingAPR(value).toFixed(3).toString();
          // this.TVL = getDecimalStringfromString(value.toString(),6);
          this.APR_1 = getStakingAPR(value, '100000000',1);
          this.APR_36 = getStakingAPR(value, '100000000',36);
          this.APR = this.APR_1 + '% ~ ' + this.APR_36 + '%';
          this.TVL_STACKS = new BigNumber(value).toString();
        }
    )

   getRewardRoundID_V2(this).then(value=>{
     this.res_list = value;
     getRewardTotal(this, value).then((value=>{
       this.STSW_reward_balance = (value / (10 ** this.STSW_Token.decimal)).toString()
     }));
   })
  }

  onClickStake(){
    this.$router.push({name: 'stake', params: {stsw_balance: this.STSW_balance, tvl: this.TVL_STACKS}});
  }
  onClickUnstake(){
    this.$router.push({name: 'unstake'});
  }
  onClickWithdrawReward(){
    this.$router.push({name: 'reward', params: {res_list: this.res_list}});
  }
  ///////////////////////////////////////////////////////
  onClickOpenVote(data_id:number){
    this.selected_proposal_id = data_id;
    // tslint:disable-next-line:radix
    const start_block = parseInt(this.data_list[data_id]['start-block-height'].value,10);
    // tslint:disable-next-line:radix
    const end_block = parseInt(this.data_list[data_id]['end-block-height'].value,10);
    // tslint:disable-next-line:radix
    const current_block = parseInt(this.current_block,10);
    // console.log("voting", this.data_list[data_id]["start-block-height"].value,this.data_list[data_id]["end-block-height"].value)
    // console.log("voting", typeof(this.data_list[data_id]["start-block-height"].value))
    // console.log(this.data_list[data_id], this.selected_proposal_id)
    if(start_block<= current_block && current_block <= end_block){
      // this.$router.push({name: 'vote'});
      this.toggleVote = true;
      this.toggleVoteWithdraw= false;

      // tslint:disable-next-line:radix
    }else if(parseInt(this.num_my_vote,10) > 0 && current_block > end_block) {
      this.toggleVoteWithdraw= true;
    }
    else{
      this.toggleVoteWithdraw= false;
      this.openPopup('It is not voting period.');
    }
  }
  onClickWithdrawVote(data_id:number){
    // alert("Withdraw")
    txWithdrawVoting(this, data_id);
  }
  onClickProposal(data_id:number, map_data:any){
    // alert(`CLICKED!!!!! ${data_id}`)
    // console.log(data_id, map_data)


    // let start_block = parseInt(this.data_list[data_id]["start-block-height"].value);
    // let end_block = parseInt(this.data_list[data_id]["end-block-height"].value);
    // let current_block = parseInt(this.current_block);
    this.$router.push({name: 'vote_act', query:{proposal_dict: map_data, balance: this.vSTSW_balance}});
    // getNumVoting(this, data_id).then(([value, res])=>{
    //   this.num_my_vote = value;
    //   console.log(value, res)
    //   this.data_list[data_id].MyVote = value;
    //   this.$forceUpdate();
    //   if(res == false && parseInt(value) > 0 && current_block > end_block){
    //     this.toggleVoteWithdraw= true;
    //   }else{
    //     this.toggleVoteWithdraw= false;
    //   }
    // })


  }
  onClickCloseVote() {
    this.toggleVote = false
  }

  onClickVoteFor(num_vote:number) {
    alert(`For : ${num_vote}`)
    this.toggleVote = false
  }

  onClickVoteAgainst(num_vote:number) {
    alert(`Against : ${num_vote}`)
    this.toggleVote = false
  }

  ///////////////////////////////////////////////////////
  setVote_IsOpen(source:any){
    // console.log("TTTTT", source)
    if(source['is-open'].value){
      // tslint:disable-next-line:radix
      if(parseInt(source['start-block-height'].value,10) <= parseInt(this.current_block,10) &&
          // tslint:disable-next-line:radix
          parseInt(this.current_block,10) <=  parseInt(source['end-block-height'].value,10)){
        return 'OPEN';
      }else{
        return 'CLOSE';
      }


    }else{
      return 'CLOSE';
    }
  }

  setVote_Title(source:string){
    if(source===''){
      return 'NONE-TITLE'
    }else{
      return source
    }
  }

  setVote_URL(source:string){
    if(source===''){
      return 'NONE-URL'
    }else{
      return source
    }
  }

}
