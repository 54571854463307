
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  changeRiskParams,
} from '@/stacks/manager';

import luHeader from '@/layout/header.component.vue';
import {
  CONTRACT_NAME_GOLD_PASS, CONTRACT_NAME_SILVER_PASS,
  CONTRACT_NAME_STACKSWAP_FARMING,
  CONTRACT_NAME_STACKSWAP_STACKING,
  IS_TESTNET,
} from '@/stacks/config';
import {contractPrincipalCV, standardPrincipalCV, stringAsciiCV, TupleCV, tupleCV, uintCV} from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import {batchSetMintPass, mintNFT, mintNFT2, mintNFTs} from '@/stacks/nft/nft.manager';
import {principalCV} from '@stacks/transactions/dist/clarity/types/principalCV';
import {StandardPrincipalCV} from '@stacks/transactions/dist/clarity';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class MintpassNftPage extends Vue {

  isGold: string = '';

  addresses: string = '';

  amounts: string = '';
  toAddr: string = '';
  counts: string = '';

  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    // console.log('test')\
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  setUp() {
    const mList: TupleCV[] = [];
    //    ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5
    // 1,1,1,1,1,1
    const addr_list = this.addresses.split(',');
    // console.log(addr_list);
    const amount_list = this.amounts.split(',');
    // console.log(amount_list);
    if ( addr_list.length !== amount_list.length) {
      alert('please input same length');
      return;
    }
    for (let i = 0; i < addr_list.length; i++) {
      mList.push(tupleCV({account: standardPrincipalCV(addr_list[i]), limit : uintCV(amount_list[i])}));
    }
    if (this.isGold === '') {
      batchSetMintPass(this, mList, CONTRACT_NAME_GOLD_PASS());
    } else {
      batchSetMintPass(this, mList, CONTRACT_NAME_SILVER_PASS());
    }
  }


  mint() {
    if (this.isGold === '') {
      mintNFTs(this, 0, CONTRACT_NAME_GOLD_PASS(), this.toAddr,  Number(this.counts));
    } else {
      mintNFTs(this, 0, CONTRACT_NAME_SILVER_PASS(), this.toAddr, Number(this.counts));
    }
  }

}
