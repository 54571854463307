import {openContractCall} from "@stacks/connect";
import {BASE_STSW_DATA, CONTRACT_NAME_STACKSWAP_FARMING3_LOGIC, STACKSWAP_ADDRESS} from "@/stacks/config";
import {callReadOnlyFunction, cvToValue, falseCV, FungibleConditionCode, trueCV, uintCV,} from "@stacks/transactions";
import {
  getBasicTokenOptions,
  getBasicTokenWriteOptionsCallbackCondition,
  getPostConditionFromAsset,
  getSenderAddress,
  parceAddressToCV,
} from "@/stacks/manager";
import {CONTRACT_TYPE} from "@/stacks/farming/farm2.manager";


export async function deposit_vote_multisig(source: any, vote: boolean) {

  // console.log({amount});
  const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, 'SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', 'deposit-vote', [
    vote ? trueCV() : falseCV(),
  ], []);
  await openContractCall(function_option);
}

export async function withdraw_vote_multisig(source: any, vote: boolean) {

  // console.log({amount});
  const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, 'SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', 'withdraw-vote', [
    vote ? trueCV() : falseCV(),
  ], []);
  await openContractCall(function_option);
}

export async function deposit_multisig(source: any, amount: string) {

  // console.log({amount});
  const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
  const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, 'SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', 'deposit', [
    uintCV(amount),
  ], post_condition);
  await openContractCall(function_option);
}

export async function withdraw_multisig(source: any, addr: string, amount: string) {

  // console.log({amount});
  const post_condition = [await getPostConditionFromAsset('SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];

  const function_option: any =  getBasicTokenWriteOptionsCallbackCondition(source, 'SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', 'withdraw', [
    parceAddressToCV(addr), uintCV(amount),
  ], post_condition);
  await openContractCall(function_option);
}

export async function set_new_reward(source: any, addr: string, amount: string, contractType: CONTRACT_TYPE, group: string = '') {

  // console.log({amount});
  // const post_condition = [await getPostConditionFromAsset(addr, BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
  switch (contractType) {
    case CONTRACT_TYPE.FARM_V2: {
      const function_option: any = getBasicTokenWriteOptionsCallbackCondition(source, addr, 'changeRewardAmount', [
        uintCV(amount),
      ], []);
      await openContractCall(function_option);
      break;
    }
    case CONTRACT_TYPE.STAKING: {
      const function_option: any = getBasicTokenWriteOptionsCallbackCondition(source, addr, 'set-mining-reward', [
        uintCV(amount),
      ], []);
      await openContractCall(function_option);
      break;
    }
    case CONTRACT_TYPE.FARM_V3: {
      const function_option: any = getBasicTokenWriteOptionsCallbackCondition(source, `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_FARMING3_LOGIC()}`, 'changeRewardAmount', [
        uintCV(group),
        uintCV(amount),
      ], []);
      await openContractCall(function_option);
      break;
    }
  }
}

export async function getMultisigSTSWAmount(source: any) {
  // console.log('getStakingSTSWBalance');
  const pair_details_options: any = getBasicTokenOptions(source, BASE_STSW_DATA().addr, 'get-balance',
    [parceAddressToCV('SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01')]);
  try {
    const result = await callReadOnlyFunction(pair_details_options);
    console.log('get-balance', cvToValue(result));
    return  cvToValue(result).value;
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}

export async function getMultisigResult(source: any, functionName: string) {
  const pair_details_options: any = getBasicTokenOptions(source, 'SPVRC3RHFD58B2PY1HZD2V71THPW7G445WBRCQYW.octopus_v01', functionName,
    []);
  const result = await callReadOnlyFunction(pair_details_options);
  console.log(functionName, cvToValue(result));
  return  cvToValue(result);
}
