
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
// import selectToken from '@/router/swap/components/select-token.component.vue';
// import confirm from '@/router/swap/components/confirm.component.vue';
import {
  getPairData,
  getBalance,
  isInWhitelist,
  numWithComma,
  numFormat_,
  getBlockHeight,
  getSenderAddress, getBlockHeightWithBurn
} from '@/stacks/manager';
import {BASE_STX, BASE_WSTX_DATA, IS_TESTNET, STACKS_BACKEND_PARAM, STACKS_BACKEND_URL} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
// import {getLPInfo} from '@/stacks/lp_info';
import {
  getDebtRatio,
  getPoxInfo, getStabilityFee,
  getVaultIDFromUser,
  getVaultIDFromUser2,
  getVaultInfoFromID,
  getVaultTypeInfo
} from '@/stacks/manager_lbtc';
import BigNumber from 'bignumber.js';


@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'tolerance',
      'isConnected',
    ]),
  },
})

export default class VaultPage extends Vue {
  info_stacking_cycle: string = 'Loading';
  info_cycle_end_date:string='Loading'
  info_days_in_cycle:string = 'Loading';
  info_days_left:string = 'Loading';
  token_x: { icon: string, name: string, [key: string]: any} | any = BASE_WSTX_DATA();
  current_block:string = 'Loading';

  CollateralTypeList = ['STX-A', 'STX-B', 'STSW-A', 'STSW-B'];
  // CollateralTypeList = ['STSW-A', 'STSW-B'];
  CollateralTypeDict: {[type: string]: {
      type:string,
      debt_ratio: string,
      liquidation_ratio: string,
      // liquidation_penalty: string,
      coll_token:string,
      coll_address:string,
      max_debt:string,
      total_debt:string,
      stability_fee_apy:string
    };} = {};
  load_done:boolean = false;

  vaults:any = [];

  total_stx_amt:string = 'Loading';
  total_stsw_amt:string = 'Loading';
  test:any = null;

  async created() {
    let sum_stx = 0;
    let sum_stsw= 0;
    const block_temp = await getBlockHeightWithBurn();
    // console.log(block_temp);
    const block_num = block_temp.block_height;
    this.current_block = block_num.toString();

    const user_wallet = getSenderAddress(this);
    const user_vaults = this.$store.getters.getVault(block_num, user_wallet);
    this.test = this.$store.getters.user_vault_list;
    // console.log(user_vaults)
    // console.log('Address : ', user_wallet)
    if (user_vaults !== null
        && user_vaults.addr === user_wallet
        && user_vaults.addr !== BASE_STX()){
      this.vaults = user_vaults.data;
      for (const vault_dict of this.vaults) {
        if (vault_dict['collateral-token'].value === 'STX') {
          // tslint:disable-next-line:radix
          sum_stx = sum_stx + parseInt(vault_dict.collateral.value);
        } else if (vault_dict['collateral-token'].value === 'STSW') {
          // tslint:disable-next-line:radix
          sum_stsw = sum_stsw + parseInt(vault_dict.collateral.value);
        }
      }
    } else if (user_wallet !== BASE_STX()){
      const vault_ids = await getVaultIDFromUser2(this);
      // console.log('Vaults List: ', vault_ids['ids'].value )

      for (const vault_dict of vault_ids.ids.value) {
        const res = await getVaultInfoFromID(this, vault_dict.value)
        // console.log('vaults : ', res)
        if (res.debt.value !== '0') {
          // console.log('vaults : ', res)
          const debt_ratio = await this.get_debt_ratio(
              new BigNumber(res.collateral.value).dividedBy(10 ** 6).toString(),
              new BigNumber(res.debt.value).dividedBy(10 ** 8).toString(),
              res['collateral-token'].value
          );
          const stability_fee = new BigNumber((await this.get_stability_fee(vault_dict.value)).value).dividedBy(10 ** 8).toString();
          res.current_debt_ratio = debt_ratio;
          res.current_stability_fee = stability_fee;
          // console.log('tt', res['current_stability_fee'])
          this.vaults.push(res)
          if (res['collateral-token'].value === 'STX') {
            // tslint:disable-next-line:radix
            sum_stx = sum_stx + parseInt(res.collateral.value);
          } else if (res['collateral-token'].value === 'STSW') {
            // tslint:disable-next-line:radix
            sum_stsw = sum_stsw + parseInt(res.collateral.value);
          }
        }
      }
      await this.$store.dispatch('setUserVaults', {block:block_num, addr:user_wallet, data : this.vaults});
    }
    if (user_wallet !== BASE_STX()) {
      // console.log(this.vaults);
      this.total_stx_amt = (sum_stx / (10 ** 6)).toFixed(0);
      this.total_stsw_amt = (sum_stsw / (10 ** 6)).toFixed(0);
    }else{
      this.total_stx_amt = '0';
      this.total_stsw_amt = '0';
    }

    getPoxInfo(this).then((pox_info) =>
        {
          this.info_stacking_cycle = pox_info.value['reward-cycle-id'].value;

          const first_block = pox_info.value['first-burnchain-block-height'].value;
          // tslint:disable-next-line:radix
          const cycle_length =  parseInt(pox_info.value['reward-cycle-length'].value);
          const start_block = (new BigNumber(first_block).plus((new BigNumber(cycle_length)).multipliedBy(new BigNumber(this.info_stacking_cycle)))).toNumber();
          const end_block = (new BigNumber(first_block).plus((new BigNumber(cycle_length)).multipliedBy(new BigNumber(this.info_stacking_cycle).plus(1)))).toNumber();

          this.info_days_in_cycle = ((block_temp.burn_block_height - start_block) / cycle_length * 14).toFixed(0);
          // tslint:disable-next-line:radix
          this.info_days_left = (14-parseInt(this.info_days_in_cycle)).toString();
          const today = new Date();
          // tslint:disable-next-line:radix
          this.info_cycle_end_date = (today.getMonth()+1)+' '+ (today.getDate() + parseInt(this.info_days_left) )+ ' '+ today.getFullYear();
          // console.log(pox_info);
        }
    );

    const col_types = this.$store.getters.collateral_type;
    if ( col_types.block === block_num){
      this.CollateralTypeDict = col_types.data;
    } else {
      for (const type of this.CollateralTypeList) {
        const info = await getVaultTypeInfo(this, type);
        this.CollateralTypeDict[type] = {
          type,
          debt_ratio:info.value['collateral-to-debt-ratio'].value,
          liquidation_ratio:info.value['liquidation-ratio'].value,
          // liquidation_penalty:(new BigNumber(info.value['liquidation-penalty'].value).dividedBy(100)).toString(),
          coll_token:info.value.token.value,
          coll_address:info.value['token-address'].value,
          max_debt:(new BigNumber(info.value['maximum-debt'].value).dividedBy(10**8)).toString(),
          total_debt:(new BigNumber(info.value['total-debt'].value).dividedBy(10**8)).toString(),
          stability_fee_apy:(new BigNumber(info.value['stability-fee-apy'].value).dividedBy(100)).toString()
        };
        // console.log(type, this.CollateralTypeDict);
      }
      await this.$store.dispatch('setCollateralType', {block: block_num, data : this.CollateralTypeDict});
    }

    this.load_done = true
  }

  strContains(input:string, target:string) {
    return input.indexOf(target) > -1
  }
  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }

  goCreate(coll_type:string) {
    // alert(coll_type)
    this.load_done = false;
    this.$router.push({
      name: 'create',
      params:{
        coll_type:this.CollateralTypeDict[coll_type].type,
        debt_ratio: this.CollateralTypeDict[coll_type].debt_ratio,
        liquidation_ratio: this.CollateralTypeDict[coll_type].liquidation_ratio,
        // liquidation_penalty: this.CollateralTypeDict[coll_type]['liquidation_penalty'],
        coll_token:this.CollateralTypeDict[coll_type].coll_token,
        coll_address:this.CollateralTypeDict[coll_type].coll_address,
        max_debt:this.CollateralTypeDict[coll_type].max_debt,
        total_debt:this.CollateralTypeDict[coll_type].total_debt,
        stability_fee_apy:this.CollateralTypeDict[coll_type].stability_fee_apy
      }
    });
  }

  goManage(id:string) {
    this.load_done = false;
    this.$router.push({name: 'manage', params:{vault_id:id}});
  }
  async get_debt_ratio(coll_amt:string, deb_amt:string, coll_token:string) {
    return (await getDebtRatio(this,
        new BigNumber(coll_amt),
        new BigNumber(deb_amt),
        coll_token
    )).value;
  }
  async get_stability_fee(vault_id:string) {
    return (await getStabilityFee(this,
        vault_id,
    ));
  }
}
