import {StacksMainnet, StacksMocknet, StacksTestnet} from '@stacks/network';

import BigNumber from 'bignumber.js';
import {
    AnchorMode,
    callReadOnlyFunction,
    contractPrincipalCV,
    createFungiblePostCondition,
    createSTXPostCondition,
    cvToValue,
    FungibleConditionCode,
    listCV,
    noneCV,
    bufferCV,
    PostConditionMode,
    standardPrincipalCV,
    stringAsciiCV,
    stringUtf8CV,
    uintCV, tupleCV, ClarityValue
} from '@stacks/transactions';
import {openContractCall} from '@stacks/connect';
import {
    BASE_LBTC_DATA,
    BASE_STSW_DATA,
    BASE_STX,
    BASE_vSTSW_DATA,
    BASE_WSTX_DATA,
    CONTRACT_NAME_COLLATERAL_TYPES,
    CONTRACT_NAME_MORTGAGER,
    CONTRACT_NAME_ORACLE,
    CONTRACT_NAME_SIP10_RESERVE,
    CONTRACT_NAME_STACKSWAP_FARMING2_1,
    CONTRACT_NAME_STACKSWAP_GOVERNANCE,
    CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT,
    CONTRACT_NAME_STACKSWAP_ROUTER_SWAP,
    CONTRACT_NAME_STACKSWAP_STACKING,
    CONTRACT_NAME_STACKSWAP_SWAP,
    CONTRACT_NAME_STX_RESERVE,
    IS_TESTNET,
    IS_WHITELIST_ON,
    MODE,
    STACKS_API_URL,
    STACKS_BACKEND_URL,
    STACKSWAP_ADDRESS,
    STX_STSW_LP,
} from '@/stacks/config';
import axios from 'axios';
import {
    getAmountStringWithDecimal,
    getAmountWithDecimalString,
    getPriceAndDY
} from '@/stacks/token.tool';
import {StacksNetwork} from '@stacks/network/src/index';
import {ReadOnlyFunctionOptions} from '@stacks/transactions/src/builders';
import {getFarmingRewardInfo, getFarmingRewardInfo22} from "@/stacks/farming/farm2.manager";
import {sha256} from "bitcoinjs-lib/src/crypto";
import {Buffer} from "bitcoinjs-lib/src/types";
import {BufferReader} from "bitcoinjs-lib/src/bufferutils";


////////////////////////////////////////////////////
type BooleanCV = TrueCV | FalseCV;
interface TrueCV {
    type: 0x03;
}

interface FalseCV {
    type: 0x04;
}

const trueCV = (): BooleanCV => ({ type: 0x03 });
const falseCV = (): BooleanCV => ({ type: 0x04 });

////////////////////////////////////////////////////////////////

export function getSenderAddress(source: any) : string {
    if (source.$store.getters.isConnected) {
        return IS_TESTNET() ? source.$store.getters.user.testnetAddress : source.$store.getters.user.mainnetAddress;

        // [ 'SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03',
        //     'SP2QM2TZP4DD8KP2MX6C5TA5P82S3KXZM9NV267RN',
        //     'SPJ2VG1VQHTWSS7D7MPYKGPM8CBCVSVH53D6WDBR',
        //     'SPDP8YK19CE3G3J2PGCRWXK770VQC8P5GC341JZM',
        //     'SP3Q3Y5E2S782ZWCQNQM6S66FCBVB4SS764RMV6KF',
        //     'SP1420SJVGARGC631S7NSDJJHX85ZZXD6Q3CF13K6',
        //     'SPA7KHZWAV9HZWA6T8VC242D23VDK521M61YGEMD'
        // ]
        // return 'SP1420SJVGARGC631S7NSDJJHX85ZZXD6Q3CF13K6';
    } else {
        return BASE_STX();
    }
}

export function getNetwork() : StacksNetwork {
    switch (MODE()) {
        case 'mainnet':
            return new StacksMainnet({url: STACKS_API_URL()});

        case 'testnet':
            return new StacksTestnet();


        default:
            return new StacksMocknet();
    }
}

export function parceAddressToCV(addr: string){
    if (addr.includes('.')) {
        const [address, contractName] = addr.split('.');
        return contractPrincipalCV(address, contractName);
    } else {
        return standardPrincipalCV(addr);
    }
}

export function getBasicReadOptions(source: any, contractName: string, functionName: string, functionArgs: ClarityValue[]) : ReadOnlyFunctionOptions  {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        // @ts-ignore
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
    };
}

export function getBasicWriteOptions(source: any, contractName: string, functionName: string, functionArgs: any) {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Allow,
        postConditions: [ ],
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.tx_success(true);
                source.input(false);
            } catch (e) {
                // console.log(e);
            }
        },
    };
}


function getBasicWriteOptionsConditon(source: any, contractName: string, functionName: string, functionArgs: any, postConditions: any) {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        sponsored: false,
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.tx_success(true);
                source.input(false);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            // console.log('data is good');
        },
    };
}
export function getBasicWriteOptionsConditonCallback(source: any, contractName: string, functionName: string, functionArgs: any, postConditions: any) {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        sponsored: false,
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

async function getFTAsset(tokenStr: string) {
    // console.log(tokenStr);
    const token_addr = tokenStr.split('.');
    const url = STACKS_API_URL() + '/v2/contracts/interface/' + token_addr[0] + '/' + token_addr[1];
    const result = await axios.get(url);
    // console.log(tokenStr,result);
    if (result.data.fungible_tokens.length === 1) {
        // console.log(tokenStr+'::'+result.data.fungible_tokens[0].name);
        return tokenStr + '::' + result.data.fungible_tokens[0].name;
    } else if (result.data.fungible_tokens.length === 0) {
        return 'wstx';
    } else {
        // TODO one contract with more than one ft
        // console.log(tokenStr+'::'+result.data.fungible_tokens[0].name);
        return tokenStr + '::' + result.data.fungible_tokens[0].name;
    }
}
export async function getPostConditionFromAsset(account: string, tokenStr: string, tokenAmount: any, condition: FungibleConditionCode) {
    const assetString = await getFTAsset(tokenStr);
    if (assetString === 'wstx') {
        // console.log('tokenamount',tokenAmount.toNumber());
        return createSTXPostCondition(account, condition, tokenAmount);
    } else {
        return createFungiblePostCondition(account, condition, tokenAmount, assetString);
    }
}

export function getBasicTokenOptions(source: any, tokenStr: string, functionName: string, functionArgs: any) : any {
    // console.log(tokenStr);
    const token_addr = tokenStr.split('.');
    return {
        contractAddress: token_addr[0],
        contractName: token_addr[1],
        functionName,
        functionArgs,
        // @ts-ignore
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
    };
}


export function getBasicTokenWriteOptions(source: any, tokenStr: string, functionName: string, functionArgs: any) {
    const token_addr = tokenStr.split('.');
    return {
        contractAddress: token_addr[0],
        contractName: token_addr[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Allow,
        postConditions: [ ],
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.tx_success(true);
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export function getBasicTokenWriteOptionsCallbackCondition(source: any, tokenStr: string, functionName: string, functionArgs: any, postConditions_: any) {
    const token_addr = tokenStr.split('.');
    return {
        contractAddress: token_addr[0],
        contractName: token_addr[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions: postConditions_,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}
export async function getPoolSTXPrice(pool:any, stx_usd:number,stsw_usd:number, btc_usd:number){
    // BigNumber.config({ DECIMAL_PLACES: 6});

    const x_token_addr = pool.token_x.addr;
    const y_token_addr = pool.token_y.addr;

    let total_balacne = new BigNumber(-1);
    let total_price = -1;
    if(x_token_addr === BASE_WSTX_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-x'].value).dividedBy(new BigNumber(10 ** pool.token_x.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(stx_usd).toNumber();
    }else if(y_token_addr === BASE_WSTX_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-y'].value).dividedBy(new BigNumber(10 ** pool.token_y.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(stx_usd).toNumber();
    }else if(x_token_addr === BASE_STSW_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-x'].value).dividedBy(new BigNumber(10 ** pool.token_x.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(stsw_usd).toNumber();
    }else if(y_token_addr === BASE_STSW_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-y'].value).dividedBy(new BigNumber(10 ** pool.token_y.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(stsw_usd).toNumber();
    }else if(x_token_addr === BASE_LBTC_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-x'].value).dividedBy(new BigNumber(10 ** pool.token_x.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(btc_usd).toNumber();
    }else if(y_token_addr === BASE_LBTC_DATA().addr){
        total_balacne =  (new BigNumber(pool.pair['balance-y'].value).dividedBy(new BigNumber(10 ** pool.token_y.decimal))).multipliedBy(2);
        total_price = total_balacne.multipliedBy(btc_usd).toNumber();
    }
    // console.log(pool.token_x.addr, pool.token_y.addr,pool.token_x.balance,pool.token_y.balance,total_balacne.toNumber())
    // console.log(pool.pair["balance-x"].value, pool.pair["balance-y"].value, total_balacne.toNumber())

    return total_price;
}



export async function getStackingRoundFirstBlock(source: any, cycle: number){
    const function_option =  getBasicReadOptions(source,
      CONTRACT_NAME_STACKSWAP_STACKING(),
      'get-first-stacks-block-in-reward-cycle', [
          uintCV(cycle)
      ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    return result;
}


export async function getPairData(source: any) {
    const token_x_addr = source.token_x.addr;
    const token_y_addr = source.token_y.addr;
    const res = await getDatas(source, source.token_x.addr, source.token_y.addr);
    if ( Object.keys(res).length === 0) {


        const res2 = await getDatas(source, source.token_y.addr, source.token_x.addr);
        if ( Object.keys(res2).length === 0) {
            // source.valid = 0;

            let valid = 0;
            if(token_x_addr !== BASE_STSW_DATA().addr &&
                token_y_addr !== BASE_STSW_DATA().addr) {
                const STSW_router_x1 = await getDatas(source, token_x_addr, BASE_STSW_DATA().addr);
                const STSW_router_x2 = await getDatas(source, BASE_STSW_DATA().addr, token_x_addr);
                const STSW_router_y1 = await getDatas(source, token_y_addr, BASE_STSW_DATA().addr);
                const STSW_router_y2 = await getDatas(source, BASE_STSW_DATA().addr, token_y_addr);
                if ((Object.keys(STSW_router_x1).length !== 0 || Object.keys(STSW_router_x2).length !== 0) &&
                    (Object.keys(STSW_router_y1).length !== 0 || Object.keys(STSW_router_y2).length !== 0)) {
                    let x_mode = true;
                    source.router_from_pair = STSW_router_x1;
                    if (Object.keys(STSW_router_x2).length !== 0) {
                        x_mode = false;
                        source.router_from_pair = STSW_router_x2;
                    }
                    let y_mode = true;
                    source.router_to_pair = STSW_router_y2;
                    if (Object.keys(STSW_router_y1).length !== 0) {
                        y_mode = false;
                        source.router_to_pair = STSW_router_y1;
                    }
                    valid = 0;
                    source.valid = 0;
                    source.router_from_mode = x_mode;
                    source.router_to_mode = y_mode;
                    source.setRouterYValue();
                    // alert(`STSW Bridge Swap X_mode : ${x_mode}, Y_mode: ${y_mode}`)
                }
            }
            if(token_x_addr !== BASE_WSTX_DATA().addr &&
                token_y_addr !== BASE_WSTX_DATA().addr) {
                const STX_router_x1 = await getDatas(source, token_x_addr, BASE_WSTX_DATA().addr);
                const STX_router_x2 = await getDatas(source, BASE_WSTX_DATA().addr, token_x_addr);
                const STX_router_y1 = await getDatas(source, token_y_addr, BASE_WSTX_DATA().addr);
                const STX_router_y2 = await getDatas(source, BASE_WSTX_DATA().addr, token_y_addr);
                if((Object.keys(STX_router_x1).length !== 0 || Object.keys(STX_router_x2).length !== 0)&&
                    (Object.keys(STX_router_y1).length !== 0 || Object.keys(STX_router_y2).length !== 0)) {
                    let x_mode = true;
                    source.router_from_pair = STX_router_x1;
                    if (Object.keys(STX_router_x2).length !== 0) {
                        x_mode = false;
                        source.router_from_pair = STX_router_x2;
                    }
                    let y_mode = true;
                    source.router_to_pair = STX_router_y2;
                    if (Object.keys(STX_router_y1).length !== 0) {
                        y_mode = false;
                        source.router_to_pair = STX_router_y1;
                    }
                    valid = 0;
                    source.valid = 0;
                    source.router_from_mode = x_mode;
                    source.router_to_mode = y_mode;
                    source.setRouterYValue();
                    // alert(`STX Bridge Swap X_mode : ${x_mode}, Y_mode: ${y_mode}`)
                }
            }
            if(valid === 0){
                console.log(source.valid)
                source.valid = 0;
            }

        } else {
            // console.log({token_x_addr, 's_x': source.token_x.addr, token_y_addr,'s_y' : source.token_y.addr});
            if ( token_x_addr === source.token_x.addr && token_y_addr === source.token_y.addr ) {
                source.x_to_y = false;
                source.valid = 2;
                source.pair = res2;
                source.pair.price = (source.pair['balance-x'].value / (10 ** source.token_y.decimal)) / (source.pair['balance-y'].value / (10 ** source.token_x.decimal));
                if ( isNaN( source.pair.price ) ) {
                    source.pair.price = 0;
                } else {
                    try {
                        // console.log({token_x_addr, 's_x': source.token_x.addr, token_y_addr, 's_y': source.token_y.addr});
                        source.setTokenYValue();
                    } catch
                        (e) {
                        return null;
                    }
                }
            }
        }
    } else {
        if (token_x_addr === source.token_x.addr && token_y_addr === source.token_y.addr) {
            source.x_to_y = true;
            source.valid = 2;
            source.pair = res;
            source.pair.price = (source.pair['balance-y'].value / (10 ** source.token_y.decimal)) / (source.pair['balance-x'].value / (10 ** source.token_x.decimal));
            if ( isNaN( source.pair.price ) ) {
                source.pair.price = 0;
            } else {
                try {
                    // console.log({token_x_addr, 's_x': source.token_x.addr, token_y_addr, 's_y': source.token_y.addr});
                    source.setTokenYValue();
                } catch
                    (e) {
                    return null;
                }
            }
        }
    }
}

export async function getSTXSTSWPrice(source: any){
    try{
        if (source.$store.getters.stx_stsw > 0){
            return source.$store.getters.stx_stsw;
        } else {
            // @ts-ignore
            const result2 = await callReadOnlyFunction(getBasicTokenOptions(source, STX_STSW_LP(), 'get-lp-data', []));
            // console.log('cv-to-value',cvToValue(result2)['value']);
            const price = getPriceAndDY(true, cvToValue(result2).value, {decimal:6}, {decimal:6}).output_price;
            await source.$store.dispatch('setSTX_STSW', price);
            // console.log('price',price);
            return price;
        }
    } catch (e) {
        // console.log(e);
        return 0.2;
    }
}

export async function getSTXUSD(source: any) {
    try{
        if (source.$store.getters.usd_stx > 0){
            return source.$store.getters.usd_stx;
        } else {
            const res = await axios.get ( STACKS_BACKEND_URL() + '/stacks/usd/');
            const price_dict = JSON.parse(res.data);
            const price = (price_dict.open_price+price_dict.close_price) / 2
            await source.$store.dispatch('setUSD_STX', price);
            return price;
        }
    } catch (e) {
        // console.log(e);
        return 2.5;
    }
}

export async function getDatas(source: any, token_x_add: string, token_y_add: string ) {
    const token_x_addr = token_x_add.split('.');
    const token_y_addr = token_y_add.split('.');
    const pair_details_options : any = getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'get-pair-details',
        [ contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        // @ts-ignore
        const result2 = await callReadOnlyFunction(getBasicTokenOptions(source, cvToValue(result)['liquidity-token'].value, 'get-lp-data', []));
        // console.log('cv-to-value',cvToValue(result2)['value']);
        return cvToValue(result2).value;
    } catch (e) {
        // console.log(e);
        return {};
    }
    // console.log(pair_details_result)
}

export async function getPoolDatasLegacy(source: any, token_x_add: string, token_y_add: string, swap_contract: string ) {
    const token_x_addr = token_x_add.split('.');
    const token_y_addr = token_y_add.split('.');
    const pair_details_options : any = getBasicTokenOptions(source, swap_contract, 'get-pair-details',
        [ contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        // @ts-ignore
        const result2 = await callReadOnlyFunction(getBasicTokenOptions(source, cvToValue(result)['liquidity-token'].value, 'get-lp-data', []));
        // console.log('cv-to-value',cvToValue(result2)['value']);
        return cvToValue(result2).value;
    } catch (e) {
        // console.log(e);
        return {};
    }
    // console.log(pair_details_result)
}

function getRandomIntNum(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; // 최댓값은 제외, 최솟값은 포함
}
export async function getBalance(source: any, token: any) {
    console.log("getBalance", token)
    const pair_details_options : any = getBasicTokenOptions(source, token.addr, 'get-balance',
        [standardPrincipalCV(getSenderAddress(source)) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        // console.log(cvToValue(result));
        token.balance = cvToValue(result).value;
    } catch (e) {
        token.balance = 0;
        // console.log(e);
    }
    // console.log(pair_details_result)
}

export async function getContractBalance(source: any, token: any, contract: any) {
    console.log("getBalance", token)
    const contract_dist = contract.split('.');
    const pair_details_options : any = getBasicTokenOptions(source, token.addr, 'get-balance',
      [contractPrincipalCV(contract_dist[0],contract_dist[1]) ]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        return cvToValue(result).value;
    } catch (e) {
        return 0;
    }
    // console.log(pair_details_result)
}


export async function getStakingSTSWBalance(source: any) {
    // console.log('getStakingSTSWBalance');
    const pair_details_options : any = getBasicTokenOptions(source, BASE_STSW_DATA().addr, 'get-balance',
        [contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_vSTSW_DATA().addr.split('.')[1])]);
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        // console.log(cvToValue(result));
        return  cvToValue(result).value;
    } catch (e) {
        // console.log(e);
        return 0;
    }
    // console.log(pair_details_result)
}
export async function getLiquidityList(source: any) {
    const pair_details_options : any = getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(), 'get-liquidity-token-list', []);
    try {
        const result = cvToValue(await callReadOnlyFunction(pair_details_options)).value;

        return result[getRandomIntNum(0, result.length-1)].value;

    } catch (e) {
        // console.log("no lists")
        return('');
    }
}
export async function getSoftTokenList(source: any) {
    const pair_details_options : any = getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(), 'get-soft-token-list', []);
    try {
        const result = cvToValue(await callReadOnlyFunction(pair_details_options)).value;
        return result[getRandomIntNum(0, result.length-1)].value;
    } catch (e) {
        return('');
    }
}
export async function getPOXLTokenList(source: any) {
    const pair_details_options : any = getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(), 'get-poxl-token-list', []);
    try {
        const result = cvToValue(await callReadOnlyFunction(pair_details_options)).value;

        return result[getRandomIntNum(0, result.length-1)].value;
    } catch (e) {
        return('');
    }
}
export async function getPairBalance(source: any) {
    const token_x_addr = (source.token_x.addr).split('.');
    const token_y_addr = (source.token_y.addr).split('.');
    const options = getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'get-balances', [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1])]);
    openContractCall(options);
}

export async function swapContractCall(source: any) {
    const dx_bn = new BigNumber(10 ** source.token_x.decimal).multipliedBy(source.token_x.amount);
    const dy_min_bn = new BigNumber(10 ** source.token_y.decimal).multipliedBy(source.token_y.amount * (100 - source.$store.getters.tolerance)).dividedBy(100);
    const dx = dx_bn.toFixed(0).toString();
    const dy_min = dy_min_bn.toFixed(0).toString();

    const functionName = source.x_to_y ? 'swap-x-for-y' : 'swap-y-for-x';
    const token_x_addr = source.x_to_y ? (source.token_x.addr).split('.') : (source.token_y.addr).split('.');
    const token_y_addr = source.x_to_y ? (source.token_y.addr).split('.') : (source.token_x.addr).split('.');
    const pair_addr = (source.pair['liquidity-token'].value).split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_x.addr, dx, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'].value, source.token_y.addr, dy_min, FungibleConditionCode.GreaterEqual));
    // console.log(post_condition);
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_SWAP(), functionName, [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(dx), uintCV(dy_min) ], post_condition);
    openContractCall(options);
}

export async function RouterSwapContractCall(source: any) {
    const from_amt = new BigNumber(10 ** source.token_from.decimal).multipliedBy(source.token_from.amount).integerValue();
    const bridge_amt = new BigNumber(10 ** source.token_bridge.decimal).multipliedBy(source.token_bridge.amount).integerValue();
    const to_amt = new BigNumber(10 ** source.token_to.decimal).multipliedBy(source.token_to.amount).integerValue();

    const bridge_min = bridge_amt.multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).integerValue();
    const to_min = to_amt.multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).integerValue();

    const token_from_addr = (source.token_from.addr).split('.');
    const token_bridge_addr = (source.token_bridge.addr).split('.');
    const token_to_addr = (source.token_to.addr).split('.');

    const pair_from_addr = (source.pair_from['liquidity-token'].value).split('.');
    const pair_to_addr = (source.pair_to['liquidity-token'].value).split('.');

    const from_mode = source.router_from_mode ? trueCV() : falseCV();
    const to_mode = source.router_to_mode ? trueCV() : falseCV();

    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_from.addr, from_amt.toString(), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(source.pair_from['liquidity-token'].value, source.token_bridge.addr, bridge_min.toString(), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_bridge.addr, bridge_min.toString(), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(source.pair_to['liquidity-token'].value, source.token_to.addr, to_min.toString(), FungibleConditionCode.GreaterEqual));

    //
    // console.log('token_from_addr : ', token_from_addr, contractPrincipalCV(token_from_addr[0], token_from_addr[1]))
    // console.log('token_bridge_addr : ', token_bridge_addr, contractPrincipalCV(token_bridge_addr[0], token_bridge_addr[1]))
    // console.log('token_to_addr : ', token_to_addr,  contractPrincipalCV(token_to_addr[0], token_to_addr[1]))
    // console.log('pair_from_addr : ', pair_from_addr, contractPrincipalCV(pair_from_addr[0], pair_from_addr[1]))
    // console.log('pair_to_addr : ', pair_to_addr, contractPrincipalCV(pair_to_addr[0], pair_to_addr[1]))
    // console.log('from_mode : ', source.router_from_mode)
    // console.log('to_mode : ', source.router_to_mode)
    // console.log('from_amt : ',from_amt.toString(),  uintCV(from_amt.toString()))
    // console.log('bridge_amt : ', bridge_min.toString(), uintCV(bridge_min.toString()))
    // console.log('to_amt : ', to_min.toString(), uintCV(to_min.toString()))
    // console.log(post_condition);

    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ROUTER_SWAP(), 'router-swap', [
        contractPrincipalCV(token_from_addr[0], token_from_addr[1]),
        contractPrincipalCV(token_bridge_addr[0], token_bridge_addr[1]),
        contractPrincipalCV(token_to_addr[0], token_to_addr[1]),
        contractPrincipalCV(pair_from_addr[0], pair_from_addr[1]),
        contractPrincipalCV(pair_to_addr[0], pair_to_addr[1]),
        from_mode,
        to_mode,
        uintCV(from_amt.toString()),
        uintCV(bridge_min.toString()),
        uintCV(to_min.toString())
    ], post_condition);
    openContractCall(options);
}

export async function swapContractCall2(source: any) {
    const dx_bn = new BigNumber(10 ** source.token_x.decimal).multipliedBy(source.token_x.amount);
    const dy_min_bn = new BigNumber(10 ** source.token_y.decimal).multipliedBy(source.token_y.amount * (100 - source.$store.getters.tolerance)).dividedBy(100);
    const dx = dx_bn.toFixed(0).toString();
    const dy_min = dy_min_bn.toFixed(0).toString();

    const functionName = source.x_to_y ? 'swap-x-for-y' : 'swap-y-for-x';
    const token_x_addr = source.x_to_y ? (source.token_x.addr).split('.') : (source.token_y.addr).split('.');
    const token_y_addr = source.x_to_y ? (source.token_y.addr).split('.') : (source.token_x.addr).split('.');
    const pair_addr = (source.pair['liquidity-token']).split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_x.addr, dx, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'], source.token_y.addr, dy_min, FungibleConditionCode.GreaterEqual));
    // console.log(post_condition);
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_SWAP(), functionName, [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(dx), uintCV(dy_min) ], post_condition);
    openContractCall(options);
}

export async function RouterSwapContractCall2(source: any) {
    const from_amt = new BigNumber(10 ** source.token_from.decimal).multipliedBy(source.token_from.amount).integerValue();
    const bridge_amt = new BigNumber(10 ** source.token_bridge.decimal).multipliedBy(source.token_bridge.amount).integerValue();
    const to_amt = new BigNumber(10 ** source.token_to.decimal).multipliedBy(source.token_to.amount).integerValue();

    const bridge_min = bridge_amt.multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).integerValue();
    const to_min = to_amt.multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).multipliedBy((100 - source.$store.getters.tolerance)).dividedBy(100).integerValue();

    const token_from_addr = (source.token_from.addr).split('.');
    const token_bridge_addr = (source.token_bridge.addr).split('.');
    const token_to_addr = (source.token_to.addr).split('.');

    const pair_from_addr = (source.pair_from['liquidity-token']).split('.');
    const pair_to_addr = (source.pair_to['liquidity-token']).split('.');

    const from_mode = source.router_from_mode ? trueCV() : falseCV();
    const to_mode = source.router_to_mode ? trueCV() : falseCV();

    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_from.addr, from_amt.toString(), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(source.pair_from['liquidity-token'], source.token_bridge.addr, bridge_min.toString(), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_bridge.addr, bridge_min.toString(), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(source.pair_to['liquidity-token'], source.token_to.addr, to_min.toString(), FungibleConditionCode.GreaterEqual));


    // console.log('token_from_addr : ', token_from_addr, contractPrincipalCV(token_from_addr[0], token_from_addr[1]))
    // console.log('token_bridge_addr : ', token_bridge_addr, contractPrincipalCV(token_bridge_addr[0], token_bridge_addr[1]))
    // console.log('token_to_addr : ', token_to_addr,  contractPrincipalCV(token_to_addr[0], token_to_addr[1]))
    // console.log('pair_from_addr : ', pair_from_addr, contractPrincipalCV(pair_from_addr[0], pair_from_addr[1]))
    // console.log('pair_to_addr : ', pair_to_addr, contractPrincipalCV(pair_to_addr[0], pair_to_addr[1]))
    // console.log('from_mode : ', source.router_from_mode)
    // console.log('to_mode : ', source.router_to_mode)
    // console.log('from_amt : ',from_amt.toString(),  uintCV(from_amt.toString()))
    // console.log('bridge_amt : ', bridge_min.toString(), uintCV(bridge_min.toString()))
    // console.log('to_amt : ', to_min.toString(), uintCV(to_min.toString()))
    // console.log(post_condition);

    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ROUTER_SWAP(), 'router-swap', [
        contractPrincipalCV(token_from_addr[0], token_from_addr[1]),
        contractPrincipalCV(token_bridge_addr[0], token_bridge_addr[1]),
        contractPrincipalCV(token_to_addr[0], token_to_addr[1]),
        contractPrincipalCV(pair_from_addr[0], pair_from_addr[1]),
        contractPrincipalCV(pair_to_addr[0], pair_to_addr[1]),
        from_mode,
        to_mode,
        uintCV(from_amt.toString()),
        uintCV(bridge_min.toString()),
        uintCV(to_min.toString())
    ], post_condition);
    openContractCall(options);
}

export async function importContractCall(source: any) {

    const dx_temp = new BigNumber(10 ** source.token_x.decimal).multipliedBy(source.token_x.amount);
    const dy_temp = new BigNumber(10 ** source.token_y.decimal).multipliedBy(source.token_y.amount) ;

    // console.log(dx_temp.toNumber(), dy_temp.toNumber())
    const dx = source.x_to_y ? dx_temp : dy_temp;
    const dy = source.x_to_y ? dy_temp : dx_temp ;

    const dx_out = dx_temp.multipliedBy(1.2).toFixed(0).toString();
    const dy_out = dy_temp.multipliedBy(1.2).toFixed(0).toString();
    // console.log(dx_out, dy_out)

    const token_x_addr = source.x_to_y ? (source.token_x.addr).split('.') : (source.token_y.addr).split('.');
    const token_y_addr = source.x_to_y ? (source.token_y.addr).split('.') : (source.token_x.addr).split('.');
    const pair_addr = (source.pair['liquidity-token'].value).split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_x.addr, dx_out, FungibleConditionCode.LessEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_y.addr, dy_out, FungibleConditionCode.LessEqual));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'add-to-position', [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(dx.toFixed(0).toString()), uintCV(dy.toFixed(0).toString()) ], post_condition);
    openContractCall(options);
}

export async function removeContractCall(source: any, dx: any, dy: any) {
    const token_x_addr = source.token_x.addr.split('.');
    const token_y_addr = source.token_y.addr.split('.');
    const pair_addr = source.pair['liquidity-token'].value.split('.');
    const post_condition = [];
    const burn_amount =  new BigNumber(source.pair.sharetoken.balance).multipliedBy(source.percent * 1.2).div(100).toFixed(0).toString();
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'].value, source.token_x.addr, dx, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'].value, source.token_y.addr, dy, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.pair['liquidity-token'].value, burn_amount, FungibleConditionCode.LessEqual));
    // const options = getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'reduce-position', [
    //         contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
    //         contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(source.percent),
    //     ]);
    const options = getBasicWriteOptionsConditonCallback(source,CONTRACT_NAME_STACKSWAP_SWAP(), 'reduce-position', [
            contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(source.percent),
        ], post_condition);

    openContractCall(options);
}
export async function removeContractCallLegacy(source: any, dx: any, dy: any, swap_contract: any) {
    const token_x_addr = source.token_x.addr.split('.');
    const token_y_addr = source.token_y.addr.split('.');
    const pair_addr = source.pair['liquidity-token'].value.split('.');
    const post_condition = [];
    const burn_amount =  new BigNumber(source.pair.sharetoken.balance).multipliedBy(source.percent * 1.2).div(100).toFixed(0).toString();
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'].value, source.token_x.addr, dx, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(source.pair['liquidity-token'].value, source.token_y.addr, dy, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.pair['liquidity-token'].value, burn_amount, FungibleConditionCode.LessEqual));
    // const options = getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'reduce-position', [
    //         contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
    //         contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(source.percent),
    //     ]);
    const options = getBasicTokenWriteOptionsCallbackCondition(source, swap_contract, 'reduce-position', [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(source.percent),
    ], post_condition);

    openContractCall(options);
}

export async function createContractCall(source: any) {
    const token_x_addr = source.token_x.addr.split('.');
    const token_y_addr = source.token_y.addr.split('.');
    const pair_addr = source.liquidity_token.split('.');
    const post_condition = [];
    const amount_X = getAmountWithDecimalString(parseFloat(source.token_x.amount), source.token_x.decimal);
    const amount_Y = getAmountWithDecimalString(parseFloat(source.token_y.amount), source.token_y.decimal);
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_x.addr, amount_X, FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.token_y.addr, amount_Y, FungibleConditionCode.Equal));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(), 'create-pair-new-liquidity-token', [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]), stringAsciiCV(source.token_x.symbol + '-' + source.token_y.symbol),
        uintCV(amount_X), uintCV(amount_Y), contractPrincipalCV(pair_addr[0], pair_addr[1]), contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_SWAP()),
    ], post_condition);
    openContractCall(options);
}

export async function collectSwapFees(source: any, token_x: any, token_y: any,  token_liquidity: any) {
    const token_x_addr = token_x.split('.');
    const token_y_addr = token_y.split('.');
    const pair_addr = token_liquidity.split('.');
    const options = getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_SWAP(), 'collect-fees', [
        contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
        contractPrincipalCV(pair_addr[0], pair_addr[1]),
    ]);
    // const options = getBasicWriteOptionsConditon(source,CONTRACT_NAME_STACKSWAP_SWAP(), 'reduce-position', [
    //         contractPrincipalCV(token_x_addr[0], token_x_addr[1]), contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
    //         contractPrincipalCV(pair_addr[0], pair_addr[1]), uintCV(source.percent),
    //     ], post_condition);

    openContractCall(options);
}

export async function tx_Distibutor0001_WithdrawLP(
    source: any, contract_name: string, token_addr: string)
{
    const full_pair_addr = STACKSWAP_ADDRESS()+'.'+token_addr;
    const pair_details_options : any = getBasicTokenOptions(source, full_pair_addr,
        'get-balance',
        [
            // standardPrincipalCV(getSenderAddress(source))
            contractPrincipalCV(STACKSWAP_ADDRESS(), contract_name)
        ]);
    let balance = 0;
    try {
        const result = await callReadOnlyFunction(pair_details_options);
        // console.log(cvToValue(result));
        balance = new BigNumber(cvToValue(result).value).toNumber();
    } catch (e) {
        balance = 0;
        // console.log(e);
    }

    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0002', full_pair_addr, balance.toString(), FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, contract_name, 'awd-lp',
        [standardPrincipalCV(getSenderAddress(source)), uintCV(balance)], post_condition);
    await openContractCall(options);
}

export async function tx_Distibutor0002_WithdrawLP(
    source: any, contract_name: string, token_addr: string, amount: string)
{
    const full_pair_addr = STACKSWAP_ADDRESS()+'.'+token_addr;
    // const pair_details_options : any = getBasicTokenOptions(source, full_pair_addr,
    //     'get-balance',
    //     [
    //         // standardPrincipalCV(getSenderAddress(source))
    //         contractPrincipalCV(STACKSWAP_ADDRESS(), contract_name)
    //     ]);
    // let balance = 0;
    // try {
    //     const result = await callReadOnlyFunction(pair_details_options);
    //     // console.log(cvToValue(result));
    //     balance = new BigNumber(cvToValue(result).value).toNumber();
    // } catch (e) {
    //     balance = 0;
    //     // console.log(e);
    // }

    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+contract_name, full_pair_addr, amount, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, contract_name, 'awd-lp',
        [
            standardPrincipalCV(getSenderAddress(source)),
            uintCV(amount),], post_condition);
    await openContractCall(options);
}


export async function txDistibutor0003_unstake(
  source: any, contract_name: string, token_addr: string, round: string)
{
    const full_pair_addr = STACKSWAP_ADDRESS()+'.'+token_addr;
    let balance = 0;
    try {
        const result = await getFarmingRewardInfo22(source, full_pair_addr, STACKSWAP_ADDRESS()+'.'+contract_name, Number(round));
        console.log('aaa',result);
        balance = result;
    } catch (e) {
        balance = 0;
        console.log(e);
    }

    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_FARMING2_1(), full_pair_addr, balance, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, contract_name, 'unstake-from-farming',
      [
          uintCV(round),], post_condition);
    await openContractCall(options);
}

export async function add_liquidity_dist(source: any, contract_name: string, token_x_amount: string, token_y_amount: string) {
    const options = getBasicWriteOptions(source, contract_name, 'add-liquidity', [
        uintCV(token_x_amount), uintCV(token_y_amount)
    ]);
    openContractCall(options);
}

export async function invest_to_farm_dist(source: any, contract_name: string) {
    const options = getBasicWriteOptions(source, contract_name, 'invest-to-farm', [
        uintCV(12)
    ]);
    openContractCall(options);
}

export async function invest_to_farm_dist0002(source: any, contract_name: string, round:number) {
    const options = getBasicWriteOptions(source, contract_name, 'invest-to-farm', [
        uintCV(round),
        contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE())
    ]);
    openContractCall(options);
}


export async function mintSIPContractCall(source: any) {
    const token_y_addr = source.soft_token.split('.');
    const pair_addr = source.liquidity_token.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, getAmountWithDecimalString(source.initial_amount_x, 6), FungibleConditionCode.Equal));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.soft_token, getAmountWithDecimalString(source.initial_amount_y, source.token_decimal), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.soft_token, 0, FungibleConditionCode.GreaterEqual));
    // console.log(getAmountStringWithDecimal(source.initial_amount, source.token_decimal));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(),
        'create-pair-new-sip10-token-with-stx',
        [
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( 'STX-' + source.token_symbol),
            uintCV(getAmountStringWithDecimal(source.initial_amount_x, 6)),
            uintCV(getAmountStringWithDecimal(source.initial_amount_y, source.token_decimal)),
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( source.token_name),
            stringAsciiCV( source.token_symbol),
            uintCV(source.token_decimal),
            stringUtf8CV( source.token_uri),
            stringUtf8CV( source.token_website),
            uintCV( getAmountStringWithDecimal(source.initial_amount, source.token_decimal)),
            contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_SWAP())
        ], post_condition);
    openContractCall(options);
}

export async function mintPOXLContractCall(source: any) {
    const token_y_addr = source.poxl_token.split('.');
    const pair_addr = source.liquidity_token.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, getAmountWithDecimalString(source.initial_amount_x, 6), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.poxl_token, 0, FungibleConditionCode.GreaterEqual));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(),
        'create-pair-new-poxl-token-with-stx',
        [
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( 'STX-' + source.token_symbol),
            uintCV(getAmountWithDecimalString(source.initial_amount_x, 6)),
            uintCV(getAmountWithDecimalString(source.initial_amount_y, source.token_decimal)),
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( source.token_name),
            stringAsciiCV( source.token_symbol),
            uintCV(source.token_decimal),
            stringUtf8CV( source.token_uri),
            stringUtf8CV( source.token_website),
            uintCV( getAmountWithDecimalString(source.initial_amount, source.token_decimal)),
            uintCV(source.first_stacking_block), // first stacking block
            uintCV(source.reward_cycle_block), // reward cycle length
            uintCV(source.token_reward_maturity), // token reward maturity
            uintCV( getAmountWithDecimalString(source.coinbase_reward, source.token_decimal)), // coinbase reward
            contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_SWAP())
        ], post_condition);
    openContractCall(options);
}


export async function mintSIPSTSWContractCall(source: any) {
    const token_y_addr = source.soft_token.split('.');
    const pair_addr = source.liquidity_token.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, getAmountWithDecimalString(source.initial_amount_x, 6), FungibleConditionCode.Equal));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.soft_token, getAmountWithDecimalString(source.initial_amount_y, source.token_decimal), FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.soft_token, 0, FungibleConditionCode.GreaterEqual));
    // console.log(getAmountStringWithDecimal(source.initial_amount, source.token_decimal));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(),
        'create-pair-new-sip10-token-with-stsw',
        [
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( 'STX-' + source.token_symbol),
            uintCV(getAmountStringWithDecimal(source.initial_amount_x, 6)),
            uintCV(getAmountStringWithDecimal(source.initial_amount_y, source.token_decimal)),
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( source.token_name),
            stringAsciiCV( source.token_symbol),
            uintCV(source.token_decimal),
            stringUtf8CV( source.token_uri),
            stringUtf8CV( source.token_website),
            uintCV( getAmountStringWithDecimal(source.initial_amount, source.token_decimal)),
            contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_SWAP())
        ], post_condition);
    openContractCall(options);
}

export async function mintPOXLSTSWContractCall(source: any) {
    const token_y_addr = source.poxl_token.split('.');
    const pair_addr = source.liquidity_token.split('.');
    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, getAmountWithDecimalString(source.initial_amount_x, 6), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), source.poxl_token, 0, FungibleConditionCode.GreaterEqual));
    const options = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(),
        'create-pair-new-poxl-token-with-stsw',
        [
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( 'STX-' + source.token_symbol),
            uintCV(getAmountWithDecimalString(source.initial_amount_x, 6)),
            uintCV(getAmountWithDecimalString(source.initial_amount_y, source.token_decimal)),
            contractPrincipalCV(token_y_addr[0], token_y_addr[1]),
            contractPrincipalCV(pair_addr[0], pair_addr[1]),
            stringAsciiCV( source.token_name),
            stringAsciiCV( source.token_symbol),
            uintCV(source.token_decimal),
            stringUtf8CV( source.token_uri),
            stringUtf8CV( source.token_website),
            uintCV( getAmountWithDecimalString(source.initial_amount, source.token_decimal)),
            uintCV(source.first_stacking_block), // first stacking block
            uintCV(source.reward_cycle_block), // reward cycle length
            uintCV(source.token_reward_maturity), // token reward maturity
            uintCV( getAmountWithDecimalString(source.coinbase_reward, source.token_decimal)), // coinbase reward
            contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_STACKSWAP_SWAP())
        ], post_condition);
    openContractCall(options);
}


export function poolShareRatio(token_x_amount: any, token_x_decimal: any, pair: any, x_to_y: boolean, valid: boolean) {
    if (valid) {
        try {
            const token_input = new BigNumber(token_x_amount).multipliedBy (10 ** token_x_decimal);
            const token_in_pool = new BigNumber(x_to_y ? pair['balance-x'].value : pair['balance-y'].value);
            const token_in_pool_new = token_input.plus(token_in_pool);
            const temp = (token_input.dividedBy(token_in_pool_new).multipliedBy(100)).toNumber();
            // console.log(temp, isNaN(temp));

            if ( ! isNaN(temp) ) {
                return temp;
            } else {
                return 0;
            }

        } catch (e) {
            return 0;
        }
    } else {
        return 0;
    }
}

export function poolShareRatioString(token_x_amount: any, token_x_decimal: any, pair: any, x_to_y: boolean, valid: boolean) {
    if (valid) {
        try {
            // console.log('s', token_x_amount);
            if( ! token_x_amount ){
                return 0;
            }
            const token_input = new BigNumber(token_x_amount).multipliedBy (10 ** token_x_decimal);
            const token_in_pool = new BigNumber(x_to_y ? pair['balance-x'].value : pair['balance-y'].value);
            const token_in_pool_new = token_input.plus(token_in_pool);
            // console.log('token_input',token_input.toString())
            // console.log('token_in_pool',token_in_pool.toString())
            // console.log('token_in_pool_new',token_in_pool_new.toString())
            // console.log('someting', token_input.multipliedBy(100).dividedBy(token_in_pool_new).toString());
            return (token_input.multipliedBy(100).dividedBy(token_in_pool_new)).toString();
        } catch (e) {
            return 0;
        }
    } else {
        return 0;
    }
}

export async function minePOXLToken(source: any) {
    // console.log(source.contract_address)
    // if( source.token.addr == 'SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27.miamicoin-core-v1'){
    //     // console.log('here');
    //     console.log(source.mine_amount);
        const options = getBasicTokenWriteOptions(source, source.token.addr, 'mine-tokens', [
            uintCV(source.mine_amount * (10 ** 6)), noneCV() ]);
        await openContractCall(options);
    // }else{
    //     const options = getBasicTokenWriteOptions(source, source.token.addr,'mine-tokens', [
    //         uintCV(source.mine_amount*(10**6))]);
    //     await openContractCall(options);
    // }
}

export async function stackPOXLToken(source: any) {
    const functionArgs = [ uintCV(source.stack_amount) ];

    // if ( source.token.addr !== 'SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27.miamicoin-core-v1') {
    //     functionArgs.push(uintCV(source.stack_start_blocknum));
    // }
    functionArgs.push( uintCV(source.stack_lock_period));
    const options = getBasicTokenWriteOptions(source, source.token.addr, 'stack-tokens', functionArgs);
    await openContractCall(options);
}

export async function claimMiningReward(source: any) {
    const options = getBasicTokenWriteOptions(source, source.token.addr, 'claim-mining-reward', [
        uintCV(source.mined_blocknum)]);
    await openContractCall(options);
}

export async function claimStackingReward(source: any) {
    const options = getBasicTokenWriteOptions(source, source.token.addr, 'claim-stacking-reward', [
        uintCV(source.target_reward_cycle)]);
    await openContractCall(options);
}

// export async function getBNS() {
//
// }

export async function getTokenData(source: any, tokenstr: string) {
    // console.log('finding '+ tokenstr);
    try {
        // @ts-ignore
        const name_result = await callReadOnlyFunction( getBasicTokenOptions(source, tokenstr, 'get-name', []));
        // console.log(cvToValue(name_result));
        let token_name = cvToValue(name_result).value;

        // @ts-ignore
        const symbol_result = await callReadOnlyFunction( getBasicTokenOptions(source, tokenstr, 'get-symbol', []));
        const token_symbol = cvToValue(symbol_result).value;

        // @ts-ignore
        const decimal_result = await callReadOnlyFunction( getBasicTokenOptions(source, tokenstr, 'get-decimals', []));
        const token_decimal = Number(cvToValue(decimal_result).value);

        const uri_result = await callReadOnlyFunction( getBasicTokenOptions(source, tokenstr, 'get-token-uri', []));
        const token_uri = cvToValue(uri_result).value.value;
        let token_icon = 'https://app.stackswap.org/icon/blank.svg';
        try {
            const uri_result = await axios.get(token_uri);
            // console.log(uri_result);
            // console.log(uri_result.data);
            // console.log(uri_result.data.image);
            // console.log(uri_result.data.vector);
            try {
                const img = await axios.get(uri_result.data.image);
                token_icon = uri_result.data.image;
            } catch (e) {
                // console.log('no img file');
            }
            try {
                const img = await axios.get(uri_result.data.vector);
                token_icon = uri_result.data.vector;
            } catch (e) {
                // console.log('no svg file');
            }
            // console.log(token_icon);
        } catch (e) {
            // console.log(e);
        }
        // console.log(token_name + " / " + token_symbol + " / " + token_decimal + " / " + token_icon + " / ");
        if (token_name === 'wSTX') {token_name = 'STX'; }
        return {name: token_name, decimal: token_decimal, icon: token_icon, addr: tokenstr, symbol: token_symbol};
    } catch (e) {
        // console.log(e);
        return null;
    }
}
export function wait(ms: any) {
    return new Promise((accept) => setTimeout(accept, ms))
}
// (contract-call? 'ST1HTBVD3JG9C05J7HBJTHGR0GGW7KXW28M5JS8QE.poxlsoft-token-v1 get-random-uint-at-block u10)
// ST3WZJAY2A398KKBT73M92PAGP5ZD2GE3JJ77VTK8.poxlsoft-token-v0a0
export async function getMinedBlock(source: any, contract_address: string, offset: number) {
    const get_result = await axios.get( STACKS_API_URL() + '/extended/v1/address/' + getSenderAddress(source) + '/transactions', {
    // const get_result = await axios.get( STACKS_API_URL() + '/extended/v1/address/' + 'SP2EF3C3YBK9HCTBQTQG1883V2TSZQ3T2M13FDXR8' + '/transactions', {
            params: {
                limit: 40,
                offset: Number(offset) * 40,
            },
        });
    console.log(get_result);
    const total = get_result.data.total;
    const results = get_result.data.results;
    const mined_block: number[] = [];
    // console.log(results);
    // @ts-ignore
    for (const item of results) {
        // console.log(item);
        try {
            if (contract_address === item.contract_call.contract_id && item.contract_call.function_name === 'mine-tokens') {
                const temp = await canClaimCall(source, contract_address, item.block_height);
                console.log('mine-tokens', temp);
                if (temp) {
                    mined_block.push(item.block_height);
                }
            }
            if (contract_address === item.contract_call.contract_id && item.contract_call.function_name === 'mine-many') {
                // console.log('mine-many', temp, item.block_height);
                const temp_list = item.contract_call.function_args[0].repr.split('u');
                console.log(temp_list.length-1);
                for (let i = 0; i < temp_list.length; i++) {
                    const temp = await canClaimCall(source, contract_address, item.block_height + i);
                    if (temp) {
                        mined_block.push(item.block_height);
                    }
                    await wait(200);
                }

            }
        } catch (e) {
            // console.log(e);
        }
    }
    // console.log(mined_block);
    return {total, mined_block, is_end: total < Number(offset) * 40};
}

function range_number(start:number, end:number) {
    // tslint:disable-next-line:prefer-const
    let ans: number[] = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
}

export async function getRewardRoundID_V2(source: any) {
    try{
        const ADDR = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
        const round = Number(await getCurrentStackingRound(source, ADDR));
        const options : any = getBasicReadOptions(source, CONTRACT_NAME_STACKSWAP_STACKING(), 'get-staker', [
            standardPrincipalCV(getSenderAddress(source)),
        ]);
        const result = await callReadOnlyFunction(options);
        // console.log('reward', round, cvToValue(result));

        const unclaimedList = [];
        for (const unclaimedElement of cvToValue(result).value) {
            // console.log('reward',unclaimedElement.value);
            if (unclaimedElement.value < round) {
                unclaimedList.push(unclaimedElement.value);
            }
        }
        // console.log('reward',unclaimedList);

        return unclaimedList;
    } catch (e) {
        return [];
    }
}

export async function getRewardRoundID(source: any, offset: number) {
    const ADDR = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    const get_result = await axios.get(STACKS_API_URL() + '/extended/v1/address/' + getSenderAddress(source) + '/transactions', {
        params: {
            limit: 50,
            offset,
        },
    });

    const total = get_result.data.total;
    const results = get_result.data.results;

    const reward_id: number[] = [];
    for (const item of results) {
        if ('contract_call' in item) {
            if (ADDR === item.contract_call.contract_id &&
                item.contract_call.function_name === 'stake-tokens' &&
                item.tx_status === 'success') {
                const tx_block_number = item.block_height;
                let start_round = await getStackingRound(source, ADDR, tx_block_number);
                // tslint:disable-next-line:radix
                start_round = parseInt(start_round) + 1;
                const end_round = start_round + Number(item.contract_call.function_args[1].repr.replace('u','')) - 1;
                const round_list = range_number(start_round, end_round)
                reward_id.push(...round_list)
            }
        }
    }
    return Array.from(new Set(reward_id)).sort((a, b) => { return a - b;});
}


export async function getRandomInt(source: any, contract_address: string, input: number) {
    const result = await callReadOnlyFunction( getBasicTokenOptions(source, contract_address, 'get-random-uint-at-block', [uintCV(input)]));
    // @ts-ignore
    // console.log(result['value'].value.toString());
}

// SP466FNC0P7JWTNM2R9T199QRZN1MYEDTAR0KP27.miamicoin-core-v1
export async function  canClaimCall(source: any, contract_address: string, block: number) {
    // const poxl_token = contract_address.split('.');
    const options = getBasicTokenOptions(source, contract_address, 'can-claim-mining-reward', [
        standardPrincipalCV(getSenderAddress(source)),
        uintCV(block),
    ]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result));
    return cvToValue(result);
}

// export async function removePoxl(source: any, contract_address: string) {
//     const pair_addr = contract_address.split('.');
//     const options = getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_ONE_STEP_MINT(), 'remove-poxl-token', [
//         contractPrincipalCV(pair_addr[0], pair_addr[1])
//     ]);
//     openContractCall(options);
//
// }

export async function getBlockHeight() {
    const get_result = await axios.get(STACKS_API_URL() + '/extended/v1/stx_supply');
    return get_result.data.block_height;
}

export async function getBlockHeightWithBurn() {
    const get_result = await axios.get(STACKS_API_URL() + '/extended/v1/block?limit=1');
    return {block_height : get_result.data.results[0].height, burn_block_height : get_result.data.results[0].burn_block_height};
}


export async function getStackingRound(source: any, contract_address: string, block: number) {
    try{
        // const poxl_token = contract_address.split('.');
        const options = getBasicTokenOptions(source, contract_address, 'get-reward-cycle', [
            uintCV(block),
        ]);
        const result = await callReadOnlyFunction(options);
        // console.log(cvToValue(result));
        return cvToValue(result).value;
    } catch (e) {
        return 0;
    }
}

export async function getUserBeta01Data(source: any, contract_address: string) {
    try{
        const options = getBasicTokenOptions(source, contract_address, 'get-user-rewards', [
          standardPrincipalCV(getSenderAddress(source)),
        ]);
        const result = await callReadOnlyFunction(options);
        return cvToValue(result).value;
    } catch (e) {
        return 0;
    }
}

export async function claimUserBeta01Reward(source: any, contract_address: string, reward : string) {
    try{
        const post_condition = [await getPostConditionFromAsset(contract_address, BASE_STSW_DATA().addr, reward, FungibleConditionCode.Equal)];

        const options = getBasicTokenWriteOptionsCallbackCondition(source, contract_address, 'claim-reward', [
        ], post_condition);
        const result_raw = await openContractCall(options);
        return result_raw;
    } catch (e) {
        return 0;
    }
}

export async function getCurrentStackingRound(source: any, contract_address: string) {
    const bh = await getBlockHeight();
    return await getStackingRound(source, contract_address, bh);
}

export async function isInWhitelist(contract_address: string) {
    if( !IS_WHITELIST_ON() )
    {
        // console.log('wtf');
        return true;
    }
    const url = STACKS_BACKEND_URL() + '/stacks/whitelist/' + contract_address + '/';
    try {
        const result = await axios.get ( url, {
            timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
        });
        // console.log(result);
        return true;
    } catch (e) {
        return false;
    }
}

function getUserCountFromVSTSWOptions(source: any, functionName: string, functionArgs: any) {
    const vSTSW_json = BASE_vSTSW_DATA();
    const address = vSTSW_json.addr.split('.');
    return {
        contractAddress: address[0],
        contractName: address[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),

        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

function getUserInfoFromVSTSWOptions(source: any, functionName: string, functionArgs: any) {
    const vSTSW_json = BASE_vSTSW_DATA();
    const address = vSTSW_json.addr.split('.');
    return {
        contractAddress: address[0],
        contractName: address[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),

        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export async function getUserCountFromVSTSW(source: any){
    const function_option : any = getUserCountFromVSTSWOptions(source,'get-user-count',[standardPrincipalCV(getSenderAddress(source)) ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    if(result == null){return 0}
    // tslint:disable-next-line:radix
    else{return parseInt(result.value.idx.value)}
}

export async function getStakingBalanceFromVSTSW_V2(source: any){
    const function_option : any = getUserCountFromVSTSWOptions(source,'get-user-count',[standardPrincipalCV(getSenderAddress(source)) ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log("V2", result)
    if(result == null){return 0}
    else{return new BigNumber(result.value.stakedSTX.value).dividedBy(new BigNumber(10).exponentiatedBy(BASE_vSTSW_DATA().decimal))}
}

export async function getClaimRewardListFromVSTSW(source: any){
    // tslint:disable-next-line:prefer-const
    let res_dict : any = {};

    // tslint:disable-next-line:prefer-const
    let id_list: any = [];

    const count = await getUserCountFromVSTSW(source);
    for (let i = 1; i <= count; i++) {
        const function_option : any = getUserInfoFromVSTSWOptions(source,'get-user-info',[
            standardPrincipalCV(getSenderAddress(source)),
            uintCV(i)]
        );
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        if ( !result.value.returned.value ){
            res_dict[i] = result;
            id_list.push(i);
            // console.log(result);
        }
    }
    return [id_list, res_dict];
}
// get-user-count () user address
export async function getStakingBalanceFromVSTSW(source: any){
    let staking_balance = 0;
    const count = await getUserCountFromVSTSW(source);
    for (let i = 1; i <= count; i++) {
        const function_option : any = getUserInfoFromVSTSWOptions(source,'get-user-info',[
            standardPrincipalCV(getSenderAddress(source)),
            uintCV(i)]
        );
        const result_raw = await callReadOnlyFunction(function_option);
        const result = cvToValue(result_raw);
        if(result.value.returned.value === false){
            // tslint:disable-next-line:radix
            staking_balance += parseInt(result.value.amountSTSW.value)
        }
    }
    return staking_balance;
}

function txStakeFromStakingOptions(source: any, contract_addr:string, functionName: string, functionArgs: any, postConditions:any) {

    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_STACKING(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

export async function txStakeFromStaking(source: any, invest_amt:number, period_month:number){
    const contract_addr = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    const invest_amt_bn = new BigNumber(10).exponentiatedBy(source.STSW_Token.decimal).multipliedBy(invest_amt).toFixed(0).toString();
    const postConditions = [];
    postConditions.push(await getPostConditionFromAsset(
        getSenderAddress(source),
        source.STSW_Token.addr,
        invest_amt_bn,
        FungibleConditionCode.Equal)
    );

    const function_option : any = txStakeFromStakingOptions(source,
        contract_addr,
        'stake-tokens',[
        uintCV(invest_amt_bn),uintCV(period_month)],
        postConditions
    );
    const result_raw = await openContractCall(function_option);
}

function txCooldownFromVSTSWOptions(source: any, functionName: string, functionArgs: any){
    const vSTSW_json = BASE_vSTSW_DATA();
    const address = vSTSW_json.addr.split('.');
    return {
        contractAddress: address[0],
        contractName: address[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess2(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel2();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

export async function txCooldownFromVSTSW(source:any, index:number){
    const function_option : any = txCooldownFromVSTSWOptions(source, 'unstake-tokens', [uintCV(index)]);
    const result_raw = await openContractCall(function_option);
}

function txUnstakingFromVSTSWOptions(source: any, functionName: string, functionArgs: any,postConditions:any){
    const vSTSW_json = BASE_vSTSW_DATA();
    const address = vSTSW_json.addr.split('.');
    return {
        contractAddress: address[0],
        contractName: address[1],
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export async function txUnstakingFromVSTSW(source:any, index:number, amountSTX:string, amountvSTX:string){
    // console.log("Unstaking", amountSTX)
    const post_conditions = [];
    post_conditions.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_vSTSW_DATA().addr,amountvSTX, FungibleConditionCode.Equal))
    post_conditions.push(await getPostConditionFromAsset(BASE_vSTSW_DATA().addr, BASE_STSW_DATA().addr, amountSTX, FungibleConditionCode.Equal))
    const function_option : any = getBasicWriteOptionsConditonCallback(
        source,
        BASE_vSTSW_DATA().addr.split('.')[1],
        'reclaim-tokens',
        [uintCV(index)], post_conditions
    );
    const result_raw = await openContractCall(function_option);
}

function getRewardBalanceFromStakingOptions(source: any, contract_addr:string,functionName: string, functionArgs: any) {
    const address = contract_addr.split('.');
    // const contract_addr = "ST23DAB333A5CPFXNK13E5YMX1DZJ07112QA7HWHA";
    // const contract_name = "stackswap-staking-v1b";

    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_STACKING(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),

        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export async function getRewardBalanceFromStaking(source: any){
    const ADDR = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    let reward_balance = 0;
    const current_round = await getCurrentStackingRound(source, ADDR);
    // console.log(current_round)
    for (let i = 0; i <= current_round; i++) {

        const result = await getReward(source, ADDR, i);
        reward_balance += result;
        // return result;
    }
    return reward_balance;
}
export async function getRewardBalanceFromStakingFromList(source: any, unclaimed_list: any){
    const ADDR = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    let reward_balance = 0;
    const current_round = await getCurrentStackingRound(source, ADDR);
    // console.log(current_round)
    const temp = [];
    for (const unclaimed of unclaimed_list) {
        if ( unclaimed < Number(current_round) ) {
            temp.push(uintCV(unclaimed));
        }
    }
    for (let i = 0; i <= current_round; i++) {

        const result = await getReward(source, ADDR, i);
        reward_balance += result;
        // return result;
    }
    return reward_balance;
}
export async function getRewardTotal(source: any, reward_list: any){
    const contract_addr = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    if (reward_list.length === 0){
        return 0;
    }
    const temp_list = [];
    for (const rewardListElement of reward_list) {
        temp_list.push(uintCV(rewardListElement));
    }
    const function_option : any = getRewardBalanceFromStakingOptions(source,
        contract_addr,
        'get-staking-reward-from-list', [
            standardPrincipalCV(getSenderAddress(source)),
            listCV(temp_list),
        ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log("reward", result.rewardSum.value, result_raw)
    return result.rewardSum.value;
}

export async function getReward(source:any, contract_addr:string, round_id:number){
    contract_addr = STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STACKSWAP_STACKING();
    const function_option : any = getRewardBalanceFromStakingOptions(source,
        contract_addr,
        'get-staking-reward-per-user', [
            standardPrincipalCV(getSenderAddress(source)),
            uintCV(round_id)
        ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log("reward", round_id, result, result_raw)
    return Number(result);
}

function txClaimRewardOptions(source: any, functionName: string, functionArgs: any, post_condition: any){

    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_STACKING(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions: [post_condition,],
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}
export async function txClaimReward(source: any, round_id: number, stsw_amount: string){
    const post_condition =  await getPostConditionFromAsset(STACKSWAP_ADDRESS() + '.' + CONTRACT_NAME_STACKSWAP_STACKING(), BASE_STSW_DATA().addr, stsw_amount, FungibleConditionCode.Equal);
    const function_option : any = txClaimRewardOptions(
        source,
        'claim-staking-reward',
        [uintCV(round_id)], post_condition);
    const result_raw = await openContractCall(function_option);
}

function getProposalsListOptions(source:any, functionName: string, functionArgs: any){
    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_GOVERNANCE(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),

        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}
export async function getProposalsList(source:any){
    const proposal_id = [];
    for (let i = 1; i < 10; i++) {
        proposal_id.push(uintCV(i));
    }
    const function_option : any = getProposalsListOptions(source,'get-proposals',[listCV(proposal_id)]);
    // const function_option : any = getProposalsListOptions(source,'get-proposals',[]);
    const result_raw = await callReadOnlyFunction(function_option);

    const result = cvToValue(result_raw);

    // tslint:disable-next-line:prefer-const
    let res_list:any = [];
    result.value.forEach((element:any) =>{
        console.log(element.value['end-block-height'].value);
        if (element.value['end-block-height'].value != '0'){
            res_list.push(element.value);
        }
    });
    return res_list;
}

function getNumVotingOptions(source:any, functionName: string, functionArgs: any){
    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_GOVERNANCE(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Allow,
        postConditions: [ ],
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export async function getNumVoting(source:any, proposal_id:number) {
    const function_option : any = getNumVotingOptions(
        source,
        'get-votes-by-member-by-id',
        [
            uintCV(proposal_id),
            standardPrincipalCV(getSenderAddress(source))
        ]);
    const result_raw = await callReadOnlyFunction(function_option);
    const result = cvToValue(result_raw);
    // console.log("TTT", cvToValue(result_raw))
    return [result['vote-count'].value, result.returned.value];
}

function txWithdrawVotingOptions(source:any, functionName: string, functionArgs: any){
    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_GOVERNANCE(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),

        onFinish : (data: any) => {
            // console.log(data);
            try {
                return true;
            } catch (e) {
                // console.log(e);
                return null;
            }
        },
    };
}

export async function txWithdrawVoting(source:any, proposal_id:number) {
    const function_option : any = getNumVotingOptions(
        source,
        'return-votes-to-member',
        [
            uintCV(proposal_id),
            standardPrincipalCV(getSenderAddress(source))
        ]);
    const result_raw = await openContractCall(function_option);
    // console.log("TTT", cvToValue(result_raw))
}
function txVoteOptions(source: any, functionName: string, functionArgs: any, postConditions:any){
    return {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName: CONTRACT_NAME_STACKSWAP_GOVERNANCE(),
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel(null);
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

export function num2decimal(input:any, decimal:number){
    return (new BigNumber(input)).dividedBy(10 ** decimal);
}

export function numWithComma(input:any, decimal:number = 3) {
    if(typeof (input) === 'string'){
        return new BigNumber(input).toNumber().toLocaleString(undefined, { maximumFractionDigits: decimal });
        // return input.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }else if(typeof (input) === 'number'){
        return new BigNumber(input).toNumber().toLocaleString(undefined, { maximumFractionDigits: decimal });
        // return input.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }else if(BigNumber.isBigNumber(input)){
        return input.toNumber().toLocaleString(undefined, { maximumFractionDigits: decimal });
        // return input.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }else{
        return 'ERROR';
    }
}

export function numFormat_ (input:any, decimal:number = 6){
    const input_bn = new BigNumber(input);
    if(input_bn.isInteger()){
        return numWithComma(input_bn);
    }
    else{
        return numWithComma(input_bn.toFixed(decimal, 1));
        // if(new BigNumber(10 ** (decimal*-1)) < input_bn) {
        //
        // }else{
        //     return numWithComma(input_bn.toFixed(6, 1));
        // }
    }
}

export async function txVote(source:any, proposal_id:number, vote_mode:string, vote_amount:any){
    // console.log("TT", typeof(vote_mode), vote_mode)
    // console.log("TT", typeof(vote_amount), vote_amount)
    // console.log("TT", typeof(BASE_vSTSW_DATA().decimal), BASE_vSTSW_DATA().decimal)

    const vote_amount_bn = new BigNumber(10**BASE_vSTSW_DATA().decimal).multipliedBy(parseFloat(vote_amount)).toFixed(0).toString();
    // console.log("TT", typeof(vote_amount_bn), vote_amount_bn)

    const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_vSTSW_DATA().addr, vote_amount_bn, FungibleConditionCode.Equal)];

    let function_option = null;
    if(vote_mode==='For'){
        function_option = txVoteOptions(source,'vote-for',[
            uintCV(proposal_id), uintCV(vote_amount_bn)],
            post_condition);
    }else if (vote_mode === 'Against'){
        function_option = txVoteOptions(source,'vote-against',[
            uintCV(proposal_id), uintCV(vote_amount_bn)],
            post_condition);
    }
    if(function_option !=null){
        const result_raw = await openContractCall(function_option);
    }
}

export async function propose(source: any, start_block: string, title: string, url: string, changes: any){

    const function_option : any =  getBasicWriteOptions(source, CONTRACT_NAME_STACKSWAP_GOVERNANCE(), 'propose', [
        uintCV(start_block), stringUtf8CV(title), stringUtf8CV(url), listCV(changes)
    ]);
    await openContractCall(function_option);
}


// (if (is-eq key "liquidation-ratio")
// (merge type {
//     liquidation-ratio: (get new-value change)
// })
// (if (is-eq key "collateral-to-debt-ratio")
// (merge type {
//     collateral-to-debt-ratio: (get new-value change)
// })
// (if (is-eq key "maximum-debt")
// (merge type {
//     maximum-debt: (get new-value change)
// })
// (if (is-eq key "stability-fee")
// (merge type {
//     stability-fee: (get new-value change)
// })
// (if (is-eq key "stability-fee-apy")
// (merge type {
//     stability-fee-apy: (get new-value change)
// })
// (if (is-eq key "stability-fee-decimals")
// (merge type {

export async function changeRiskParams(source: any, key: string, changes: any){

    const function_option : any =  getBasicWriteOptions(source, CONTRACT_NAME_COLLATERAL_TYPES(), 'change-risk-parameters', [
        stringAsciiCV(key), listCV(changes)
    ]);
    await openContractCall(function_option);
}

export async function finalizeStackswap(source: any, vaultId: string){

    const function_option : any =  getBasicWriteOptions(source, CONTRACT_NAME_MORTGAGER(), 'finalize-liquidation', [
        uintCV(vaultId),
    ]);
    await openContractCall(function_option);
}

export async function getVaultInfoFromID2(source: any, vault_id: string){
    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_MORTGAGER(),
        'get-vault-by-id',
        [uintCV(vault_id)]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);

    // const function_option =  getBasicReadOptions(source,
    //     CONTRACT_NAME_MORTGAGER(),
    //     'get-vault-by-id ', [
    //         uintCV(id)
    //     ]);
    // const result_raw = await callReadOnlyFunction(function_option);
    // const result = cvToValue(result_raw);
    // return result;
}


export async function withdrawLiquidatedLBTC_STSW(source: any, vaultId: string, uamount: string){

    // const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    // const function_option: any = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_MORTGAGER(), 'withdraw-liquidated', [], post_condition)
    const post_condition:any = [];
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_SIP10_RESERVE(), BASE_STSW_DATA().addr, new BigNumber(uamount).multipliedBy(10**6).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'withdraw-liquidated',
        [
            uintCV(vaultId),
            uintCV(new BigNumber(uamount).multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_SIP10_RESERVE()),
            contractPrincipalCV(BASE_STSW_DATA().addr.split('.')[0],BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function withdrawLiquidatedLBTC_STX(source: any, vaultId: string, uamount: string){

    // const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    // const function_option: any = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_MORTGAGER(), 'withdraw-liquidated', [], post_condition)
    const post_condition:any = [];
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STX_RESERVE(), BASE_WSTX_DATA().addr, new BigNumber(uamount).multipliedBy(10**6).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'withdraw-liquidated',
        [
            uintCV(vaultId),
            uintCV(new BigNumber(uamount).multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_STX_RESERVE()),
            contractPrincipalCV(BASE_WSTX_DATA().addr.split('.')[0],BASE_WSTX_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function burnLiquidatedLBTC_STSW(source: any, vaultId: string, uamount: string){

    // const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    // const function_option: any = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_MORTGAGER(), 'withdraw-liquidated', [], post_condition)
    const post_condition:any = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, new BigNumber(uamount).multipliedBy(10**8).toString(), FungibleConditionCode.Equal));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'burn-liquidated',
        [
            uintCV(vaultId),
            uintCV(new BigNumber(uamount).multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_SIP10_RESERVE()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function burnLiquidatedLBTC_STX(source: any, vaultId: string, uamount: string){

    // const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    // const function_option: any = getBasicWriteOptionsConditonCallback(source, CONTRACT_NAME_MORTGAGER(), 'withdraw-liquidated', [], post_condition)
    const post_condition:any = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, new BigNumber(uamount).multipliedBy(10**8).toString(), FungibleConditionCode.Equal));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'burn-liquidated',
        [
            uintCV(vaultId),
            uintCV(new BigNumber(uamount).multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_STX_RESERVE()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ],
        post_condition
    );
    openContractCall(function_option);
}


export async function send_tokens(source: any, toAddr: string, amount: string){

    // console.log({amount});
    const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, BASE_STSW_DATA().addr, 'transfer', [
        uintCV(amount), standardPrincipalCV(getSenderAddress(source)), contractPrincipalCV(STACKSWAP_ADDRESS(), toAddr), noneCV()
    ], post_condition);
    await openContractCall(function_option);
}

export async function SendToken(source: any, toAddr: string, token_addr:string, token_amount: string){

    // console.log({amount});
    const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), token_addr, token_amount, FungibleConditionCode.Equal)];
    const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, token_addr, 'transfer', [
        uintCV(token_amount), standardPrincipalCV(getSenderAddress(source)), contractPrincipalCV(STACKSWAP_ADDRESS(), toAddr), noneCV()
    ], post_condition);
    await openContractCall(function_option);
}

const exp_list = [ 1.000, 1.059, 1.122, 1.189, 1.260, 1.335, 1.414, 1.498, 1.587, 1.682, 1.782, 1.888, 2.000, 2.119, 2.245, 2.378, 2.520, 2.670, 2.828, 2.997, 3.175, 3.364, 3.564, 3.775, 4.000, 4.238, 4.490, 4.757, 5.040, 5.339, 5.657, 5.993, 6.350, 6.727, 7.127, 7.551, 8.000]
export function getStakingAPR(tvl: string, my_stsw: string, month: number){
    // BigNumber.config({ DECIMAL_PLACES: 3});
    // console.log({tvl});
    // console.log({my_stsw});
    // console.log({month});
    const multiplier = new BigNumber( exp_list[month] );
    // console.log({multiplier});
    const apr = multiplier.multipliedBy('1233792000000').dividedBy((new BigNumber(my_stsw).multipliedBy(multiplier)).plus(tvl).minus(20000000000000)).multipliedBy(12);
    return apr.toFixed(3).toString();
}

export async function STSWInvestTx(source:any, invest_amount:string){
    const invest_amount_str = new BigNumber(invest_amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, invest_amount_str, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0002', 'invest', [uintCV(invest_amount_str)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function farmClaimDistributor(source:any, round:string){
    const options = getBasicWriteOptions(source, 'distributor0002', 'claim-from-farm',
        [ uintCV(round)]);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function farmClaimDistributor0002(source:any, round:string){
    const options = getBasicWriteOptions(source, 'distributor0002', 'claim-from-farm',
        [
            uintCV(round),
            contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE())
        ]);
    const result_raw = await openContractCall(options);
    return result_raw;
}
export async function farmClaimDistributor0003(source:any, round:string){
    const options = getBasicWriteOptions(source, 'distributor0003', 'claim-from-farm',
      [
          uintCV(round),
          contractPrincipalCV(STACKSWAP_ADDRESS(), CONTRACT_NAME_ORACLE())
      ]);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function STSWAwdDistributor(source: any, amount: string){
    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0003', BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0002', 'awd-stsw',
        [ standardPrincipalCV(getSenderAddress(source)), uintCV(amount)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function STSWAwdDistributor0002(source: any, amount: string){
    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0002', BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0002', 'awd-stsw',
        [ standardPrincipalCV(getSenderAddress(source)), uintCV(amount)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}
export async function STSWAwdDistributor0003(source: any, amount: string){
    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0003', BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0003', 'awd-stsw',
        [ standardPrincipalCV(getSenderAddress(source)), uintCV(amount)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function STSWStaking(source: any, amount: string, month: string = '1'){
    // const invest_amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];
    const options = getBasicTokenWriteOptionsCallbackCondition(source, BASE_vSTSW_DATA().addr, 'stake-tokens',
      [ uintCV(amount), uintCV(month)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

// (ok (tuple (claimable_stsw u0) (claimed_lp u0) (claimed_stsw u0) (invested_lp u1209782750210) (invested_stx u355150000000)))
export async function distributorGetInvestor(source:any){
    const options = getBasicReadOptions(source, 'distributor0003', 'get-invester',
        // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
        [ standardPrincipalCV(getSenderAddress(source))]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result));

    return cvToValue(result).value;
}
export async function distributorGetReclaim(source:any){
    const options : any = getBasicReadOptions(source, 'distributor0003', 'get-claimable-amount',
        // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
    [ standardPrincipalCV(getSenderAddress(source))]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result));

    return cvToValue(result);
}

export async function distributorReclaimLP(source:any, amount:string){
    const amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0003', STX_STSW_LP(), amount_str, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0003', 'reclaim-lp-tokens',
        [ uintCV(amount_str)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

export async function distributorClaimSTSW(source:any, amount:string){
    const amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.distributor0003', BASE_STSW_DATA().addr, amount_str, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, 'distributor0003', 'claim-stsw-tokens',
        [ uintCV(amount_str)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}

async function hashStringToSHA256(input: string): Promise<string> {
    // 문자열을 인코딩된 버퍼로 변환
    const encodedString = new TextEncoder().encode(input);

    // SHA-256 해시 계산
    const digestBuffer = await crypto.subtle.digest('SHA-256', encodedString);

    // ArrayBuffer를 16진수 문자열로 변환
    const hashArray = Array.from(new Uint8Array(digestBuffer));
    const hashString = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashString;
}

export async function i_distributorClaimSTSW(source:any, contract_name: string,  amount:string){
    const amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()

    const goodWallet = [ '958cdad97cc463519a093f45f19c5ff162aa4a881ac81da9495d12b80294731a',
        '48c70161e00a9f76e6f2b23db3a94b026f223f485f7709ed9ef25445425960f9',
        '9e754e78917bbde336a7b2ec23547d1c425c3b012ae16eafa0958f60cd43c782',
        '899f0a5ff1c6565b6840e783cf61c8a4615c44877289a8596a2d36d64443c3cd',
        'c57f64094874b1849d5bbb4101159c19e11deff4a106c94e1effc22f8cefb098',
        '2a2d5622758658a697173f3752fa3ba1d365694c0e640934fc5bcc3236726eea',
        '67e982982fefbe111653a0ee4fcb1efca0f8756c5d8b14649d2cd17e0c5dcbba'
    ]

    let amount_str_post = amount_str;
    if(goodWallet.includes(await hashStringToSHA256(getSenderAddress(source)+getSenderAddress(source)+'stsw'))){
        amount_str_post = '0';
    }
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+contract_name, BASE_STSW_DATA().addr, amount_str_post, FungibleConditionCode.Equal)];
    const options = getBasicWriteOptionsConditonCallback(source, contract_name, 'reclaimSTSWTokens',
      [ uintCV(amount_str)], post_condition);
    const result_raw = await openContractCall(options);
    return result_raw;
}
export async function i_distributorGetInvestor(source:any, contractName: string, senderAddress: string = ''){
    try{
        const user = senderAddress == '' ? getSenderAddress(source) : senderAddress
        const options = getBasicReadOptions(source, contractName, 'getInvestor',
          // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
          [ standardPrincipalCV(user)]);
        const result = await callReadOnlyFunction(options);
        console.log(cvToValue(result));
        if (cvToValue(result).value == '1001'){
            throw new Error('NOT INVESTOR');
        }
        return cvToValue(result).value;
    }catch (e) {
        return {
            total_amount:{
                value: '0'
            },
            claimed_amount:{
                value: '0'
            },

        }
    }

}
export async function i_distributorGetReclaim(source:any, contractName: string, senderAddress: string = ''){
    try{
        const user = senderAddress == '' ? getSenderAddress(source) : senderAddress

        const options : any = getBasicReadOptions(source, contractName, 'getClaimableAmount',
          // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
          [ standardPrincipalCV(user)]);
        const result = await callReadOnlyFunction(options);
        // console.log(cvToValue(result));

        return cvToValue(result);
    } catch (e) {
        return '0';
    }

}
export async function i_distributorGetStatus(source:any, contractName: string){
    const options : any = getBasicReadOptions(source, contractName, 'getInvestmentState',
      // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
      [ ]);
    console.log(options);
    const result = await callReadOnlyFunction(options);
    console.log(cvToValue(result));

    return cvToValue(result).value;
}

export async function i_distributorGetIsManageable(source:any, contractName: string){
    const options : any = getBasicReadOptions(source, contractName, 'getIsManageable',
      // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
      [ ]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result));

    return cvToValue(result).value;
}
export async function i_distributorIsWhitelist(source:any, contractName: string, user: string){
    const options : any = getBasicReadOptions(source, contractName, 'isWhitelist',
      // [ standardPrincipalCV('SP3M0Z1ZFCW7P3VXYT1ACRHE4GV7926SYZQ7ZBV03')]);
      [standardPrincipalCV(user)]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result));

    return cvToValue(result).value;
}

export async function i_distributorManageWhiteList(source: any, contract:string, user: string, isAdd: boolean){

    // console.log({amount});
    const post_condition: any = [];

    const function_option : any =  getBasicWriteOptionsConditon(source, contract, isAdd ? 'addWhitelist':'removeWhitelist', [
        parceAddressToCV(user)
    ], post_condition);
    await openContractCall(function_option);
}


export async function i_distributorWithdraw(source: any, contract:string, addr: string, amount: string){

    // console.log({amount});
    const post_condition = [await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+contract, BASE_STSW_DATA().addr, amount, FungibleConditionCode.Equal)];

    const function_option : any =  getBasicWriteOptionsConditon(source, contract, 'AWDSTSW', [
        parceAddressToCV(addr), uintCV(amount)
    ], post_condition);
    await openContractCall(function_option);
}
export async function i_distributorSetInvestor(source: any, contract:string, addr: string, amountTotal: string, amountClaimed: string){

    // console.log({amount});
    const post_condition: any = [];

    const function_option : any =  getBasicWriteOptionsConditon(source, contract, 'setInvestor', [
        parceAddressToCV(addr), uintCV(amountTotal), uintCV(amountClaimed),
    ], post_condition);
    await openContractCall(function_option);
}
export async function i_distributorSetStartBlock(source: any, contract:string, startBlcok: string){

    // console.log({amount});
    const post_condition: any = [];

    const function_option : any =  getBasicWriteOptionsConditon(source, contract, 'setStartBlock', [
         uintCV(startBlcok),
    ], post_condition);
    await openContractCall(function_option);
}

export async function get_pox_info(source:any){
    const options = getBasicTokenOptions(source, 'SP000000000000000000002Q6VF78.pox', 'get-pox-info',
        [ ]);
    const result = await callReadOnlyFunction(options);
    // console.log(cvToValue(result).value);
    return cvToValue(result).value;
}

export async function stack_stx_to_pox(source:any, amount:string, poxAddress: any, start_burn_ht: string, lock_period: string){
    // const hashbytes = bufferCV(address.fromBase58Check(poxAddress).hash);
    const addresss = tupleCV({
        hashbytes: bufferCV(poxAddress),
        version: bufferCV(new Buffer(1)),
    });

    const amount_str = new BigNumber(amount).multipliedBy(10**6).toFixed(0).toString()
    // console.log(addresss);
    const options = getBasicTokenOptions(source, 'SP000000000000000000002Q6VF78.pox', 'stack-stx',
        [ uintCV(amount_str), addresss, uintCV(start_burn_ht), uintCV(lock_period)]);
    const result_raw = await openContractCall(options);
    return result_raw;

}
