
import {Component, Prop, Vue} from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component
export default class PopupModal extends Vue {
  @Prop({default: false}) IsPopupShow!: boolean;
  @Prop({default: ''}) Title!: string;
  @Prop({default: 'None-Contents'}) Contents!: string;

  OnClickConfirmBtn() {
    this.IsPopupShow = false;
    this.$emit('popupClosed', true);
    // console.log('confirm close')
  }
}

