
import { Component, Vue, Watch } from 'vue-property-decorator';
import modal from '@/components/modal/modal.component.vue';
import wallet from '@/components/modal/wallet.modal.vue';

import { mapGetters } from 'vuex';
import {getSenderAddress, numFormat_, numWithComma} from '@/stacks/manager';
import {IS_TESTNET, MODE, STACKS_BACKEND_URL} from '@/stacks/config';
import axios from 'axios';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    modal,
    wallet,
  },
  computed: {
    ...mapGetters([
      'isConnected',
        'user',
    ]),
  },
})
/***
 *
 */
export default class Header extends Vue {
  selected: any = null;
  toggleSetting: boolean = false;
  walletSetting: boolean = false;
  tolerance: number = 5;
  deadline: number = 60;
  showing_header: boolean = IS_TESTNET();

  res_predict: string = 'UP';
  res_time: string = '2022-12-12 18:00';
  res_accuracy: string = '66.66';

  toogleMore: boolean = false;
  item_list: any = [
    {url: '/about', name: 'About', icon: 'stsw.svg'},
    {url: '/poxl-mining', name: 'PoXL Mining', icon: 'stsw.svg'},
    {url: '/launchpad', name: 'Launchpad', icon: 'stsw.svg'},
    {url: '/stx_stacking', name: 'STX Stacking', icon: 'stsw.svg'},
    {url: '/event_reward', name: 'Beta Event', icon: 'stsw.svg'},
    {url: 'https://github.com/stackswap/stackswap_contract_v1', name: 'Github', icon: 'github.svg'},
    {url: 'https://www.stackswap.org/disclaimer/', name: 'Disclaimer', icon: 'info.svg'},
    {url: 'https://twitter.com/Stackswap_BTC', name: 'Twitter', icon: 'twitter.svg'},
    {url: 'https://t.me/Stackswap', name: 'Telegram', icon: 'telegram.svg'},
    {url: 'https://discord.gg/HUv6eChp9p', name: 'Discord', icon: 'discord.svg'},
    {url: 'https://medium.com/@Stackswap', name: 'Medium', icon: 'medium.svg'},
    {url: '/legacypool', name: 'Legacy Pool', icon: 'stsw.svg'},

  ];
  getImgUrl(icon_name: string) {
    return require('../../public/icon/' + icon_name);
  }
  gotoURL(url: string) {
    window.open(url, '_blank');
  }
  is_farm_staking_active() {
    return IS_TESTNET();
  }
  onClickMore() {
    this.toogleMore = !this.toogleMore;
    if (this.toogleMore) {
      setTimeout(() => document.addEventListener('click', this.hide), 0);
    }

  }
  hide() {
    this.toogleMore = false;
    document.removeEventListener('click', this.hide);
  }
  created() {
    this.tolerance = this.$store.getters.tolerance;

  }

  // async getPrediction(){
  //   let res = await axios.get ( STACKS_BACKEND_URL() + '/stacks/predict/');
  //   let json_data = JSON.parse(res.data);
  //   // console.log(json_data)
  //   // /console.log(typeof (json_data))
  //   // console.log(`AI market forecast: STX/KRW 48H ${json_data["prediction"]}    Last predicted at ${json_data["time"]} UTC    7day running average ${json_data["accuracy"]}%`);
  //   this.res_predict = json_data["prediction"];
  //   this.res_time = json_data["time"];
  //   this.res_accuracy = this.numFormat(json_data["accuracy"],2);
  // }

  numFormat(input: any, decimal: number = 3) {
    return numFormat_(input, decimal);
  }

  getMode() {
    if (MODE() === 'mainnet') {
      return 'mainnet';
    } else if (MODE() === 'testnet') {
      return 'testnet';
    } else {
      return 'error';
    }
  }


  connectWallet() {

    this.$store.dispatch('connectWallet', this.$store.getters.callback);
    // alert('connect to wallet');

  }

  openWallet() {
    // console.log("Click wallet")
    this.walletSetting = true;
  }

  closeWallet() {
    this.walletSetting = false;
    this.$store.dispatch('closeWallet');
  }


  openSetting() {
    this.toggleSetting = true;
  }

  closeSetting() {
    this.$store.dispatch('closeSetting');
  }

  chooseTolerance(tolerance: number) {
    this.tolerance = tolerance;
  }


  goSwap() {
    this.$router.push({name: 'swap'});
  }

  @Watch('$store.getters.toggleSettingModal')
  toggleSettingModal(val: boolean) {
    this.toggleSetting = val;
  }

  @Watch('tolerance')
  changeTolerance() {
    this.$store.dispatch('setTolerance', this.tolerance);
  }

  get isActive() {
    return (menu: string) => {
      return this.$route.fullPath.includes(menu);
    };
  }

  getUser() {
    const temp = getSenderAddress(this);
    // return temp.toString();
    return temp.substring(0, 10) + '...' + temp.substring(31, 41);
  }

  getUserFull() {
    const temp = getSenderAddress(this);
    return temp;
  }

  disconnectWallet() {
    this.$store.dispatch('disConnectWallet');
  }

  copyAddress() {

    navigator.clipboard.writeText(this.getUserFull());

    alert('Copied address to clipboard.');
  }
}
