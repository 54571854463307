
import { Component, Vue, Watch } from 'vue-property-decorator';
import {getSenderAddress} from '@/stacks/manager';
import {MODE} from '@/stacks/config';

@Component({
  components: {
  },
  computed: {
  },
})
/***
 *
 */
export default class Footer extends Vue {

}
