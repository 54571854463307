import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VTooltip from 'v-tooltip';
import VueSimpleAlert from 'vue-simple-alert';

Vue.use(VTooltip);
Vue.use(VueSimpleAlert);
import 'bootstrap/dist/js/bootstrap.bundle';
import {DISCLAIMER_CONTENT} from '@/stacks/config';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mounted() {
    this.$fire({
      title: 'Disclaimer',
      html: DISCLAIMER_CONTENT(),
      allowOutsideClick: false,
    });
  },
  render: (h) => h(App),
}).$mount('#app');
