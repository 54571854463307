
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  changeRiskParams, finalizeStackswap, getVaultInfoFromID2, withdrawLiquidatedLBTC_STSW, withdrawLiquidatedLBTC_STX,
  burnLiquidatedLBTC_STSW, burnLiquidatedLBTC_STX,
} from '@/stacks/manager';

import luHeader from '@/layout/header.component.vue';
import {CONTRACT_NAME_STACKSWAP_FARMING, CONTRACT_NAME_STACKSWAP_STACKING, IS_TESTNET} from '@/stacks/config';
import {contractPrincipalCV, standardPrincipalCV, stringAsciiCV, tupleCV, uintCV} from '@stacks/transactions';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class VaultmanagePage extends Vue {

  col_type_key: string = '';

  col_type_ll: string = '';

  col_type_ctdr: string = '';

  col_type_md: string = '';

  col_type_sf: string = '';

  col_type_sfa: string = '';

  col_type_sfd: string = '';

  vault_id: string = '';

  withdraw_vault_id: string = '';
  withdraw_uamount: string = '';
  withdraw_max_uamount: string = '';
  withdraw_coll_token: string = '';

  burn_vault_id: string = '';
  burn_uamount: string = '';
  burn_max_uamount: string = '';
  burn_coll_token: string = '';

  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    // console.log('test')
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }


  finalize() {
    finalizeStackswap(this, this.vault_id);
  }

  get_vault_info() {
    getVaultInfoFromID2(this, this.withdraw_vault_id).then((res) => {
      console.log(res);
      this.withdraw_max_uamount = new BigNumber(res.collateral.value).dividedBy(10 ** 6).toString();
      this.withdraw_coll_token = res['collateral-token'].value;

    });
  }
  get_vault_info_burn() {
    getVaultInfoFromID2(this, this.burn_vault_id).then((res) => {
      console.log(res);
      this.burn_max_uamount = new BigNumber(res.debt.value).dividedBy(10 ** 8).toString();
      this.burn_coll_token = res['collateral-token'].value;

    });
  }
  withdraw_liquidated() {
    if (this.withdraw_coll_token === 'STSW') {
      if (this.withdraw_uamount === '') {
        withdrawLiquidatedLBTC_STSW(this, this.withdraw_vault_id, this.withdraw_max_uamount);
      } else {
        withdrawLiquidatedLBTC_STSW(this, this.withdraw_vault_id, this.withdraw_uamount);
      }
    } else {
      if (this.withdraw_uamount === '') {
        withdrawLiquidatedLBTC_STX(this, this.withdraw_vault_id, this.withdraw_max_uamount);
      } else {
        withdrawLiquidatedLBTC_STX(this, this.withdraw_vault_id, this.withdraw_uamount);
      }
    }
  }

  burn_liqudated() {
    if (this.burn_coll_token === 'STSW') {
      if (this.burn_uamount === '') {
        burnLiquidatedLBTC_STSW(this, this.burn_vault_id, this.burn_max_uamount);
      } else {
        burnLiquidatedLBTC_STSW(this, this.burn_vault_id, this.burn_uamount);
      }
    } else {
      if (this.burn_uamount === '') {
        burnLiquidatedLBTC_STX(this, this.burn_vault_id, this.burn_max_uamount);
      } else {
        burnLiquidatedLBTC_STX(this, this.burn_vault_id, this.burn_uamount);
      }
    }

  }

  setUp() {
    // (list {name: "governance", address: 'ST23DAB333A5CPFXNK13E5YMX1DZJ07112QA7HWHA, qualified-name: 'ST23DAB333A5CPFXNK13E5YMX1DZJ07112QA7HWHA.stackswap-governance-v5gb})
    const temp: any = [];
    if (this.col_type_ll.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('liquidation-ratio'), 'new-value' : uintCV(this.col_type_ll)}));
    }
    if (this.col_type_ctdr.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('collateral-to-debt-ratio'), 'new-value' : uintCV(this.col_type_ctdr)}));
    }
    if (this.col_type_md.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('maximum-debt'), 'new-value' : uintCV(this.col_type_md)}));
    }
    if (this.col_type_sf.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('stability-fee'), 'new-value' : uintCV(this.col_type_sf)}));
    }
    if (this.col_type_sfa.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('stability-fee-apy'), 'new-value' : uintCV(this.col_type_sfa)}));
    }
    if (this.col_type_sfd.length > 0) {
      temp.push(tupleCV({"key" : stringAsciiCV('stability-fee-decimals'), 'new-value' : uintCV(this.col_type_sfd)}));
    }

    changeRiskParams(this, this.col_type_key, temp);
  }

}
