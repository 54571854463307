import {openContractCall} from "@stacks/connect";
import {BASE_STSW_DATA, BASE_STX, BASE_vSTSW_DATA, BASE_WSTX_DATA, STACKSWAP_ADDRESS} from "@/stacks/config";
import {
  callReadOnlyFunction,
  contractPrincipalCV, cvToValue,
  falseCV,
  FungibleConditionCode, listCV,
  standardPrincipalCV, stringAsciiCV,
  trueCV,
  uintCV
} from "@stacks/transactions";
import {
  getBasicTokenOptions, getBasicTokenWriteOptions,
  getBasicTokenWriteOptionsCallbackCondition,
  getPostConditionFromAsset,
  getSenderAddress, parceAddressToCV
} from "@/stacks/manager";
import BigNumber from "bignumber.js";

const BRIDGE_CONTRACT = 'SP3YX02QBYNBWQ0QQ9CKSWT263C2KR9AE7BGZT6XA.bridge002';


export async function get_voters(source: any, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'get-voters',
    []);
  try {
    const result = await callReadOnlyFunction(options);
    const temp2 = [];
    for (const cvToValueElement of cvToValue(result)) {
      temp2.push(cvToValueElement.value);
    }
    console.log('get_voters', temp2);
    return temp2;
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}
export async function getVoteRes(source: any, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'getVoteRes',
    []);
  try {
    const result = await callReadOnlyFunction(options);
    console.log('getVoteRes', cvToValue(result));
    return  cvToValue(result);
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}

export async function GetFee(source: any, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'GetFee',
    []);
  try {
    const result = await callReadOnlyFunction(options);
    console.log('GetFee', cvToValue(result));
    return  cvToValue(result);
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}
export async function getETHToken(source: any, token: string, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'getETHToken',
    [parceAddressToCV(token)]);
  try {
    const result = await callReadOnlyFunction(options);
    console.log('getETHToken', cvToValue(result));
    return  cvToValue(result);
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}
export async function getOrderIDInfo_From(source: any, id: string, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'getOrderIDInfo_From',
    [uintCV(id)]);
  try {
    const result = await callReadOnlyFunction(options);
    console.log('getOrderIDInfo_From', cvToValue(result));
    return  cvToValue(result);
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}

export async function getOrderIDInfo_To(source: any, id: string, bridge_contract : string = BRIDGE_CONTRACT) {
  // console.log('getStakingSTSWBalance');
  const options : any = getBasicTokenOptions(source, bridge_contract, 'getOrderIDInfo_To',
    [uintCV(id)]);
  try {
    const result = await callReadOnlyFunction(options);
    console.log('getOrderIDInfo_To', cvToValue(result));
    return  cvToValue(result);
  } catch (e) {
    // console.log(e);
    return '0';
  }
  // console.log(pair_details_result)
}

export async function withdrawFixFee(source: any, amount: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition = [
    // await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    await getPostConditionFromAsset(bridge_contract, BASE_WSTX_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'WithdrawFee_FIX', [
  ], post_condition);
  await openContractCall(function_option);
}

export async function WithdrawFee_RATIO(source: any, type: string, token: string, amount: string, bridge_contract : string = BRIDGE_CONTRACT){
  let functionName = 'WithdrawFee_RATIO_1';
  switch (type) {
    case 'ratio1' :
      functionName = 'WithdrawFee_RATIO_1';
      break;
    case 'ratio2' :
      functionName = 'WithdrawFee_RATIO_2';
      break;
    case 'ratio3' :
      functionName = 'WithdrawFee_RATIO_3';
      break;
  }
  // console.log({amount});
  const post_condition = [
    await getPostConditionFromAsset(bridge_contract, BASE_STSW_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    // await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, functionName, [
    parceAddressToCV(token),
  ], post_condition);
  await openContractCall(function_option);
}

export async function vote(source: any, vote: boolean, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'vote', [
    vote? trueCV() : falseCV()
  ], []);
  await openContractCall(function_option);
}

export async function depositSTSW_SIP10_to_ERC20(source: any, addr: string, amount: string, fee: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition = [
    await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, new BigNumber(amount).plus(fee).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'DepositForETH', [
    stringAsciiCV(addr),
    parceAddressToCV(BASE_STSW_DATA().addr),
    uintCV(new BigNumber(amount).multipliedBy(10**6).toFixed(0)),
    uintCV(new BigNumber(fee).multipliedBy(10**6).toFixed(0)),
  ], post_condition);
  await openContractCall(function_option);
}

export async function cancel_depositSTSW_SIP10_to_ERC20(source: any, orderID: string, amount: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition = [
    await getPostConditionFromAsset(bridge_contract, BASE_STSW_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    // await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'Cancel_DepositForETH', [
    uintCV(orderID)
  ], post_condition);
  await openContractCall(function_option);
}

export async function EmergencyWithdraw(source: any, amount: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition = [
    await getPostConditionFromAsset(bridge_contract, BASE_STSW_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    // await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'EmergencyWithdraw', [
    parceAddressToCV(BASE_STSW_DATA().addr)
  ], post_condition);
  await openContractCall(function_option);
}
export async function withdrawFrom(source: any, orderID: string, amount: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition = [
    await getPostConditionFromAsset(bridge_contract, BASE_STSW_DATA().addr, new BigNumber(amount).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    // await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal),
    await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, new BigNumber(10).multipliedBy(10**6).toFixed(0), FungibleConditionCode.Equal)
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'WithdrawFromETH', [
    uintCV(orderID),
    parceAddressToCV(BASE_STSW_DATA().addr),
  ], post_condition);
  await openContractCall(function_option);
}

export async function registerTokenPair(source: any, sip10Addr:string, ercAddr: string, min: string, max: string, bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const post_condition: any = [
  ];

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'RegisterTokenPair', [
    parceAddressToCV(sip10Addr),
    stringAsciiCV(ercAddr),
    uintCV(new BigNumber(min).multipliedBy(10**6).toFixed(0)),
    uintCV(new BigNumber(max).multipliedBy(10**6).toFixed(0)),
  ], post_condition);
  await openContractCall(function_option);
}

export async function update_depositSTSW_SIP10_to_ERC20(source: any, round: string, orders: string[], bridge_contract : string = BRIDGE_CONTRACT){

  // console.log({amount});
  const tempList = [];
  for (const order of orders) {
    tempList.push(uintCV(order));
  }

  const function_option : any =  getBasicTokenWriteOptionsCallbackCondition(source, bridge_contract, 'Update_DepositForETH', [
    parceAddressToCV(BASE_STSW_DATA().addr),
    // uintCV(round),
    listCV(tempList),
  ], []);
  await openContractCall(function_option);
}
