
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  getBalance,
  getDatas,
  getPairBalance,
  getSenderAddress,
  isInWhitelist,
  getUserCountFromVSTSW,
  txStakeFromStaking,
  getStakingBalanceFromVSTSW,
  getRewardBalanceFromStaking,
  getCurrentStackingRound,
  getReward,
  getProposalsList,
  getStakingBalanceFromVSTSW_V2,
  getBlockHeight,
  getNumVoting,
  txWithdrawVoting,
  getRewardTotal,
  numWithComma,
  getStakingSTSWBalance,
  getSTXUSD,
  getSTXSTSWPrice,
  getStakingAPR,
  getRewardRoundID_V2,
  numFormat_,
  distributorGetInvestor, distributorGetReclaim, STSWInvestTx, distributorClaimSTSW, distributorReclaimLP,
} from '@/stacks/manager';
import axios from 'axios';
import luHeader from '@/layout/header.component.vue';
import {IS_TESTNET, BASE_STSW_DATA, BASE_WSTX_DATA, BASE_vSTSW_DATA} from '@/stacks/config';
// import vote_confirm from '@/router/staking/components/vote-modal.vue';
import vote_confirm from '@/router/staking/components/vote.component.vue';
import vote_popup from '@/router/staking/components/vote.popup.vue';
import confirm_popup from '@/components/modal/confirm.popup.vue';
import BigNumber from 'bignumber.js';
import {getDecimalStringfromString} from '@/stacks/token.tool';

@Component({
  components: {
    luHeader,
    vote_confirm,
    confirm_popup,
    vote_popup,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },

})
export default class StakingPage extends Vue {


  invested_STX: string = 'Loading...';
  initial_acq_lp: string = 'Loading...';
  reclaimed_lp: string = 'Loading...';
  reclaimable_lp: string = 'Loading...';
  claimed_stsw: string = 'Loading...';
  claimable_stsw: string = 'Loading...';

  LP_reclaim_balance: string = 'Loading...';
  STSW_reward_balance: string = 'Loading...';

  clicked_claim: boolean = false;
  clicked_reclaim: boolean = false;

  created() {
    if (this.$store.getters.isConnected) {
      // console.log("TTTTT", this.STSW_Token, this.vSTSW_Token)
      this.onConnect();
    }
  }

  numFormat(input: any, ads: string = '', decimal: number = 3) {
    if (input === 'Loading...') {
      return 'Loading...';
    }
    return numFormat_(input, decimal) + ads;
  }

  onConnect() {
    this.getData();
  }

  async getData() {
    distributorGetInvestor(this).then((value) => {
      // console.log(value);
      this.invested_STX = getDecimalStringfromString(value.invested_stx.value, 6);
      this.initial_acq_lp = getDecimalStringfromString(value.invested_lp.value, 6);
      this.reclaimed_lp = getDecimalStringfromString(value.claimed_lp.value, 6);
      this.claimed_stsw = getDecimalStringfromString(value.claimed_stsw.value, 6);
      if ( new BigNumber(value.claimable_stsw.value).toString() === '0') {
        this.claimable_stsw = '0';
        this.STSW_reward_balance = '0';
      } else {
        this.claimable_stsw = getDecimalStringfromString(new BigNumber(value.claimable_stsw.value).minus(1).toFixed().toString(), 6);
        this.STSW_reward_balance = getDecimalStringfromString(new BigNumber(value.claimable_stsw.value).minus(1).toFixed().toString(), 6);
      }
    });
    distributorGetReclaim(this).then((value) => {
      // console.log('reclaim',value);
      if ( new BigNumber(value).toString() === '0') {
        this.reclaimable_lp = '0';
        this.LP_reclaim_balance = '0';
      } else {
        // this.reclaimable_lp = getDecimalStringfromString(new BigNumber(value).minus(1).toString(),6);
        // this.LP_reclaim_balance = getDecimalStringfromString(new BigNumber(value).minus(1).toString(), 6);
        this.reclaimable_lp = '0';
        this.LP_reclaim_balance = '0';
      }
    });

  }

  confirmClaim() {
    this.clicked_claim = true;
    distributorClaimSTSW(this, this.STSW_reward_balance);
  }

  confirmReclaim() {
    this.clicked_reclaim = true;
    distributorReclaimLP(this, this.LP_reclaim_balance);
  }



}
