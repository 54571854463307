import empty from '../empty.vue';

import { firstRouter }  from './sub-router/first_page/first-page.router';
import { stakeRouter } from '@/router/staking/sub-router/stake/stake.router';
import { unstakeRouter } from '@/router/staking/sub-router/unstake/unstake.router';
import { rewardRouter } from '@/router/staking/sub-router/withdraw-reward/withdraw-reward.router';
import { voteRouter } from '@/router/staking/sub-router/vote/vote.router';


export const stakingRouter = [
  {
    path: '/vote',
    name: 'vote',
    component: empty,
    redirect: { name: 'staking' },
    children: [
      ...firstRouter,
      ...stakeRouter,
      ...unstakeRouter,
      ...rewardRouter,
      ...voteRouter,

    ],
  },
];
