import { render, staticRenderFns } from "./staking.page.vue?vue&type=template&id=458e3576&scoped=true&"
import script from "./staking.page.vue?vue&type=script&lang=ts&"
export * from "./staking.page.vue?vue&type=script&lang=ts&"
import style0 from "./staking.page.vue?vue&type=style&index=0&id=458e3576&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458e3576",
  null
  
)

export default component.exports