
import { Component, Prop, Vue } from 'vue-property-decorator';
import modal from '@/components/modal/modal.component.vue';
import {swapContractCall, txVote} from '@/stacks/manager';
import confirm_popup from'@/components/modal/confirm.popup.vue';

@Component({
  components: {
    modal,
    confirm_popup,
  },
})
export default class VoteConfirmComponent extends Vue {
  @Prop() value!: boolean;
  @Prop() vote_balance!: string;
  @Prop() proposal_id!:number;
  // @Prop() token!:any;
  num_against_vote:number = 0;
  num_for_vote:number = 0;
  vote_amount:number= 0;
  radioValues:string='SELECT VOTE MODE';

  // popup-var
  popup_show:boolean=false;
  popup_title:string='';
  popup_contents:string='None-Contents';

  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    // console.log('confirm close2')

    this.popup_show = false;
  }
  // ==== PopupSetting =====//

  create(){
    // console.log("Create", this.proposal_id)
  }

  input(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  tx_success(success: boolean) {
    this.$emit('tx_success', success);
  }

  onClickMax(){
    this.vote_amount = parseFloat(this.vote_balance);
  }

  onClickVote(){
    if(this.radioValues==='SELECT VOTE MODE'){
      // alert('Please select vote mode')
      this.openPopup('Please select vote mode')
    }
    else if(this.vote_amount <= 0){
      // alert("The number of votes should be bigger than 0")
      this.openPopup('The number of votes should be bigger than 0')

    }else if(parseFloat(this.vote_balance) < this.vote_amount){
      // alert(`The number of votes should be smaller than ${this.vote_balance}`)
      this.openPopup(`The number of votes should be smaller than ${this.vote_balance}`)

    }else{
      txVote(this,this.proposal_id, this.radioValues,this.vote_amount)
    }
  }
}
