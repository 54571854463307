
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  numFormat_, i_distributorGetInvestor, i_distributorGetReclaim, i_distributorClaimSTSW, i_distributorGetStatus
} from '@/stacks/manager';
import axios from 'axios';
import luHeader from '@/layout/header.component.vue';
import {IS_TESTNET, BASE_STSW_DATA, BASE_WSTX_DATA, BASE_vSTSW_DATA} from '@/stacks/config';
// import vote_confirm from '@/router/staking/components/vote-modal.vue';
import vote_confirm from '@/router/staking/components/vote.component.vue';
import vote_popup from '@/router/staking/components/vote.popup.vue';
import confirm_popup from'@/components/modal/confirm.popup.vue';
import BigNumber from 'bignumber.js';
import {getDecimalStringfromString} from '@/stacks/token.tool';

@Component({
  components: {
    luHeader,
    vote_confirm,
    confirm_popup,
    vote_popup,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },

})
export default class StakingPage extends Vue {

  contract_name: string = 'comodo-dragon001';
  total_amt: string = 'Loading...';
  claimed_amt: string = 'Loading...';
  claimable_amt: string = 'Loading...';
  cur_round: string = 'Loading...';
  monthly_release: string = 'Loading...';
  round_percent: string = 'Loading...';
  STSW_reward_balance: string = 'Loading...';

  clicked_claim: boolean = false;
  start_block: string = 'Loading...';
  next_round_starts_at: string = 'Loading...';
  claimedPercent: string = 'Loading...';

  created() {
    if (this.$store.getters.isConnected) {
      // console.log("TTTTT", this.STSW_Token, this.vSTSW_Token)
      this.onConnect();
    }
  }

  numFormat (input:any, ads:string = '', decimal:number = 3){
    if (input === 'Loading...'){
      return 'Loading...';
    }
    return numFormat_(input, decimal) + ads;
  }

  onConnect() {
    this.getData();
  }

  async getData() {
    i_distributorGetInvestor(this, this.contract_name).then((value) => {
      console.log(value);
      this.total_amt = getDecimalStringfromString(value.total_amount.value,6);
      this.claimed_amt = getDecimalStringfromString(value.claimed_amount.value,6);
      this.claimedPercent = new BigNumber(value.claimed_amount.value).multipliedBy(100).dividedBy(value.total_amount.value).toFixed(1);
      this.monthly_release = getDecimalStringfromString(new BigNumber(value.total_amount.value).dividedBy(36).toString(), 6);
    });
    i_distributorGetReclaim(this, this.contract_name).then((value) => {
      // console.log('reclaim',value);
      if ( new BigNumber(value).toString() === '0'){
        this.claimable_amt = '0';
        this.STSW_reward_balance = '0';
      } else{
        // this.reclaimable_lp = getDecimalStringfromString(new BigNumber(value).minus(1).toString(),6);
        // this.LP_reclaim_balance = getDecimalStringfromString(new BigNumber(value).minus(1).toString(), 6);
        this.claimable_amt = new BigNumber(value).dividedBy(10**6).toString();
        this.STSW_reward_balance = new BigNumber(value).dividedBy(10**6).toString();
      }
    });
    i_distributorGetStatus(this, this.contract_name).then((value) => {
      this.cur_round = value.cur_round.value;
      this.round_percent = new BigNumber(Number(this.cur_round) / 36 * 100).toFixed(1);
      this.start_block = value.cycle_start_block.value;
      this.next_round_starts_at = new BigNumber(this.start_block).plus(4320 * (Number(this.cur_round)+1)).toFixed(0);
    })

  }

  confirmClaim() {
    this.clicked_claim = true;
    i_distributorClaimSTSW(this, this.contract_name, this.STSW_reward_balance);
  }

}
