import empty from '../empty.vue';
import {subVaultRouter} from './sub_router/vault/first.router';
import {createRouter} from '@/router/vault/sub_router/create/create.router';
import {manageRouter} from '@/router/vault/sub_router/manage/manage.router';

export const vaultRouter = [
    {
        path: '/vault',
        name: 'vault',
        component: empty,
        redirect: { name: 'sub_vault' },
        children: [
            ...subVaultRouter,
            ...createRouter,
            ...manageRouter,
        ],
    },
];
