
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {
  changeRiskParams,
} from '@/stacks/manager';

import luHeader from '@/layout/header.component.vue';
import {
  CONTRACT_NAME_GOLD_PASS, CONTRACT_NAME_SILVER_PASS,
  CONTRACT_NAME_STACKSWAP_FARMING,
  CONTRACT_NAME_STACKSWAP_STACKING,
  IS_TESTNET,
} from '@/stacks/config';
import {
  bufferCV,
  contractPrincipalCV,
  standardPrincipalCV,
  stringAsciiCV,
  TupleCV,
  tupleCV,
  uintCV,
} from '@stacks/transactions';
import BigNumber from 'bignumber.js';
import {batchSetMintPass, mintNFT, mintNFT2, sendMany} from '@/stacks/nft/nft.manager';
import {principalCV} from '@stacks/transactions/dist/clarity/types/principalCV';
import {StandardPrincipalCV} from '@stacks/transactions/dist/clarity';
import {bufferCVFromString} from "@stacks/transactions/src/clarity/types/bufferCV";

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class SendmanyPage extends Vue {

  memo: string = '';

  addresses: string = '';

  amounts: string = '';

  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
  }

  onConnect() {
    // console.log('test')\
  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  setUp() {
    const mList: TupleCV[] = [];
    //    ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5,ST1SJ3DTE5DN7X54YDH5D64R3BCB6A2AG2ZQ8YPD5
    // 1,1,1,1,1,1
    const addr_list = this.addresses.split(',');
    // console.log(addr_list);
    const amount_list = this.amounts.split(',');
    // console.log(amount_list);
    if ( addr_list.length !== amount_list.length) {
      alert('please input same length');
      return;
    }
    let sum = new BigNumber(0);
    for (let i = 0; i < addr_list.length; i++) {
      mList.push(tupleCV({to: standardPrincipalCV(addr_list[i]), ustsw : uintCV(new BigNumber(amount_list[i]).multipliedBy(10 ** 6).toFixed(0)), memo: bufferCVFromString(this.memo)}));
      // console.log(amount_list[i]);
      // console.log(new BigNumber(10**6).multipliedBy(amount_list[i]).toFixed(0));
      sum = sum.plus(new BigNumber(10 ** 6).multipliedBy(amount_list[i]));
      // console.log(sum.toFixed(0))
    }
    sendMany(this, mList, sum.toFixed(0));
  }

}
