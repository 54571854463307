
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';


import luHeader from '@/layout/header.component.vue';
import {
  getContractBalance, i_distributorGetInvestor,
  i_distributorGetIsManageable, i_distributorGetReclaim,
  i_distributorGetStatus,
  i_distributorIsWhitelist,
  i_distributorManageWhiteList,
  i_distributorSetInvestor, i_distributorSetStartBlock,
  i_distributorWithdraw,
  send_tokens
} from "@/stacks/manager";
import {
  BASE_STSW_DATA,
  STACKSWAP_ADDRESS
} from "@/stacks/config";
import BigNumber from "bignumber.js";
import {getDecimalStringfromString} from "@/stacks/token.tool";

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class InvestordistributorPage extends Vue {

  contract_address : string = 'comodo-dragon001';
  amount_in_contract: string = 'Loading...';
  total_invested_amount: string = 'Loading...';
  claimable_invested_amount: string = 'Loading...';
  claimed_invested_amount: string = 'Loading...';
  deposit_amount: string = '';
  withdraw_address: string = '';
  withdraw_amount: string = '';
  investor_address: string = '';
  investor_total: string = '';
  investor_claimed: string = '';

  investor_claimable: string = '';
  next_round_starts_at: string = 'Loading...';
  current_round: string = 'Loading...';
  start_block: string = '0';

  whitelist_address: string = '';
  isWhiteList : string = '';

  isManageable: string = 'Loading...';


  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
    getContractBalance(this, BASE_STSW_DATA(), STACKSWAP_ADDRESS()+'.'+this.contract_address).then(value => {
      this.amount_in_contract = new BigNumber(value).dividedBy(10**6).toString();
    });
    i_distributorGetStatus(this, this.contract_address).then(value => {
      console.log('getstatus',value);
      this.current_round = value.cur_round.value;
      this.total_invested_amount = value.total.value;
      this.claimed_invested_amount = value.claimed.value;
      this.claimable_invested_amount = new BigNumber(value.total.value).multipliedBy(value.cur_round.value).dividedBy(36).minus(value.claimed.value).dividedBy(10**6).toFixed(6);
      this.start_block = value.cycle_start_block.value;
      this.next_round_starts_at = new BigNumber(this.start_block).plus(4320 * (Number(this.current_round)+1)).toFixed(0);
    });
    i_distributorGetIsManageable(this, this.contract_address).then(value => {
      this.isManageable = value;
    })
  }

  async onConnect() {
  }
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  deposit_token(){
    send_tokens(this, this.contract_address, new BigNumber(this.deposit_amount).multipliedBy(10**6).toFixed(0));
  }

  withdraw_token(){
    i_distributorWithdraw(this, this.contract_address, this.withdraw_address, this.withdraw_amount);
  }

  set_investor(){
    i_distributorSetInvestor(this, this.contract_address, this.investor_address, new BigNumber(this.investor_total).multipliedBy(10**6).toFixed(0), new BigNumber(this.investor_claimed).multipliedBy(10**6).toFixed(0));
  }

  addWhitelist(){
    i_distributorManageWhiteList(this, this.contract_address, this.whitelist_address, true);
  }
  removeWhitelist(){
    i_distributorManageWhiteList(this, this.contract_address, this.whitelist_address, false);
  }

  input_change_whitelist_address() {
    this.isWhiteList = 'Loading...';

    i_distributorIsWhitelist(this, this.contract_address, this.whitelist_address).then(value => {
      this.isWhiteList = value;
    });
  }
  setStartBlock() {
    i_distributorSetStartBlock(this, this.contract_address, this.start_block);
  }

  changeInvestor() {
    this.investor_claimed = 'Loading...';
    this.investor_total = 'Loading...';
    this.investor_claimable = 'Loading...';
    i_distributorGetInvestor(this, this.contract_address, this.investor_address).then( value =>  {
        console.log(value);
        this.investor_total = ''+Number(new BigNumber(value.total_amount.value).dividedBy(10**6).toFixed(6));
        this.investor_claimed = ''+Number(new BigNumber(value.claimed_amount.value).dividedBy(10**6).toFixed(6));
        // this.claimedPercent = new BigNumber(value.claimed_amount.value).multipliedBy(100).dividedBy(value.total_amount.value).toFixed(1);
        // this.monthly_release = getDecimalStringfromString(new BigNumber(value.total_amount.value).dividedBy(36).toString(), 6);
      });
      i_distributorGetReclaim(this, this.contract_address).then((value) => {
        // console.log('reclaim',value);
        if ( new BigNumber(value).toString() === '0'){
          this.investor_claimable = '0';
        } else{
          this.investor_claimable = ''+Number(new BigNumber(value).dividedBy(10**6).toFixed(6));
        }
      });
  }

}
