import BigNumber from 'bignumber.js';
import {
    AnchorMode,
    callReadOnlyFunction,
    contractPrincipalCV,
    cvToValue,
    FungibleConditionCode,
    PostConditionMode,
    standardPrincipalCV,
    stringAsciiCV,
    uintCV,
} from '@stacks/transactions';
import {openContractCall} from '@stacks/connect';
import {
    BASE_STSW_DATA,
    BASE_WSTX_DATA,
    CONTRACT_NAME_COLLATERAL_TYPES,
    CONTRACT_NAME_MORTGAGER,
    CONTRACT_NAME_POX,
    CONTRACT_NAME_SIP10_RESERVE,
    CONTRACT_NAME_STX_RESERVE,
    CONTRACT_NAME_ORACLE,
    CONTRACT_NAME_VAULT_DATA,
    STACKSWAP_ADDRESS,
    BASE_LBTC_DATA,
    CONTRACT_NAME_LIQUIDATOR,
    CONTRACT_NAME_STACKER1,
    CONTRACT_NAME_STACKER3,
    CONTRACT_NAME_STACKER2, CONTRACT_NAME_STACKER4,
} from '@/stacks/config';
import {
    getNetwork,
    getSenderAddress,
    getPostConditionFromAsset,
    getBlockHeight,
    getBasicReadOptions
} from '@/stacks/manager';

////////////////////////////////////////////////////
type BooleanCV = TrueCV | FalseCV;
interface TrueCV {
    type: 0x03;
}

interface FalseCV {
    type: 0x04;
}

const trueCV = (): BooleanCV => ({ type: 0x03 });
const falseCV = (): BooleanCV => ({ type: 0x04 });
////////////////////////////////////////////////////////////////

function getBasicWriteOptionsConditonCallback(source: any, contractName: string, functionName: string, functionArgs: any, postConditions: any) {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Deny,
        postConditions,
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        sponsored: false,
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

function getBasicWriteOptionsConditionAllow(source: any, contractName: string, functionName: string, functionArgs: any, postConditions: any) {
    return  {
        contractAddress: STACKSWAP_ADDRESS(),
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
        postConditionMode: PostConditionMode.Allow,
        postConditions: [ ],
        appDetails : {
            name: 'Stackswap',
            icon: window.location.origin + '/public/favicon.ico',
        },
        anchorMode: AnchorMode.Any, // speedup
        sponsored: false,
        onFinish : (data: any) => {
            // console.log(data);
            try {
                source.onWalletSuccess(data);
            } catch (e) {
                // console.log(e);
            }
        },
        onCancel : () => {
            try{
                source.onWalletCancel();
            } catch (e) {
                // console.log(e);
            }
        },
    };
}

function getRawReadOptions(source: any, contract_addr:string,contractName: string, functionName: string, functionArgs: any) {
    return  {
        contractAddress: contract_addr,
        contractName,
        functionName,
        functionArgs,
        network: getNetwork(),
        senderAddress: getSenderAddress(source),
    };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getPoxInfo(source:any){
    const function_option =  getRawReadOptions(source,
        CONTRACT_NAME_POX(),
        'pox',
        'get-pox-info',
        []
    );
    // @ts-ignore
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);
}

export async function getVaultTypeInfo(source:any, coll_type:string){

    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_COLLATERAL_TYPES(),
        'get-collateral-type-by-name',
        [stringAsciiCV(coll_type)]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);
}

export async function getVaultIDFromUser(source:any){

    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_VAULT_DATA(),
        'get-vaults',
        [standardPrincipalCV(getSenderAddress(source)) ]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);
}

export async function getVaultInfoFromID(source:any, vault_id:string){

    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_VAULT_DATA(),
        'get-vault-by-id',
        [uintCV(vault_id)]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);
}

export async function getVaultIDFromUser2(source:any){
    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_VAULT_DATA(),
        'get-vault-entries',
        [standardPrincipalCV(getSenderAddress(source)) ]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    // console.log('getVaultIDFromUser2', cvToValue(result_raw))
    return cvToValue(result_raw);
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function txCreateVaultWithSTX(source:any, coll_amt:BigNumber, debt_amt:BigNumber, coll_type:string, isStack:boolean) {
    let IsStack = trueCV;
    if(isStack){
        IsStack = trueCV;
    }else{
        IsStack = falseCV;
    }

    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_MORTGAGER(), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'collateralize-and-mint',
        [
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            IsStack(),
            stringAsciiCV(coll_type),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_STX_RESERVE()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txCreateVaultWithSTSW(source:any, coll_amt:BigNumber, debt_amt:BigNumber, coll_type:string, isStack:boolean) {
    let IsStack = trueCV;
    if(isStack){
        IsStack = trueCV;
    }else{
        IsStack = falseCV;
    }

    const post_condition = [];
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_MORTGAGER(), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'collateralize-and-mint',
        [
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            falseCV(),
            stringAsciiCV(coll_type),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_SIP10_RESERVE()),
            contractPrincipalCV(BASE_STSW_DATA().addr.split('.')[0],BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}



export async function txDepositCollateral(source:any, vault_id:number, coll_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    // console.log(coll_token,coll_token === 'STX' )
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    if(coll_token === 'STX') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));

    }else if(coll_token === 'STSW') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    }

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'deposit',
        [
            uintCV(vault_id.toString()),
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txToggleStacking(source:any, vault_id:number){
    const post_condition:any = [];

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'toggle-stacking',
        [
            uintCV(vault_id.toString()),
        ],
        post_condition
    );

    openContractCall(function_option);
}

export async function txStackCollateral(source:any, vault_id:number){
    const post_condition:any = [];

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'stack-collateral',
        [
            uintCV(vault_id.toString()),
        ],
        post_condition
    );

    openContractCall(function_option);
}

function resolveStacker(stacker_name: string){
    switch (stacker_name) {
        case 'stacker':
            return CONTRACT_NAME_STACKER1();
        case 'stacker-2':
            return CONTRACT_NAME_STACKER2();
        case 'stacker-3':
            return CONTRACT_NAME_STACKER3();
        case 'stacker-4':
            return CONTRACT_NAME_STACKER4();
        default:
            return CONTRACT_NAME_STACKER1();
    }
}

export async function txEnableWithdraw(source:any, vault_id:number, stacker_name: string){
    const post_condition:any = [];

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        resolveStacker(stacker_name),
        'enable-vault-withdrawals',
        [
            uintCV(vault_id.toString()),
        ],
        post_condition
    );

    openContractCall(function_option);
}

export async function txWithdrawCollateral(source:any, vault_id:number, coll_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition:any = [];
    if(coll_token === 'STX') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+reserve, BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    }else if(coll_token === 'STSW') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+reserve, BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    }

    const function_option =  getBasicWriteOptionsConditionAllow(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'withdraw',
        [
            uintCV(vault_id.toString()),
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txMintDebt(source:any, vault_id:number, debt_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+reserve, BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'mint',
        [
            uintCV(vault_id.toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txBurnDebt(source:any, vault_id:number, debt_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.GreaterEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'burn',
        [
            uintCV(vault_id.toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txCloseVault(source:any, vault_id:number, coll_token:string, coll_amount:string, debt_amount: string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();

    // console.log(coll_token, debt_amount);
    const post_condition:any = [];

    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
        post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_STX_RESERVE(), BASE_WSTX_DATA().addr, coll_amount, FungibleConditionCode.Equal));
    }else {
        post_condition.push(await getPostConditionFromAsset(STACKSWAP_ADDRESS()+'.'+CONTRACT_NAME_SIP10_RESERVE(), BASE_STSW_DATA().addr, coll_amount, FungibleConditionCode.Equal));
    }

    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, new BigNumber(debt_amount).multipliedBy(10**8).multipliedBy(1.001).toFixed(0), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'close-vault',
        [
            uintCV(vault_id.toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES())
        ],
        post_condition
    );
    openContractCall(function_option);
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export async function txLiquidation(source:any, vault_id:number,){
    const post_condition:any = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_LIQUIDATOR(),
        'notify-risky-vault',
        [
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_MORTGAGER()),
            uintCV(vault_id.toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txFinalLiquidation(source:any, vault_id:number){

    const post_condition:any = [];

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'finalize-liquidation',
        [
            uintCV(vault_id.toString()),
        ],
        post_condition
    );

    openContractCall(function_option);
}

export async function txUnliquidationDeposit(source:any, vault_id:number, coll_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    if(coll_token === 'STX') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    }else if(coll_token === 'STSW') {
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.Equal));
    }

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'deposit-to-unliquidate',
        [
            uintCV(vault_id.toString()),
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txUnliquidationBurn(source:any, vault_id:number, debt_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.GreaterEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'burn-to-unliquidate',
        [
            uintCV(vault_id.toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txLiquidationWithdraw(source:any, vault_id:number, coll_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    if(coll_token === 'STX') {
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_WSTX_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.LessEqual));

    }else if(coll_token === 'STSW') {
        post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
        // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_STSW_DATA().addr, coll_amt.multipliedBy(10**6).toString(), FungibleConditionCode.LessEqual));

    }

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'withdraw-liquidated',
        [
            uintCV(vault_id.toString()),
            uintCV(coll_amt.multipliedBy(10**6).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES())
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txLiquidationBurn(source:any, vault_id:number, debt_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));
    post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, debt_amt.multipliedBy(10**8).toString(), FungibleConditionCode.LessEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'burn-liquidated',
        [
            uintCV(vault_id.toString()),
            uintCV(debt_amt.multipliedBy(10**8).toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ],
        post_condition
    );
    openContractCall(function_option);
}

export async function txCloseVaultLiquidated(source:any, vault_id:number, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const post_condition:any = [];
    // post_condition.push(await getPostConditionFromAsset(getSenderAddress(source), BASE_LBTC_DATA().addr, 0, FungibleConditionCode.GreaterEqual));

    const function_option =  getBasicWriteOptionsConditonCallback(
        source,
        CONTRACT_NAME_MORTGAGER(),
        'close-vault-liquidated',
        [
            uintCV(vault_id.toString()),
            contractPrincipalCV(STACKSWAP_ADDRESS(),reserve),
            contractPrincipalCV(STACKSWAP_ADDRESS(),BASE_STSW_DATA().addr.split('.')[1]),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES())
        ],
        post_condition
    );
    openContractCall(function_option);
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getDebtRatio(source:any, coll_amt:BigNumber, debt_amt:BigNumber, coll_token:string){
    let reserve = CONTRACT_NAME_SIP10_RESERVE();
    if(coll_token === 'STX'){
        reserve = CONTRACT_NAME_STX_RESERVE();
    }

    const function_option =  getBasicReadOptions(source,
        reserve,
        'calculate-current-collateral-to-debt-ratio',
        [
            stringAsciiCV(coll_token),
            uintCV(debt_amt.multipliedBy(10**8).toFixed(0)),
            uintCV(coll_amt.multipliedBy(10**6).toFixed(0)),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_ORACLE())
        ]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    return cvToValue(result_raw);
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getPrice(source:any, token:string){

    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_ORACLE(),
        'get-price',
        [
            stringAsciiCV(token)
        ]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    // console.log(cvToValue(result_raw))
    return cvToValue(result_raw);
}

export async function getStabilityFee(source:any, vault_id:string){

    const function_option =  getBasicReadOptions(source,
        CONTRACT_NAME_MORTGAGER(),
        'get-stability-fee-for-vault',
        [
            uintCV(vault_id),
            contractPrincipalCV(STACKSWAP_ADDRESS(),CONTRACT_NAME_COLLATERAL_TYPES()),
        ]
    );
    const result_raw = await callReadOnlyFunction(function_option);
    // console.log(cvToValue(result_raw))
    return cvToValue(result_raw);
}
