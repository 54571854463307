import { StoreOptions } from 'vuex';

export const stakingStore: StoreOptions<any> = {
  state: {
    toggleStakingModal: false,
    toggleUnstakeModal: false,
    toggleRewardsModal: false,
  },
  actions: {
    // staking modal open
    openStaking({commit}) {
      commit('setToggleStakingModal', true);
    },
    // setting model close
    closeStaking({commit}) {
      commit('setToggleStakingModal', false);
    },
    openUnstake({commit}) {
      commit('setToggleUnstakeModal', true);
    },
    closeUnstake({commit}) {
      commit('setToggleUnstakeModal', false);
    },
    openRewards({commit}) {
      commit('setToggleRewardsModal', true);
    },
    closeRewards({commit}) {
      commit('setToggleRewardsModal', false);
    },
  },

  getters: {
    // 현재 staking modal이 open인지 close 리턴 합니다.
    toggleStakingModal: (state) => state.toggleStakingModal,
    toggleUnstakeModal: (state) => state.toggleUnstakeModal,
    toggleRewardsModal: (state) => state.toggleRewardsModal,
  },

  mutations: {
    setToggleStakingModal(state, isOpen) {
      state.toggleStakingModal = isOpen;
    },
    setToggleUnstakeModal(state, isOpen) {
      state.toggleUnstakeModal = isOpen;
    },
    setToggleRewardsModal(state, isOpen) {
      state.toggleRewardsModal = isOpen;
    },
  },
};
