
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import luHeader from '@/layout/header.component.vue';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})

export default class AboutPage extends Vue {

}
