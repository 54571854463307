
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import luHeader from '@/layout/header.component.vue';
import {BASE_WSTX_DATA, IS_TESTNET, MODE} from '@/stacks/config';
import {getBalance, isInWhitelist, numFormat_, STSWInvestTx} from '@/stacks/manager';

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'tolerance',
      'isConnected',
    ]),
  },
})

export default class StswDepositPage extends Vue {
  // 토큰을 선택하는 모달의 toggle 입니다.
  token_stx: { icon: string, name: string, [key: string]: any} = BASE_WSTX_DATA();
  token_stx_balance: number = 0;
  invest_stx_amount:number = 0;

  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  tx_id : string = '';

  onWalletCancel(){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }
  viewTX() {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
  }


  created() {
    if (this.$store.getters.isConnected) {
      getBalance(this, this.token_stx).then((value) => this.setSTXBalance());
    }

    this.$store.dispatch('setCallback', this.onConnect);
  }

  confirm() {
    isInWhitelist(this.$store.getters.user.mainnetAddress).then( (value) => {
      if (!value && !IS_TESTNET()) {window.location.href = 'https://testnet.stackswap.org'; }
    });
    // alert(`Invest STX : ${this.invest_stx_amount}`)
    this.clicked_confirm = true;
    STSWInvestTx(this, this.invest_stx_amount.toString());
  }


  isBalanceSufficient() {

    // console.log('token_x_balance', this.token_x_balance);
    // console.log('token_x_Number', parseFloat(this.token_x_Number));
    if (this.invest_stx_amount > this.token_stx_balance) {
      return false;
    } else {
      return true;
    }

  }

  // 월랫을 커넥트 하는 함수입니다.
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }
  onlyNumber($event: KeyboardEvent) {
    const keyCode = $event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      if (keyCode === 46) {
        const target: any = $event.target;
        $event.returnValue = target.value.split('.').length > 1 ? false : true;
      } else {
        $event.returnValue = false;
      }
    }
    // console.log($event);
  }

  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }

  setSTXBalance() {
    this.token_stx_balance = this.token_stx.balance / (10 ** this.token_stx.decimal);
  }

  onConnect() {
    isInWhitelist(this.$store.getters.user.mainnetAddress).then((value) => {
      if (!value && !IS_TESTNET()) {window.location.href = 'https://testnet.stackswap.org'; }
    });

    try {
      getBalance(this, this.token_stx).then((value) => this.setSTXBalance());
    } catch (e) {
      // console.log(e);
    }

  }

}

