
import { Component, Prop, Vue } from 'vue-property-decorator';
import modal from '@/components/modal/modal.component.vue';
import axios from 'axios';
import {BASE_WSTX_DATA, IS_TESTNET, MIAMI_DATA, STACKS_BACKEND_PARAM, STACKS_BACKEND_URL} from '@/stacks/config';
import {getTokenData} from '@/stacks/manager';

@Component({
  components: {
    modal,
  },
})
export default class SelectTokenComponent extends Vue {
  @Prop() value!: boolean;
  @Prop() token!: any;
  // 검색할 토큰 명입니다.
  searchToken: string = '';
  // 토큰의 리스트가 나와야되는 부분입니다.
  tokens: any = [
    MIAMI_DATA(),
  ];
  created() {
    const url = STACKS_BACKEND_URL() + '/stacks/tokens/' + STACKS_BACKEND_PARAM();
    axios.get ( url, {
      timeout: 30000, // 해당시간안에 응답이 오지않는다면 에러처리된다.
    }).then( (res) => {
      // @ts-ignore
      const fixed_res =  res.data.filter((data) => data.addr.includes('poxl'));
      this.tokens = this.tokens.concat(fixed_res);
    });
  }
  input(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  // 선택 완료시 해당 토큰값을 전달해줍니다.
  selected(token: any) {
    this.$emit('selected', token);
    this.input(false);
  }

  // 검색 input에 엔터를 치면 아래의 함수를 호출 합니다.
  search() {
    // console.log(this.searchToken);
    getTokenData(this, this.searchToken).then((value1) => {
      if (value1 != null) {
        this.selected(value1);
      }
      // console.log(value1);
    });
    alert('Loading data. Please wait.');
  }
}
