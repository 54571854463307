import { StoreOptions } from 'vuex';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { Person } from '@stacks/profile';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

export const userStore: StoreOptions<any> = {
  state: {
    user: null,
    callback: () => {
      // console.log('temp');
    },
  },
  actions: {
    // 월렛 커넥트 하는 부분입니다.
    connectWallet({commit}, callback) {
      showConnect({
        appDetails: {
          name: 'Stackswap',
          icon: 'https://app.stackswap.org/favicon.ico',
        },
        redirectTo: '/',
        onFinish: () => {
          const profile = userSession.loadUserData().profile.stxAddress;
          commit('setUser', {mainnetAddress: profile.mainnet, testnetAddress: profile.testnet});
          callback();
        },

      });
    },
    disConnectWallet({commit}) {
      commit('setUser', null);
      commit('setInvestedPairs', []);
    },
    setCallback({commit}, callback) {
      commit('setCallback', callback);
    },
  },
  getters: {
    // user의 정보가 있으면 이 부분의 getter를 사용하시면 됩니다.
    user: (state) => state.user,
    // connected 되어 있는지 확인하는 부분 입니다.
    isConnected: (state) => !!state.user,
    callback: (state) => state.callback,
  },
  mutations: {
    // action에서 가져온 유저 정보를 넣는 부분입니다.
    setUser(state, user) {
      state.user = user;
    },
    setCallback(state, callback) {
      state.callback = callback;
    },
  },
};
