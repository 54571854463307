
import {Component, Prop, Vue} from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import {txVote} from '@/stacks/manager';
import confirm_popup from'@/components/modal/confirm.popup.vue';

@Component({
  components: {
    confirm_popup,
  },
})export default class PopupModal extends Vue {
  @Prop({default: false}) IsVoteMenuShow!: boolean;
  @Prop({default: '0'}) vote_balance!: string;
  @Prop({default: 0}) proposal_id!:number;

  radioValues:string='NONE';
  invest_amount:number = 0;

  // popup-var
  popup_show:boolean=false;
  popup_title:string='';
  popup_contents:string='None-Contents';

  onClickStaking() {
    this.IsVoteMenuShow = false;
    this.$emit('popupClosed', true);
  }

  onClickMax(){
    this.invest_amount = parseFloat(this.vote_balance);
  }

  onClickVote(){
    if(this.radioValues==='SELECT VOTE MODE'){
      alert('Please select vote mode')
      this.openPopup('Please select vote mode')
    }
    else if(this.invest_amount <= 0){
      // alert("The number of votes should be bigger than 0")
      this.openPopup('The number of votes should be bigger than 0')

    }else if(parseFloat(this.vote_balance) < this.invest_amount){
      // alert(`The number of votes should be smaller than ${this.vote_balance}`)
      this.openPopup(`The number of votes should be smaller than ${this.vote_balance}`)

    }else{
      txVote(this,this.proposal_id, this.radioValues,this.invest_amount)
    }
  }

  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    // console.log('confirm close2')

    this.popup_show = false;
  }
  // ==== PopupSetting =====//

  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.IsVoteMenuShow = false;
    this.$emit('voteClosed', true);
  }
  // setting을 오픈합니다.
  openSetting() {
    this.$store.dispatch('openSetting');
  }
}

