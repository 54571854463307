
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import {
  getPairData,
  getBalance,
  poolShareRatio,
  getLiquidityList,
  isInWhitelist,
  getCurrentStackingRound,
  getStackingRoundFirstBlock,
  getClaimRewardListFromVSTSW,
  getRewardRoundID,
  getReward, getBlockHeight, txClaimReward, getRewardRoundID_V2, numFormat_
} from '@/stacks/manager';
import {
  BASE_WSTX_DATA,
  CONTRACT_NAME_STACKSWAP_STACKING,
  IS_TESTNET,
  BASE_STSW_DATA,
  BASE_vSTSW_DATA, MODE
} from '@/stacks/config';
import luHeader from '@/layout/header.component.vue';
import confirm_popup from'@/components/modal/confirm.popup.vue';
import tx_confirm from '@/components/modal/tx.modal.vue';
import BigNumber from 'bignumber.js';

@Component({
  components: {
    luHeader,
    confirm_popup,
    tx_confirm,

  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class WithdrawRewardPage extends Vue  {

  res_list:any=[];
  selected_id:number = 0;
  selected_reward:number = 0;
  selected_start_block:number = 0;
  selected_end_block:number = 0;

  current_block: string = '0';
  current_round:any = null;
  start_block_num: number = 0;
  end_block_num: number=0;
  // block_per_round: number = 4032;
  block_per_round: number = 5;
  value: number = 1;
  default_value = 'Loading....';
  // popup-var
  popup_show:boolean=false;
  popup_title:string='';
  popup_contents:string='None-Contents';

  // tx setting 모달
  toggleTxConfirm:boolean = false;


  clicked_confirm : boolean = false;
  tx_sent : boolean = false;
  tx_id : string = '';

  onWalletCancel(){
    // console.log('user closed wallet');
    this.clicked_confirm = false;
  }
  numFormat (input:any, decimal:number = 3){
    return numFormat_(input, decimal);
  }
  onWalletSuccess(data: any){
    // console.log('user tx successes', data);
    this.tx_id = data.txId;
    this.tx_sent = true;
    this.clicked_confirm = false;
  }
  viewTX() {
    window.open(`https://explorer.stacks.co/txid/${this.tx_id}?chain=${MODE()}`,'_blank');
  }

  OnTxOpen() {
    this.toggleTxConfirm = true;
  }
  OnTxClosed(){
    this.toggleTxConfirm = false;
  }
  setTokenBalance(token_obj:any){
    // alert(`${token_obj.balance / (10 ** token_obj.decimal)}`)
    return token_obj.balance / (10 ** token_obj.decimal);
  }
  created() {
    getBlockHeight().then((value)=>{
      this.current_block = value.toString();
    })
    // console.log('route params', this.$route.params.res_list, this.$route.params.res_list.length);
    if ( this.$route.params.res_list && this.$route.params.res_list.length > 0) {
      this.res_list = this.$route.params.res_list;
      this.selected_id = this.res_list[0];
      this.getRewardInfo(this.selected_id);
    } else {
      getRewardRoundID_V2(this).then(value=>{
        this.res_list = value;
        this.selected_id = this.res_list[0];
        this.getRewardInfo(this.selected_id);
      })
    }

  }

  onClickClaimReward(){
    if(this.selected_id>0) {
      this.clicked_confirm = true;
      txClaimReward(this, this.selected_id, new BigNumber(this.selected_reward).multipliedBy(10**6).toFixed(0).toString());
    }else{
      this.openPopup('Please select round ID')
    }
  }

  getRewardInfo(round_id:number){

    this.clicked_confirm  = false;
    this.tx_sent  = false;
    this.tx_id  = '';
    if(round_id>0){
      getReward(this, CONTRACT_NAME_STACKSWAP_STACKING(), round_id).then((value)=>{
        this.selected_reward = (value / (10 ** BASE_STSW_DATA().decimal));
      });
      getStackingRoundFirstBlock(this, round_id ).then((value1) => {
        if (value1) {
          this.selected_start_block = value1;
        }
      });
      getStackingRoundFirstBlock(this, Number(round_id) + 1).then((value1) => {
        if (value1) {
          this.selected_end_block = Number(value1) - 1;
        }
      });
    }
  }
  // ==== PopupSetting =====//
  openPopup(contents:string){
    this.popup_show=true;
    this.popup_contents=contents;
  }
  closePopup(){
    this.popup_show = false;
  }
  // ==== PopupSetting =====//
  // 뒤로 가기 함수 입니다. 우선 liquidity로 가지만 혹시 몰라 history back함수도 주석으로 넣었습니다.
  back() {
    this.$router.back();
    // this.$router.push({name: 'liquidity'});
  }
  // setting을 오픈합니다.
  openSetting() {
    this.$store.dispatch('openSetting');
  }
}
