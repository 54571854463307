
import {Component, Vue} from "vue-property-decorator";
import luHeader from '@/layout/header.component.vue';
import BigNumber from 'bignumber.js';
import {mapGetters} from "vuex";
import Web3 from "web3";
import {
  CONTRACT,
  Tx_AddAllowedContract, Tx_EmergencyWithdraw_ERC20, Tx_EmergencyWithdraw_ETH,
  Tx_Register_toToken,
  Tx_Register_Withdraw, Tx_Remove_Order,
  Tx_RemoveAllowedContract,
  Tx_setBridgeContract, Tx_SetChangeContract,
  Tx_setCurrentRoundInfo,
  Tx_setMaxLen,
  Tx_setNextRoundInfo,
  Tx_transferOwnership, Tx_UpdateRound,
  Tx_SetConfirmNum, Tx_EnrollWhiteWallet, Tx_DeregisterWhiteWallet, Tx_Vote, Tx_setFee,
  Tx_SetEmergency_Vault, Tx_EmergencyWithdraw_Vault, Tx_Approve,
  Tx_Burn,
} from "@/router/propose/bridge_eth_contract";

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})

export default class manageBridge_ETH extends Vue {
  private web3: Web3 | null = null;
  private account: string | null= null;

  newOwner:string = '0x';
  target1:string = '0x';
  target2:string = '0x';

  //  BridgeInfo
  ercToken:string = '0x';
  toTokenAddress:string = 'To Network Token Address';
  network_id: string= '10';
  new_round: string = '0';
  new_start_block: string = '0';
  new_cycle_len: string = '0';
  max_len:string = '0';
  bridgeContract:string = '0x';

  // Bridge
  toToken:string = '0x';
  toTokenDecimal:string = '0';
  Register_UserList:any = [];
  Register_TokenList:any = [];
  Register_AmtList:any = [];
  Register_OrderID:any = [];

  round: string = '0';
  addr_fee:string = '0x';
  addr_vault:string = '0x';
  addr_info:string = '0x';
  addr_multisig:string = '0x';
  erc20Token_:string ='0x';
  amt_ :string = '0';
  amt__:string = '0';
  updateRoundOrderID:any = [];
  Remove_Wallet: string = '0x';
  Remove_Token: string = '0x';
  Remove_withdrawOrderID: string = '0';

  //MultiSig
  vote_posi_num:string = '0';
  enroll_vote_wallet:string = '0x';
  delete_vote_wallet:string = '0x';
  vote_status:boolean = false;

  // Fee
  targetToken: string = '0x';
  network_id3: string= '10';
  deposit_fix_fee: string= '0';
  deposit_ratio_fee: string= '0';
  withdraw_fix_fee: string= '0';
  withdraw_ratio_fee: string= '0';

  //Vault
  isStop:boolean = false;
  withdrawERC20Token: string= '0x';

  //Stsw
  ApproveWallet: string = '0x';
  ApproveAmt:string = '0';
  BurnWallet: string = '0';
  BurnAmt: string = '0';


  private async connectToMetaMask(): Promise<void> {
    if (!window.ethereum) {
      alert('Please install MetaMask to use this feature');
      return;
    }

    try {
      // Request accounts access from MetaMask
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Set up Web3 instance using MetaMask provider
      this.web3 = new Web3(window.ethereum);

      // Get selected account
      const accounts = await this.web3.eth.getAccounts();
      this.account = accounts[0];

      // Do something with the account, for example:
      const balance = await this.web3.eth.getBalance(this.account);

    } catch (error) {
      console.error(error);
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  Click_AddAllowedContract(event: MouseEvent){
    const buttonId = (event.target as HTMLButtonElement).id.replace('_BtnA', '');
    const contract_key = (Object.keys(CONTRACT) as (keyof typeof CONTRACT)[]).find((key) => {
      return CONTRACT[key] === buttonId;
    });
    Tx_AddAllowedContract(this.web3, this.account, contract_key, this.target1)
  }
  Click_RemoveAllowedContract(event: MouseEvent){
    const buttonId = (event.target as HTMLButtonElement).id.replace('_BtnR', '');
    const contract_key = (Object.keys(CONTRACT) as (keyof typeof CONTRACT)[]).find((key) => {
      return CONTRACT[key] === buttonId;
    });
    Tx_RemoveAllowedContract(this.web3, this.account, contract_key, this.target2)
  }
  Click_transferOwnership(event: MouseEvent){
    const buttonId = (event.target as HTMLButtonElement).id.replace('_Btn', '');
    const contract_key = (Object.keys(CONTRACT) as (keyof typeof CONTRACT)[]).find((key) => {
      return CONTRACT[key] === buttonId;
    });
    Tx_transferOwnership(this.web3, this.account, contract_key, this.newOwner);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  Click_setNextRoundInfo(){ // ERROR
    console.log('click', this.new_round, this.new_start_block, this.new_cycle_len)
    Tx_setNextRoundInfo(this.web3, this.account, CONTRACT.BridgeInfo, this.new_round, this.new_start_block, this.new_cycle_len)
  }
  Click_setCurrentRoundInfo(){ // ERROR
    console.log('click', this.new_start_block, this.new_cycle_len)
    Tx_setCurrentRoundInfo(this.web3, this.account, CONTRACT.BridgeInfo, this.new_start_block, this.new_cycle_len)
  }
  Click_setMaxLen(){
    Tx_setMaxLen(this.web3, this.account, CONTRACT.BridgeInfo, this.max_len)
  }
  Click_setBridgeContract(){
    Tx_setBridgeContract(this.web3, this.account, CONTRACT.BridgeInfo, this.bridgeContract)
  }

  Click_Register_toToken(){
    Tx_Register_toToken(this.web3, this.account, CONTRACT.BridgeInfo, this.ercToken, this.toTokenAddress, this.network_id)
  }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  */
  // Click_Register_Withdraw(){
  //   Tx_Register_Withdraw(this.web3, this.account, CONTRACT.Bridge, this.toUser, this.toToken, this.amt, this.fromWallet, this.network_id2);
  // }
  Click_Remove_Order(){
    Tx_Remove_Order(this.web3, this.account, CONTRACT.Bridge, this.Remove_Wallet, this.Remove_Token, this.Remove_withdrawOrderID);
  }
  Click_SetChangeContract(){
    Tx_SetChangeContract(this.web3, this.account, CONTRACT.Bridge, this.addr_fee, this.addr_multisig);
  }
  Click_EmergencyWithdraw_ERC20(){
    Tx_EmergencyWithdraw_ERC20(this.web3, this.account, CONTRACT.Bridge, this.erc20Token_, this.amt_);
  }
  Click_EmergencyWithdraw_ETH(){
    Tx_EmergencyWithdraw_ETH(this.web3, this.account, CONTRACT.Bridge, this.amt__);
  }

  Click_AddNewRegister(){
    this.Register_UserList.push('');
    this.Register_AmtList.push('');
    this.Register_OrderID.push('');
    // Tx_UpdateRound(this.web3, this.account, CONTRACT.Bridge, this.updateRoundOrderID);
  }
  Click_Register_Withdraw(){
    let semi_res = 'Token Decimal : '+ this.toTokenDecimal + '\n';
    this.Register_TokenList = []
    for (var i = 0; i< this.Register_AmtList.length; i++){
      this.Register_TokenList.push(this.toToken);
      this.Register_AmtList[i] = new BigNumber(this.Register_AmtList[i]).multipliedBy(10 ** Number(this.toTokenDecimal)).toString()
      semi_res += this.Register_OrderID[i] +' # '
      semi_res += this.Register_UserList[i] +' # '
      semi_res += this.Register_TokenList[i] +' # '
      semi_res += this.Register_AmtList[i] +' # '
      semi_res += '\n'
    }
    alert(semi_res)
    // console.log(this.Register_UserList)
    // console.log(this.Register_TokenList)
    // console.log(this.Register_AmtList)

    Tx_Register_Withdraw(
        this.web3, this.account, CONTRACT.Bridge,
        this.Register_OrderID, this.Register_UserList, this.Register_TokenList, this.Register_AmtList);
  }

  Click_AddNewOrderID(){
    this.updateRoundOrderID.push('');
  }

  Click_UpdateRound(){
    Tx_UpdateRound(this.web3, this.account, CONTRACT.Bridge, this.updateRoundOrderID);
  }


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  */
  Click_SetConfirmNum(){
    Tx_SetConfirmNum(this.web3, this.account, CONTRACT.MultiSigWallet, this.vote_posi_num);
  }
  Click_EnrollWhiteWallet(){
    Tx_EnrollWhiteWallet(this.web3, this.account, CONTRACT.MultiSigWallet, this.enroll_vote_wallet);
  }
  Click_DeregisterWhiteWallet(){
    Tx_DeregisterWhiteWallet(this.web3, this.account, CONTRACT.MultiSigWallet, this.delete_vote_wallet);
  }
  Click_Vote(){
    Tx_Vote(this.web3, this.account, CONTRACT.MultiSigWallet, this.vote_status);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  */
  Click_setFee(){
    Tx_setFee(
        this.web3, this.account, CONTRACT.Fee,
        this.targetToken,
        this.deposit_fix_fee,
        this.deposit_ratio_fee,
        this.withdraw_fix_fee,
        this.withdraw_ratio_fee,
    );
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  */
  Click_SetEmergency(){
    Tx_SetEmergency_Vault(this.web3, this.account, CONTRACT.Vault, this.isStop);

  }

  Click_EmergencyWithdraw_Vault(){
    Tx_EmergencyWithdraw_Vault(this.web3, this.account, CONTRACT.Vault, this.withdrawERC20Token);

  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
  */
  Click_Approve(){
    Tx_Approve(this.web3, this.account, CONTRACT.STSWToken, this.ApproveWallet, this.ApproveAmt);
  }
  Click_Burn(){
    Tx_Burn(this.web3, this.account, CONTRACT.STSWToken, this.BurnWallet, this.BurnAmt);
  }
}

