// // @ts-ignore
// import * as BridgeInfoABI from './ContractABI/BridgeInfoABI.js';
// // @ts-ignore
// import * as BridgeABI from './ContractABI/BridgeABI.js';
// // @ts-ignore
// import * as FeeABI from './ContractABI/Fee.js';
// @ts-ignore
import * as BridgeABI from './ContractABI/BridgeV2ABI.js';
// @ts-ignore
import * as FeeABI from './ContractABI/FeeV2.js';
// @ts-ignore
import * as MultiSigWalletABI from './ContractABI/MultiSigWallet.js';
// @ts-ignore
import * as STSWTokenABI from './ContractABI/STSWToken.js';
// // @ts-ignore
// import * as VaultABI from './ContractABI/Vault.js';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import * as assert from "assert";
import * as fs from 'fs';

declare global {
    interface Window{
        ethereum?:any
    }
}

export const CONTRACT = {
    BridgeInfo : 'BridgeInfo',
    Bridge : 'Bridge',
    Fee : 'Fee',
    MultiSigWallet : 'MultiSigWallet',
    STSWToken : 'STSWToken',
    Vault : 'Vault',
}
// SP1Z92MPDQEWZXW36VX71Q25HKF5K2EPCJ304F275.stsw-token-v4a
// const ADDRESSLEGACY = {
//     'BridgeInfo':'0x65c3A97A03C023101442D73642966f7c7648fBeE',
//     'Bridge':'0x5387a3A682aa9ef986f7C3A791c9c66EC46a5d54',
//     'Fee':'0x3725E74E84e822AB501bfA286c722a49a1db014c',
//     'MultiSigWallet':'0x1ceBe280fC2A9f8F66Fc44A84F698247C2d8bF8E',
//     'STSWToken':'0x1C98B54d673C026C8286bADCa3E840aaf72931a3',
//     'Vault':'0xdbca1eC3787DE086eA3A5A4808a7a5e125dD7B98',
// }
const ADDRESS = {
    'Bridge':'0xA733111aad92f53Ae82fb91d64E9e21C93dcdEf7',
    'Fee':'0x131f87757d8c21eB6E46099D239B59bC99900724',
    'MultiSigWallet':'0x1ceBe280fC2A9f8F66Fc44A84F698247C2d8bF8E',
    'STSWToken':'0x1C98B54d673C026C8286bADCa3E840aaf72931a3',
}

const ABI = {
    // 'BridgeInfo': BridgeInfoABI.CONTRACT_ABI,
    'Bridge': BridgeABI.CONTRACT_ABI,
    'Fee': FeeABI.CONTRACT_ABI,
    'MultiSigWallet': MultiSigWalletABI.CONTRACT_ABI,
    'STSWToken': STSWTokenABI.CONTRACT_ABI,
    // 'Vault': VaultABI.CONTRACT_ABI,
}

async function sendTx(web3: Web3, send_wallet:string, tx:any, after_success:any = null, after_fail:any = null){
    // globalThis.web3 = web3
    // const tx = getLobbyContract(store.getState().contract).methods.Deposit(1000);

    // wallet nonce
    const nonce = await web3.eth.getTransactionCount(send_wallet);
    // console.log(send_wallet, nonce)

    // 현재 가스 가격
    const gasPrice = Number(await web3.eth.getGasPrice()) * 1.5;
    // console.log(send_wallet, nonce, gasPrice)

    // tx 소모될 가스 량 계산
    try {
        const gasEstimate = await tx.estimateGas({
            from: send_wallet
        });
        console.log(nonce, gasPrice, gasEstimate)

        return await tx
            .send({
                from: send_wallet,
                gas: new BigNumber(gasEstimate).integerValue().toString(), //gas 량
                gasPrice: new BigNumber(gasPrice).toFixed(0), //gas 가격
                nonce:nonce//재접속한 횟수
            }).on('error', function(error:any, receipt:any) {
                if(after_fail !== null) after_fail();
            }).on('receipt', function(error:any, receipt:any) {
                if(error !== undefined){
                    if(after_success !== null) after_success();
                }
            })
    }catch (e){
        if(after_fail !== null) after_fail();
    }
}

async function sendTx_woGasEstimated(web3: Web3, send_wallet:string, tx:any, after_success:any = null, after_fail:any = null){
    // globalThis.web3 = web3
    // const tx = getLobbyContract(store.getState().contract).methods.Deposit(1000);

    // wallet nonce
    const nonce = await web3.eth.getTransactionCount(send_wallet);
    // console.log(send_wallet, nonce)

    // 현재 가스 가격
    const gasPrice = Number(await web3.eth.getGasPrice()) * 1.5;
    // console.log(send_wallet, nonce, gasPrice)

    // tx 소모될 가스 량 계산
    try {
        // const gasEstimate = await tx.estimateGas({
        //     from: send_wallet
        // });
        const gasEstimate = 91000;
        console.log('ForceTx', nonce, gasPrice, gasEstimate)
        return await tx
            .send({
                from: send_wallet,
                gas: new BigNumber(gasEstimate).integerValue().toString(), //gas 량
                gasPrice: new BigNumber(gasPrice).toFixed(0), //gas 가격
                nonce:nonce//재접속한 횟수
            }).on('error', function(error:any, receipt:any) {
                if(after_fail !== null) after_fail();
            }).on('receipt', function(error:any, receipt:any) {
                if(error !== undefined){
                    if(after_success !== null) after_success();
                }
            })
    }catch (e){
        if(after_fail !== null) after_fail();
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export async function Tx_transferOwnership(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    console.log('Tx_transferOwnership', TARGET, address)

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.transferOwnership(
        input_1,
    );
    // const tx = Contract.methods.setMaxLen(
    //     new BigNumber(input_1).toString(),
    // );

    // const result = await Contract.methods.owner().call();
    // console.log('Tx_setNextRoundInfo', result, from_wallet)
    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_AddAllowedContract(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.AddAllowedContract(
        input_1,
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_RemoveAllowedContract(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.RemoveAllowedContract(
        input_1,
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
Functions for BridgeInfo Contract
 */
// ERROR
export async function Tx_setNextRoundInfo(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string, input_3:string
){
    // @ts-ignore
    assert(contract === CONTRACT.BridgeInfo)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.setNextRoundInfo(
        new BigNumber(input_1).toString(),
        new BigNumber(input_2).toString(),
        new BigNumber(input_3).toString(),
    );
    // const tx = Contract.methods.setMaxLen(
    //     new BigNumber(input_1).toString(),
    // );

    // const result = await Contract.methods.owner().call();
    // console.log('Tx_setNextRoundInfo', result, from_wallet)
    return await sendTx_woGasEstimated(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}
// ERROR
export async function Tx_setCurrentRoundInfo(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string
){
    // @ts-ignore
    assert(contract === CONTRACT.BridgeInfo)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.setCurrentRoundInfo(
        new BigNumber(input_1).toString(),
        new BigNumber(input_2).toString(),
    );

    return await sendTx_woGasEstimated(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_setMaxLen(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.BridgeInfo)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.setMaxLen(
        new BigNumber(input_1).toString(),
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_setBridgeContract(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.BridgeInfo)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.setBridgeContract(
        input_1,
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}


export async function Tx_Register_toToken(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string, input_3:string
){
    // @ts-ignore
    assert(contract === CONTRACT.BridgeInfo)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Register_toToken(
        input_1,
        input_2,
        new BigNumber(input_3).toString(),
    );
    // const tx = Contract.methods.setMaxLen(
    //     new BigNumber(input_1).toString(),
    // );

    // const result = await Contract.methods.owner().call();
    // console.log('Tx_setNextRoundInfo', result, from_wallet)
    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
Functions for Bridge Contract
 */
export async function Tx_Register_Withdraw(
    web3: any, from_wallet:any, contract:any,
    input_1:any,input_2:any,input_3:any,input_4:any
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Register_Withdraw(
        input_1,
        input_2,
        input_3,
        input_4
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_Remove_Order(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string, input_3:string
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Remove_Order(
        input_1,
        input_2,
        new BigNumber(input_3).toString(),
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_UpdateRound( // Public
    web3: any, from_wallet:any, contract:any,
    input_1:any
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.UpdateRound(
        input_1,
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_SetChangeContract(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.SetChangeContract(
        input_1,
        input_2,
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_EmergencyWithdraw_ERC20(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Withdraw_ERC20__(
        input_1,
        new BigNumber(input_2).toString(),
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_EmergencyWithdraw_ETH(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.Bridge)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Withdraw_ETH__(
        new BigNumber(input_1).toString(),
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
Functions for MultiSig
 */
export async function Tx_SetConfirmNum(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.MultiSigWallet)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.SetConfirmNum(
        new BigNumber(input_1).toString(),
    );

    return await sendTx_woGasEstimated(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_EnrollWhiteWallet(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.MultiSigWallet)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.EnrollWhiteWallet(
        input_1
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_DeregisterWhiteWallet(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.MultiSigWallet)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.DeregisterWhiteWallet(
        input_1
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_Vote(
    web3: any, from_wallet:any, contract:any,
    input_1:boolean
){
    // @ts-ignore
    assert(contract === CONTRACT.MultiSigWallet)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.Vote(
        input_1
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
Functions for Fee
 */
export async function Tx_setFee(
    web3: any, from_wallet:any, contract:any,
    input_1:string,  input_2:string, input_3:string, input_4:string, input_5:string,
){
    // @ts-ignore
    assert(contract === CONTRACT.Fee)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.setFee(
        input_1,
        new BigNumber(input_2).toString(),
        new BigNumber(input_3).toString(),
        new BigNumber(input_4).toString(),
        new BigNumber(input_5).toString(),
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
Functions for Vault
 */

export async function Tx_SetEmergency_Vault(
    web3: any, from_wallet:any, contract:any,
    input_1:boolean
){
    // @ts-ignore
    assert(contract === CONTRACT.Vault)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.SetEmergency(
        input_1
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_EmergencyWithdraw_Vault(
    web3: any, from_wallet:any, contract:any,
    input_1:string
){
    // @ts-ignore
    assert(contract === CONTRACT.Vault)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.EmergencyWithdraw(
        input_1
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )

}

export async function Tx_Approve(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string,
){
    // @ts-ignore
    assert(contract === CONTRACT.STSWToken)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.approve(
        input_1,
        new BigNumber(input_2).toNumber()
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )
}

export async function Tx_Burn(
    web3: any, from_wallet:any, contract:any,
    input_1:string, input_2:string,
){
    // @ts-ignore
    assert(contract === CONTRACT.STSWToken)

    // @ts-ignore
    const TARGET:string = CONTRACT[contract];
    // @ts-ignore
    const address:any = ADDRESS[TARGET];
    // @ts-ignore
    const abi:any = ABI[TARGET];

    const Contract = new web3.eth.Contract(
        abi,
        address,
    );

    const tx = Contract.methods.burn(
        input_1,
        new BigNumber(input_2).toNumber()
    );

    return await sendTx(
        web3,
        from_wallet,
        tx,
        null,
        null,
    )
}

