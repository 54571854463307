
import { Component, Vue } from 'vue-property-decorator';
import luHeader from '@/layout/header.component.vue';
import luFooter from '@/layout/footer.component.vue';

@Component({
  components: {
    luHeader,
    luFooter,
  },
})
export default class App extends Vue {
}
