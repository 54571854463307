import { StoreOptions } from 'vuex';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

export const vaultStore: StoreOptions<any> = {
  state: {
    user: null,
    collateral_type : { block : 0, data : {} },
    user_vault_list : { block: 0, data : {}},
    price_data : { block : 0, data : { stx : 0, lbtc : 0, stsw : 0}},
  },
  actions: {
    // 월렛 커넥트 하는 부분입니다.
    setCollateralType({commit}, collateral_type) {
      commit('setCollateralType', collateral_type);
    },
    setUserVaults({commit}, user_vaults) {
      commit('setUserVaults', user_vaults);
    },
    setPriceData({commit}, price_data) {
      commit('setPriceData', price_data);
    },
  },
  getters: {
    collateral_type: (state) => state.collateral_type,
    user_vault_list: (state) => state.user_vault_list,
    getVault: (state) => {
      return (block: number, addr_: string) => {
         if (state.user_vault_list.block === block) {
           if ( state.user_vault_list.data[addr_]) {
             return { addr : addr_, data : state.user_vault_list.data[addr_]};
           }
         }
         return null;
      };
    },
    price_data: (state) => state.price_data,
  },
  mutations: {
    setCollateralType(state, collateral_type) {
      state.collateral_type = collateral_type;
    },
    setUserVaults(state, user_vaults) {
      if (state.user_vault_list.block !== user_vaults.block) {
        state.user_vault_list.block = user_vaults.block;
        state.user_vault_list.data = {};
      }
      state.user_vault_list.data[user_vaults.addr] = user_vaults.data;
    },
    setPriceData(state, price_data) {
      state.price_data = price_data;
    },
  },
};
